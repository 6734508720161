import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";

import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import apple from "../../assets/apple.svg";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import ForgotPw from "../forgotpw/ForgotPassword";
import { useSnackbar } from "../utils/SnackbarProvider";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import { useGoogleAuth } from "../utils/AuthServices";
import LoadingBackdrop from "../utils/loader";
const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { makeApiCall } = useApiRequest();
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const { handleGoogleLogin } = useGoogleAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPw, setShowForgotPw] = useState(false);

  const handleNavigateForgotPw = () => {
    setShowForgotPw(true); // Set the state to true to render the Otp component
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleBack = () => {
    setShowForgotPw(false);
  };
  const handleContinue = () => {
    navigate("/dashboard");
    // Example: navigate(-1); if using react-router
  };
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!email) {
      showSnackbar("Email is required", "error");
      return;
    }
    if (!password) {
      showSnackbar("Password is required", "error");
      return;
    }
    if (password.length < 8) {
      showSnackbar("Password must be at least 8 characters long", "error");
      return;
    }

    // API Call to sign in
    const payload = {
      email: email,
      password: password,
    };
    setIsLoading(true);
    const response = await makeApiCall(
      routes.auth.login,
      "POST",
      payload,
      "show"
    );

    if (response.status) {
      if (response.data.toVerification) {
        navigate("/register", { state: { verify: true, email: email } });
        showSnackbar(response.message, "info");
      } else {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        navigate("/dashboard");
      }
    } else {
      showSnackbar(response.message, "error");
    }

    setIsLoading(false);
  };
  return (
    <Box sx={{ width: isMobile ? "100%" : "80%" }}>
      <LoadingBackdrop isLoading={isLoading} />
      {!showForgotPw ? (
        <>
          <Typography
            sx={{
              color: colors.gray900,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontWeight="bold"
            fontSize={fonts.heading1}
            gutterBottom
          >
            Login to your Account
          </Typography>
          <Typography
            sx={{
              ml: isMobile ? 1 : 0,
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              width: "280px",
              mt: 7,
            }}
            variant={isMobile ? "body2" : fonts.body}
            gutterBottom
          >
            Don't have an account?
            <Link
              sx={{ ml: 0.5, justifyContent: "left", textAlign: "left" }}
              href="/register"
              color={colors.teal700}
              underline={isMobile ? "none" : "always"}
            >
              {isMobile ? "Login Here" : "Sign Up Here"}
            </Link>
          </Typography>

          <TextField
            fullWidth
            label="Enter your Email"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ ml: isMobile ? 0.6 : 0, mt: 5 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ ml: isMobile ? 0.6 : 0 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />

          <Typography
            component="span"
            display={"flex"}
            justifyContent={"right"}
            sx={{
              fontSize: isMobile ? "11px" : "16px",
              ml: isMobile ? 2 : 0,
            }}
          >
            <Typography
              onClick={handleNavigateForgotPw}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              color="#00796B"
            >
              Forgot Password?
            </Typography>
          </Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{
              mt: 4,
              py: 1,
              background: "linear-gradient(to right, #74D0A9, #9388D3 )",
              height: isMobile ? "45px" : "60px",
              borderRadius: "4px",
              textTransform: "none",
              fontSize: isMobile ? 12 : fonts.heading4,
              letterSpacing: 1,
              fontWeight: 400,
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
              },
            }}
          >
            Login
          </Button>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",

              mt: isMobile ? 3 : 5,
              mb: 2,
              "&::before, &::after": {
                content: '""',
                flex: 1,
                borderBottom: "1px solid #BDBDBD",
              },
              "&::before": {
                marginRight: 2,
              },
              "&::after": {
                marginLeft: 2,
              },
            }}
          >
            <Typography variant="body2" color={colors.gray800}>
              or login with
            </Typography>
          </Box>

          <Box
            sx={{
              display: isMobile ? "inline" : "flex",
              justifyContent: "center",
              gap: 4,
              mt: 5,
            }}
          >
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "5px",
                width: isMobile ? "100%" : "100%",
                padding: 2,
                transition: "box-shadow 0.3s ease",
              }}
              onClick={handleGoogleLogin}
            >
              <img
                src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/google.png"
                alt="google"
              />
              {isMobile && (
                <Typography
                  sx={{
                    ml: 2,
                    color: colors.gray800,
                  }}
                >
                  Google
                </Typography>
              )}
            </IconButton>
            {/* <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "5px",
                width: isMobile ? "100%" : "25%",
                padding: 2,
                mt: isMobile ? 3 : 0,
                transition: "box-shadow 0.3s ease",
              }}
              // onClick={handleNavigateHome}
            >
              <img
                src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/facebook.png"
                alt="facebook"
              />
              {isMobile && (
                <Typography
                  sx={{
                    ml: 2,
                    color: colors.gray800,
                  }}
                >
                  Facebook
                </Typography>
              )}
            </IconButton>
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "5px",
                width: isMobile ? "100%" : "25%",
                mt: isMobile ? 3 : 0,
                padding: 2,
                mb: isMobile ? 2 : 0,
                transition: "box-shadow 0.3s ease",
              }}
              // onClick={handleNavigateHome}
            >
              <img
                src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/apple.png"
                alt="apple"
              />
              {isMobile && (
                <Typography
                  sx={{
                    ml: 2,
                    color: colors.gray800,
                  }}
                >
                  Apple
                </Typography>
              )}
            </IconButton> */}
          </Box>
        </>
      ) : (
        <ForgotPw
          handleBack={handleBack}
          handleContinue={handleContinue}
          email={email}
        />
      )}
    </Box>
  );
};

export default SignInForm;
