import React from "react";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import colors from "../../../styles/colors";
import { useNavigate } from "react-router-dom";

const SuggestionsEmptyPage = () => {
  const emptyImage =
    "https://d3vdd33llamc8d.cloudfront.net/assets/empty/help-center.png";
  const navigate = useNavigate();

  const handleSubmitSuggestion = () => {
    navigate("/support/suggestion/new");
  };

  const storedUser = JSON.parse(localStorage.getItem("user"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: "4%",
        mx: "10%",
      }}
    >
      <Box
        sx={{
          flex: {
            lg: 1.3,
            sm: 1,
          },
          pr: 0,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            background: "linear-gradient(to right, #74D0A9, #9388D3)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            mb: "6%",
            textAlign: "left",
            fontSize: {
              lg: "40px",
              sm: "34px",
            },
          }}
        >
          Welcome to your help center
        </Typography>
        <Typography
          variant="body1"
          textAlign={"left"}
          sx={{ color: colors.gray600, mb: "7%" }}
        >
          We're here to make sure your experience with us is smooth and
          satisfying. If you have questions, need assistance, or want to share
          feedback, you're in the right place
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            background: "linear-gradient(to right, #74D0A9, #9388D3)",
            textTransform: "none",
            borderRadius: "4px",
            padding: "8px 20px",
            display: "flex",
            justifyContent: "left",
            boxShadow:
              "box-shadow: 0px 3px 1px -2px #00000033 ,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
          }}
          onClick={handleSubmitSuggestion}
        >
          Submit a Suggestion
        </Button>
      </Box>

      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <img
          src={emptyImage}
          alt="Workout Illustration"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default SuggestionsEmptyPage;
