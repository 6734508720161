import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import OtpInput from "react-otp-input";
import WestSharpIcon from "@mui/icons-material/WestSharp";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { useSnackbar } from "../utils/SnackbarProvider";
import LoadingBackdrop from "../utils/loader";
const Otp = ({
  handleBack,
  handleContinue,
  isFromRegister,
  email,
  fromForgotPW,
}) => {
  const [otp, setOtp] = useState("");
  const { makeApiCall } = useApiRequest();
  const showSnackbar = useSnackbar(); // Use the global Snackbar
  const [seconds, setSeconds] = useState(59);
  const [isLoading, setIsLoading] = useState(false);

  const [resendText, setResendText] = useState(
    `Resend in 0:${seconds < 10 ? `0${seconds}` : seconds}`
  );

  const handleChange = (value) => {
    setOtp(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: email, // email coming from props
      code: otp, // otp entered by the user
    };
    setIsLoading(true);
    let response;
    if (fromForgotPW) {
      response = await makeApiCall(
        routes.password.verifyCode,
        "POST",
        payload // Send the OTP as part of the request body
      );
    } else {
      response = await makeApiCall(
        routes.auth.verify,
        "POST",
        payload // Send the OTP as part of the request body
      );
    }

    setIsLoading(false);

    if (response.status) {
      const verificationToken = fromForgotPW
        ? response.data.verificationToken
        : null;
      handleContinue(fromForgotPW ? verificationToken : response.data.user); // Pass the token to the parent component
    } else {
      // Handle the case where OTP verification fails
      showSnackbar("OTP verification failed", "error");
      console.error("OTP verification failed:", response.message);
    }
  };

  const handleResend = async (e) => {
    const payload = {
      email: email, // email coming from props
    };
    let response;
    if (fromForgotPW) {
      response = await makeApiCall(
        routes.password.sendCode,
        "POST",
        payload // Send the OTP as part of the request body
      );
    } else {
      response = await makeApiCall(
        routes.auth.resend,
        "POST",
        payload // Send the OTP as part of the request body
      );
    }

    if (response.status) {
      // You can add logic here to handle successful verification
      showSnackbar(response.message, "success");
      setSeconds(59);
    } else {
      // Handle the case where OTP verification fails
      showSnackbar(response.message, "error");
      console.error("OTP verification failed:", response.message);
    }
  };
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer); // Clear interval on component unmount
    } else {
      setResendText("Resend Code");
    }
  }, [seconds]);

  useEffect(() => {
    if (seconds > 0) {
      setResendText(`Resend in 0:${seconds < 10 ? `0${seconds}` : seconds}`);
    }
  }, [seconds]);
  return (
    <Box sx={{ width: isMobile ? "100%" : "100%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Typography
        sx={{
          color: colors.gray600,
          display: "flex",
          justifyContent: isMobile ? "center" : "left",
          textAlign: "left",
          mt: 0,
          cursor: "pointer",
        }}
        // variant={windowWidth <= 768 ? "h5" : "h4"}
        fontSize={fonts.body}
        gutterBottom
        onClick={handleBack}
      >
        <WestSharpIcon /> <Typography sx={{ marginLeft: 1 }}>Back</Typography>{" "}
      </Typography>
      <Typography
        sx={{
          color: colors.gray900,
          display: "flex",
          justifyContent: isMobile ? "center" : "left",
          textAlign: "left",
          mt: 5,
        }}
        // variant={windowWidth <= 768 ? "h5" : "h4"}
        fontWeight="bold"
        fontSize={fonts.heading1}
        gutterBottom
      >
        {isFromRegister ? "Verify Your Email" : "Forgot Password?"}
      </Typography>
      <Typography
        sx={{
          ml: isMobile ? 1 : 0,
          display: "flex",
          justifyContent: "left",
          textAlign: "left",
          width: "280px",
          fontSize: fonts.heading2,
          fontWeight: 600,
          color: colors.gray900,
          mt: 7,
        }}
        // variant={isMobile ? "body2" : fonts.body}

        gutterBottom
      >
        Enter the OTP
      </Typography>

      <Box marginLeft={"-2%"} marginTop={7}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "14%",
            height: "3.5rem",
            margin: "0.8rem",
            border: `1px solid ${colors.gray500}`, // Border color
            borderRadius: "4px",
            fontSize: "1.5rem",
            textAlign: "center",
          }}
          containerStyle={{
            justifyContent: "left",
          }}
        />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          component="span"
          display={"flex"}
          justifyContent={"left"}
          sx={{
            fontSize: isMobile ? "11px" : fonts.info,
            ml: isMobile ? 2 : 0,
            textAlign: "left",
          }}
        >
          Check your email for the code.
        </Typography>
        <Typography
          component="span"
          display={"flex"}
          justifyContent={"left"}
          sx={{
            fontSize: isMobile ? "11px" : fonts.info,
            ml: isMobile ? 2 : 0,
            color: colors.teal600,
            textAlign: "left",
            mr: 1.5,
            cursor: seconds > 0 ? "default" : "pointer", // Change cursor based on seconds
            "&:hover": {
              textDecoration: seconds > 0 ? "none" : "underline", // Underline when clickable
            },
          }}
          onClick={seconds === 0 ? handleResend : undefined} // Call handleResend if seconds is 0
        >
          {resendText}
        </Typography>
      </Box>

      <Button
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{
          mt: 9,
          py: 1,
          background: colors.teal400,
          height: isMobile ? "45px" : "60px",
          borderRadius: "4px",
          textTransform: "none",
          fontSize: isMobile ? 12 : fonts.heading4,
          letterSpacing: 1,
          fontWeight: 400,
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
          },
        }}
      >
        {isFromRegister ? "Verify" : "Continue"}
      </Button>
    </Box>
  );
};

export default Otp;
