import { React, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  InputAdornment,
  ListItemIcon,
  Menu,
  Divider,
} from "@mui/material";
import { Search, MoreVert } from "@mui/icons-material";
import { PlayArrow, Info, Delete } from "@mui/icons-material";

import colors from "../../styles/colors";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { useState } from "react";
import DividerLine from "../utils/DividerLine";
import fonts from "../../styles/fontVars";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";

import TruncateText from "../utils/TruncateText";
import ConfirmationDialog from "../utils/Dialog";
import LoadingBackdrop from "../utils/loader";

const AllWorkoutsSection = ({
  Data,
  onFilterChange,
  onSearch,
  onDataUpdate,
  onDelete,
}) => {
  const navigate = useNavigate();
  const { makeApiCall } = useApiRequest();
  const [cardData, setCardData] = useState(Data);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [selectedWorkoutId, setSelectedWorkoutId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = useState(""); // Local state for the search text
  const handleNavigateWorkout = (id) => {
    navigate(`/workout?id=${id}`); // Navigate to the workout page with the workout id
  };
  const [isLoading, setIsLoading] = useState(false);

  const [favorites, setFavorites] = useState({});
  useEffect(() => {
    setCardData(Data); // Sync local state with the passed prop
    const initialFavorites = {};
    Data.forEach((card) => {
      initialFavorites[card.id] = card.isFavourite || false;
    });
    setFavorites(initialFavorites);
  }, [Data]);
  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    onFilterChange(selectedFilter); // Call the callback function passed from the parent
  };
  const handleSearchClick = () => {
    onSearch(searchValue); // Call the search callback with the current search value
  };
  const handleIconClick = async (e, id, title) => {
    e.stopPropagation(); // Prevent card click event from triggering
    const updatedCardData = cardData.map((card) => {
      if (card.id === id) {
        return { ...card, isFavourite: !card.isFavourite };
      }
      return card;
    });

    setCardData(updatedCardData);

    const updatedCard = updatedCardData.find((card) => card.id === id);
    const newFavoriteStatus = updatedCard.isFavourite;

    // Toggle the favorite status
    const payload = {
      title: title,
      isFavourite: newFavoriteStatus,
    };
    const response = await makeApiCall(
      routes.workout.update(id),
      "PUT",
      payload
    );
    if (!response.status) {
      // If the API call fails, revert the change
      setCardData(cardData);
    } else {
      // If successful, update the parent component's data
      onDataUpdate(updatedCardData, updatedCard);
    }
  };
  const handleClick = (event, workout) => {
    setAnchorEl(event.currentTarget);
    setSelectedWorkout(workout); // Store the selected workout
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePlay = (id) => {
    if (id) {
      window.location.href = `https://main.dnxkce1wa4yhv.amplifyapp.com?id=${id}`;
    } else {
      window.location.href = "/404"; // Redirect to the 404 page
    }
  };

  const handleMoreInfo = (id) => {
    navigate(`/workout?id=${id}`); // Navigate to the workout page with the workout id
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const ShowCloseDialog = (id) => {
    setSelectedWorkoutId(id);
    setOpenDialog(true);
  };

  const handleDelete = (id) => {
    onDelete(selectedWorkoutId); // Call the shared delete handler
    setOpenDialog(false);
  };
  const renderImages = (images) => {
    const displayImages = images.slice(0, 3); // Get only the first 3 images
    const remainingCount = images.length - displayImages.length;
    return (
      <Box sx={{ position: "relative", width: "100%", height: "300px" }}>
        {displayImages.map((image, index) => (
          <CardMedia
            key={index}
            component="img"
            height="100%"
            image={image}
            alt={`Image ${index}`}
            sx={{
              position: "absolute",
              top: 0,
              // left: index === 0 ? 0 : `${80 + (index - 1) * 10}%`, // First image takes 80%, others shift by 10%
              // width: index === 0 ? "100%" : "10%", // First image is 80%, others are 10%
              left: (() => {
                if (displayImages.length === 1) return 0; // For single image
                if (displayImages.length === 2 && index === 0) return 0; // First image of two
                if (displayImages.length === 2 && index === 1) return "85%"; // Second image of two
                if (index === 0) return 0; // First image of three or more
                if (index === 1) return "80%"; // Second image of three or more
                return "90%"; // Third image of three
              })(),
              width: (() => {
                if (displayImages.length === 1) return "100%"; // Full width for single image
                if (displayImages.length === 2 && index === 0) return "85%"; // 80% for first image of two
                if (displayImages.length === 2 && index === 1) return "15%"; // 20% for second image of two
                if (index === 0) return "80%"; // 80% for first image of three or more
                return "10%"; // 10% for second and third images of three or more
              })(),
              // borderRadius: "8px",
              zIndex: index,
              // opacity: (() => {
              //   if (index === 1) return 1.5; // Adjust opacity for the 2nd image
              //   if (index === 2) return 1; // Adjust opacity for the 3rd image
              //   return 1; // Full opacity for the first image
              // })(),
            }}
          />
        ))}
        {displayImages.length >= 3 && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "80%", // Positioning for the second image
                width: "10%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Different opacity for second image
                zIndex: 2,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "90%", // Positioning for the third image
                width: "10%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)", // Different opacity for third image
                zIndex: 2,
              }}
            />
          </>
        )}
        {displayImages.length === 2 && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "85%", // Positioning for the third image (only shown when there are 2 images)
              width: "15%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Different opacity for third image
              zIndex: 2,
            }}
          />
        )}
        {displayImages.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
              // backgroundColor:"rgba(0, 0, 0, 0.4) "

              color: "white",
              // borderRadius: "8px",
              zIndex: 3,
              width: displayImages.length === 2 ? "15%" : "20%", // Adjust this based on the overlap
              textAlign: "center",
              pb: 3,
            }}
          >
            {" "}
            {remainingCount > 0 && (
              <Typography fontSize={fonts.body}>+{remainingCount}</Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };
  return (
    <>
      <LoadingBackdrop isLoading={isLoading} />

      <Typography
        mx={"6.3%"}
        variant="h6"
        textAlign={"left"}
        gutterBottom
        sx={{
          marginTop: 4,
        }}
      >
        All Workouts
      </Typography>
      <Box mx={"6.3%"}>
        <DividerLine />
      </Box>
      {/* Search and Filter */}
      <Box mx={"6.3%"}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <TextField
              label="Search"
              value={searchValue} // Bind the input value to local state
              onChange={(e) => setSearchValue(e.target.value)} // Update local state on input change
              sx={{
                display: "flex",
                width: "80%",
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchClick();
                }
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearchClick}>
                        {" "}
                        {/* Call search on icon click */}
                        <Search sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} textAlign="right">
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Filter</InputLabel>
              <Select
                sx={{
                  textAlign: "left",
                }}
                variant="outlined"
                label="Filter"
                defaultValue="relevance"
                onChange={handleFilterChange} // Handle change event
              >
                <MenuItem value="relevance">Relevance</MenuItem>
                <MenuItem value="dateCreated">Date Created</MenuItem>
                <MenuItem value="lastActivity">Last Activity</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Workout Cards */}
      <Box ml={"6.2%"} mr={"3.3%"}>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {cardData.map((card) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={card.id}>
              <Card
                variant="none"
                onClick={() => handleNavigateWorkout(card.id)}
                sx={{
                  marginRight: "10%",
                  marginBottom: "10%",
                  borderRadius: "8px",

                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "4px 3px 20px 2px #00796B54, -4px -3px 20px 0px #9388D380", // Apply the box shadows on hover
                    transition: "box-shadow 0.3s ease", // Smooth transition for box-shadow
                  },
                }}
              >
                {renderImages(card.images)}
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "right",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardContent sx={{ textAlign: "left" }}>
                    <Typography fontSize={fonts.body} color={colors.gray900}>
                      <TruncateText text={card.title} maxChars={30} />
                    </Typography>
                    <Typography
                      fontSize={fonts.info}
                      mt={1}
                      color={colors.gray400}
                    >
                      {card?.editedLabel} {card.edited}
                    </Typography>
                  </CardContent>
                  <Box>
                    <IconButton
                      onClick={(e) => handleIconClick(e, card.id, card.title)}
                    >
                      {favorites[card.id] ? (
                        <FavoriteOutlinedIcon
                          sx={{
                            color: colors.teal700, // Color when favorited
                            transition: "color 0.3s ease",
                          }}
                        />
                      ) : (
                        <FavoriteBorderOutlinedIcon
                          sx={{
                            color: colors.teal700, // Color when not favorited
                            transition: "color 0.3s ease",
                          }}
                        />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents the Card's onClick from being triggered
                        handleClick(e, card);
                      }}
                    >
                      <MoreVert
                        sx={{
                          color: colors.teal700,
                          transition: "color 0.3s ease",
                        }}
                      />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={(e) => {
                        e.stopPropagation(); // Prevents the Card's onClick from being triggered
                        handleCloseMenu(); // Close the menu
                      }}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      PaperProps={{
                        sx: {
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds a soft shadow
                        },
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedWorkout) {
                            // Ensure there's a selected workout
                            handlePlay(selectedWorkout.id);
                          }
                          handleCloseMenu();
                        }}
                      >
                        <ListItemIcon>
                          <PlayArrow fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">Play</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedWorkout) {
                            // Ensure there's a selected workout
                            handleMoreInfo(selectedWorkout.id);
                          }
                          // handleMoreInfo();
                          handleCloseMenu();
                        }}
                      >
                        <ListItemIcon>
                          <Info fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">More Info</Typography>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedWorkout) {
                            ShowCloseDialog(selectedWorkout.id);
                          }
                          handleCloseMenu();
                        }}
                        sx={{ color: "red" }}
                      >
                        <ListItemIcon>
                          <Delete fontSize="small" style={{ color: "red" }} />
                        </ListItemIcon>
                        <Typography variant="inherit">Delete</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        <ConfirmationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={handleDelete}
          title="Delete"
          content="Are you sure you want to delete this workout? All its sections and exercises will be deleted along with it"
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          cancelButtonProps={{ sx: { color: colors.gray500 } }}
          confirmButtonProps={{ sx: { color: colors.red900 } }}
        />
      </Box>
    </>
  );
};

export default AllWorkoutsSection;
