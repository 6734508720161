import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import colors from "../../styles/colors";

const WorkoutSkeleton = () => {
  return (
    <Box sx={{ mt: 8, mx: "8%", mb: 12 }}>
      {/* Breadcrumbs skeleton */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 2 }}>
        <Skeleton width={40} />
        <Skeleton width={20} />
        <Skeleton width={100} />
      </Box>

      {/* Main Content */}
      <Grid container spacing={0}>
        <Grid item xs={12} mt={5} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              {/* Title skeleton */}
              <Skeleton variant="text" width={300} height={60} />
              {/* Play button skeleton */}
              <Skeleton
                variant="circular"
                width={68}
                height={68}
                sx={{ ml: 3, mt: -2 }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2} sm={4}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Skeleton variant="circular" width={44} height={44} />
          </Box>
        </Grid>
      </Grid>

      {/* Alert skeleton */}
      <Skeleton
        variant="rectangular"
        height={60}
        sx={{
          mb: 6,
          borderRadius: 1,
        }}
      />

      {/* New Section button skeleton */}
      <Skeleton
        variant="rectangular"
        height={80}
        sx={{
          mb: 6,
          borderRadius: 1,
        }}
      />

      {/* Sections skeleton */}
      {[1, 2, 3].map((_, index) => (
        <Box
          key={index}
          sx={{
            mb: 2,
            p: 3,
            border: `1px solid ${colors.gray200}`,
            borderRadius: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Skeleton variant="text" width={200} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Skeleton width={100} />
                <Skeleton width={100} />
                <Skeleton width={100} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default WorkoutSkeleton;
