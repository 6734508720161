import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { HelpOutline as HelpIcon } from "@mui/icons-material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

// Assuming you have these icons, replace with actual imports or use placeholder SVGs
// import Icon1 from "@/components/icons/Icon1";
// import Icon2 from "@/components/icons/Icon2";
import fonts from "../../styles/fontVars";
import colors from "../../styles/colors";
import { useNavigate } from "react-router-dom";
// import Icon3 from "@/components/icons/Icon3";
const pagenotFound =
  "https://d3vdd33llamc8d.cloudfront.net/assets/empty/404.png";
export default function ServerErrorPage() {
  const navigate = useNavigate();
  const handleNavigateHome = () => {
    navigate("/dashboard");
  };
  const handleNavigateSupport = () => {
    navigate("/support");
  };
  return (
    <Box
      sx={{
        display: "flex",
        mx: "10%",
        padding: 3,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 5.5,
        }}
      >
        <Box
          sx={{
            display: "flex-col",
            justifyContent: "end",
            mr: 8,
          }}
        >
          <Typography
            fontSize={"40px"}
            sx={{
              fontWeight: 600,
              background: "linear-gradient(to right, #74D0A9, #9388D3)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
              marginBottom: 2,
              textAlign: "left",
            }}
          >
            5xx - Internal Server Error
          </Typography>
          <Typography
            fontSize={fonts.heading4}
            sx={{
              marginBottom: 4,
              textAlign: "left",
              color: colors.gray600,
            }}
          >
            Sorry, something went wrong on our side. Our technicians have been
            notified.
          </Typography>
          <Typography
            fontSize={fonts.heading4}
            sx={{
              textAlign: "left",
              mb: 11,
              color: colors.gray600,
            }}
          >
            Please try again later, or reach out to support if the problem
            continues.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Button
              variant="contained"
              startIcon={<HomeOutlinedIcon />}
              onClick={handleNavigateHome}
              sx={{
                backgroundColor: colors.teal400,
                mr: 2,
                px: 3,
                textTransform: "none",
                boxShadow:
                  "0px 3px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
              }}
            >
              Home
            </Button>
            <Button
              variant="outlined"
              startIcon={<HelpIcon />}
              onClick={handleNavigateSupport}
              sx={{
                backgroundColor: colors.gray200,
                border: "none",
                color: colors.gray800,
                px: 3,
                textTransform: "none",
                boxShadow:
                  "0px 3px 1px -2px #00000033,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
              }}
            >
              Help
            </Button>
          </Box>
        </Box>

        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 2,
            }}
          ></Box>
          <img
            src={pagenotFound}
            alt="Person doing yoga"
            style={{ maxWidth: "600px", height: "390px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}
