import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Drawer,
} from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import { useNavigate } from "react-router-dom";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { nav } from "framer-motion/client";
import LoadingBackdrop from "../utils/loader";

const NewSectionModal = ({ open, onClose, workoutId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCheckbox, setSelectedCheckbox] = useState(null); // To manage which checkbox is selected
  const [expanded, setExpanded] = useState("");
  const [title, setTitle] = useState("");
  const { makeApiCall } = useApiRequest();

  // Array to hold accordion data
  const accordionData = [
    {
      id: "present",
      label: "Present",
      imgSrc:
        "https://d3vdd33llamc8d.cloudfront.net/assets/templates/present.png",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: "work",
      label: "Work",
      imgSrc: "https://d3vdd33llamc8d.cloudfront.net/assets/templates/work.png",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    // Add more accordion items here if needed
  ];

  const handleNavigateSection = () => {
    navigate("/workout/section");
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCheckboxChange = (checkbox) => (event) => {
    setSelectedCheckbox(selectedCheckbox === checkbox ? null : checkbox);
  };
  const handleCreateSection = async () => {
    const payload = {
      workoutId,
      title,
      template: selectedCheckbox,
    };
    // Make the API call
    setIsLoading(true);
    const response = await makeApiCall(routes.section.add, "POST", payload);
    setIsLoading(false);

    // Check if the response is successful (assuming 200 status for success)
    if (response?.status) {
      // Close the modal
      navigate(`/workout/section?id=${response.data.section.id}`);
    }
  };
  return (
    <>
      <LoadingBackdrop isLoading={isLoading} />

      <Drawer
        onClick={(event) => {
          event.stopPropagation();
        }}
        anchor="bottom"
        ModalProps={{
          keepMounted: true, // Ensures the modal stays mounted for better performance
        }}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { borderRadius: "16px 16px 0 0", padding: 3, height: "730px" },
        }}
      >
        <Box sx={{ mx: "6.5%" }}>
          {/* Modal Heading */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: colors.gray800,
                fontSize: fonts.heading3,
                fontWeight: 600,
                mt: 10,
                mb: 2,
              }}
            >
              Name
            </Typography>
            <Box>
              <Button
                variant="contained"
                onClick={handleCreateSection}
                disabled={!(title && selectedCheckbox)}
                sx={{
                  textTransform: "none",
                  mt: 2,
                  px: 2,
                  fontWeight: 400,
                  fontSize: fonts.heading4,
                  background: colors.teal400,
                  color: "#fff",
                  "&:hover": {
                    background: colors.teal600,
                  },
                }}
                endIcon={<AddOutlinedIcon />}
              >
                Create Section
              </Button>
            </Box>
          </Box>

          {/* Section Name Field */}
          <TextField
            label="Section Name"
            fullWidth
            value={title} // Bind input value to state
            onChange={(e) => setTitle(e.target.value)} // Update state when input changes
            sx={{
              mb: 6,
              "& label.Mui-focused": { color: colors.gray800 },
              width: "50%",
            }}
          />

          {/* Accordion with Checkbox and Template */}
          <Typography
            sx={{
              mb: 4,
              color: colors.gray800,
              fontSize: fonts.heading3,
              fontWeight: 600,
            }}
          >
            Select a Template
          </Typography>

          {/* Map through the accordionData array */}
          {accordionData.map((accordion) => (
            <Accordion
              key={accordion.id}
              disableGutters
              sx={{
                border: "none",
                "&:before": {
                  display: "none", // Remove default Material-UI divider line
                },
                borderBottom: expanded === accordion.id ? "none" : "1px solid",
                borderColor: colors.gray400,
              }}
              elevation={0}
              expanded={expanded === accordion.id}
              onChange={handleChange(accordion.id)}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownOutlinedIcon sx={{ colors: "#1C1B1F" }} />
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCheckbox === accordion.id}
                      onChange={handleCheckboxChange(accordion.id)}
                      sx={{ mr: 6 }}
                    />
                  }
                  label={accordion.label}
                  sx={{ color: colors.gray800 }}
                  onClick={(e) => e.stopPropagation()} // Prevent accordion toggle when checkbox is clicked
                  onFocus={(e) => e.stopPropagation()}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", alignItems: "start", mx: "5%" }}>
                  <img
                    src={accordion.imgSrc}
                    alt={`${accordion.label} Template`}
                    style={{
                      width: "30%",
                      height: "100%",
                      objectFit: "cover",
                      marginRight: 16,
                    }}
                  />
                  <Box ml={3}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: fonts.body, fontWeight: 500 }}
                    >
                      {accordion.label} Template
                    </Typography>
                    <Typography sx={{ fontSize: fonts.info, mt: 2 }}>
                      {accordion.description}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* Create Section Button */}
        </Box>
      </Drawer>
    </>
  );
};

export default NewSectionModal;
