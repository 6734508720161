import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import colors from "../../styles/colors";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import facebookFooter from "../../assets/facebookFooter.svg";
import instagram from "../../assets/instagram.svg";
import linkedIn from "../../assets/linkedin.svg";
import twitter from "../../assets/twitter.svg";
import fonts from "../../styles/fontVars";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import { icons } from "../../styles/icons";
const Footer = () => {
  const navigate = useNavigate();
  const handleNavigateSuggestions = () => {
    navigate("/support");
  };
  const handleNavigateIssues = () => {
    navigate("/support?tab=issue");
  };
  const { makeApiCall } = useApiRequest();
  const [email, setEmail] = useState("");

  const handleSendClick = async () => {
    if (email) {
      const payload = { email };

      const response = await makeApiCall(
        routes.newsletter.add,
        "POST",
        payload,
        "show"
      );
      setEmail("");
    }
  };
  return (
    <footer
      style={{
        // marginTop: 70,
        marginLeft: 15,
        marginRight: 15,
        padding: 20,
        marginBottom: 15,
        backgroundColor: colors.teal600,
        borderRadius: "12px",
        color: "#fff",
      }}
    >
      <Grid container spacing={4} pt={3} textAlign={"left"} pl={"4.2%"}>
        {/* Left Column */}
        <Grid item xs={12} sm={3} lg={3}>
          <Typography
            fontSize={fonts.body}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline", // Smooth transition for box-shadow
              },
            }}
            gutterBottom
          >
            About Us
          </Typography>
          <Typography
            fontSize={fonts.body}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline", // Smooth transition for box-shadow
              },
            }}
            mt={2}
            gutterBottom
          >
            About the Product
          </Typography>
        </Grid>

        {/* Center Column */}
        <Grid item xs={12} sm={2.5} lg={3}>
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline", // Smooth transition for box-shadow
              },
            }}
            fontSize={fonts.body}
            gutterBottom
          >
            Terms & Conditions
          </Typography>
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline", // Smooth transition for box-shadow
              },
            }}
            fontSize={fonts.body}
            mt={2}
            gutterBottom
          >
            Privacy Policy
          </Typography>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} sm={2.5} lg={3}>
          <Typography
            onClick={handleNavigateSuggestions}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline", // Smooth transition for box-shadow
              },
            }}
            fontSize={fonts.body}
            gutterBottom
          >
            Suggestions & Feedback
          </Typography>
          <Typography
            onClick={handleNavigateIssues}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline", // Smooth transition for box-shadow
              },
            }}
            fontSize={fonts.body}
            mt={2}
            gutterBottom
          >
            Help Desk
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Box display={"block"} justifyContent={"start"}>
            <Typography fontSize={fonts.body} textAlign={"left"} gutterBottom>
              Subscribe to our Newsletter
            </Typography>
            <TextField
              label="Enter your Email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                width: "85%",
                borderColor: colors.teal50,

                "& .MuiOutlinedInput-root": {
                  "& fieldset ": {
                    borderColor: "#fff",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#fff", // Set border color to white on focus
                  },
                  input: {
                    color: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#fff",
                  },
                  "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                  "&.Mui-focused input": {
                    color: "#fff", // Set input text color to white on focus
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#fff", // Set the label color
                  "&.Mui-focused": {
                    color: "#fff", // Set label color to white on focus
                  },
                },
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <SendIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleSendClick}
                      />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          <Box mt={-5} display={"flex"}>
            <Typography
              mt={1.5}
              fontSize={fonts.body}
              sx={{ cursor: "pointer" }}
              gutterBottom
            >
              Follow Us
            </Typography>
            <Box ml={1}>
              <IconButton sx={{ color: "#fff" }}>
                <img
                  src={facebookFooter}
                  width={30}
                  height={30}
                  alt="facebook"
                />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <img
                  src={icons.instagram}
                  width={30}
                  height={30}
                  alt="instagram"
                />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <img src={icons.twitter} width={30} height={30} alt="twitter" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <img
                  src={icons.linkedin}
                  width={30}
                  height={30}
                  alt="linkedIn"
                />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography
        sx={{
          border: "solid 1px",
          borderBottom: "1px",
          borderColor: colors.teal100,
          mt: 2,
        }}
      ></Typography>
      {/* Footer bottom text */}
      <Typography
        fontSize={fonts.body}
        textAlign="center"
        sx={{ marginTop: 2 }}
        display={"inline-block"}
      >
        © 2024 Brand Name | Powered by{" "}
        <Typography
          fontSize={fonts.body}
          display={"inline-block"}
          sx={{ cursor: "pointer" }}
          onClick={() => window.open("https://www.dijinx.com/", "_blank")}
        >
          <u>Dijinx</u>
        </Typography>
      </Typography>
    </footer>
  );
};

export default Footer;
