import React from "react";
import { Tooltip } from "@mui/material";

const TruncateText = ({ text, maxChars }) => {
  const truncatedText =
    text?.length > maxChars ? `${text.slice(0, maxChars)}...` : text;

  return (
    <Tooltip title={text} placement="top">
      <span>{truncatedText}</span>
    </Tooltip>
  );
};

export default TruncateText;
