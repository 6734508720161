import React from "react";
import { Grid } from "@mui/material";

import CarouselComponent from "../carousel/CarouselComponent";
import SignUpForm from "./SignUpForm";
import useWindowWidth from "../windowSize/WindowWidth";
import { useLocation } from "react-router-dom";
import SignInForm from "../signin/SignInForm";
import NewPassword from "../forgotpw/NewPassword";
const AuthParentComponent = () => {
  const location = useLocation();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 768;

  const renderForm = () => {
    if (location.pathname === "/register") {
      return <SignUpForm />;
    } else if (location.pathname === "/forgot-password") {
      return <NewPassword />;
    } else {
      return <SignInForm />;
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100%",

        padding: 2,

        background: "white",
        borderRadius: isMobile ? 3 : "16px",
      }}
    >
      {windowWidth > 768 && (
        <Grid item xs={12} md={6}>
          <CarouselComponent />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          mt: isMobile ? 3 : 6,
          padding: 2,
        }}
      >
        {renderForm()}
      </Grid>
    </Grid>
  );
};

export default AuthParentComponent;
