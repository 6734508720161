import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import colors from "../../styles/colors";
import edit from "../../assets/edit.svg";
import EditExerciseModal from "../utils/EditExerciseModal";
import fonts from "../../styles/fontVars";
import SetTimingsModal from "../utils/TimingsModal";
import { formatTime } from "../utils/DateFormatter";
import { capitalizeFirstLetter } from "../utils/CapitalizeFirstLetter";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConfirmationDialog from "../utils/Dialog";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import LoadingBackdrop from "../utils/loader";
import { tr } from "framer-motion/client";

const DraggableSections = ({
  sections,
  handleDragEnd,
  isFromSection,
  isFromEditExerciseModal,
  onApiCall,
  onModalClose,
}) => {
  const [sectionsData, setSectionsData] = useState([]);
  const { makeApiCall } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTimingModalOpen, setIsTimingModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSetId, setSelectedSetId] = useState("");

  // Convert sections to array if it's a single object
  useEffect(() => {
    if (isFromEditExerciseModal && sections.setTimings) {
      // Convert setTimings array into individual draggable sections
      const timingSections = sections.setTimings.map((timing, index) => ({
        id: `timing-${timing.id || index}`,
        ...timing,
      }));
      setSectionsData(timingSections);
    } else {
      const sectionsArray = Array.isArray(sections)
        ? sections
        : sections
        ? [sections]
        : [];
      setSectionsData(sectionsArray);
    }
  }, [sections, isFromEditExerciseModal]);

  const handleOpenModal = (section, e) => {
    e?.stopPropagation(); // Add optional chaining in case e is undefined
    setSelectedSection(section);
    setIsModalOpen(true);
  };

  const handleOpenTimingModal = (section, e) => {
    setSelectedSetId(section.id);
    e?.stopPropagation();
    setSelectedSection(section);
    setIsTimingModalOpen(true);
  };

  const handleCloseTimingModal = () => {
    setIsTimingModalOpen(false);
    setSelectedSection(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSection(null);
    onModalClose(); // Call onModalClose function if it exists
  };

  const handleSaveTimings = async (exerciseTime, restTime) => {
    const payload = {
      exerciseTimeSeconds: exerciseTime,
      restTimeSeconds: restTime,
    };

    const response = await makeApiCall(
      routes.exercise.sets.update(selectedSetId),
      "PUT",
      payload
    );

    if (response.status) {
      onApiCall();
      handleCloseTimingModal();
    }
  };

  const handleSectionClick = (sectionId, section, index) => {
    if (isFromEditExerciseModal) {
      setSelectedSetId(sectionId);
      setIsTimingModalOpen(true);
      setSelectedSection(section);
    } else if (isFromSection) {
      setSelectedSection(section);
      setIsModalOpen(true);
    } else {
      navigate(`/workout/section?id=${sectionId}`);
    }
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const ShowDialog = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };
  const handleCancelDialog = () => {
    setOpenDialog(false);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    let response;
    if (isFromEditExerciseModal) {
      response = await makeApiCall(
        routes.exercise.sets.delete(deleteId),
        "DELETE"
      );
      // navigate(0);
      onApiCall();
    } else if (isFromSection) {
      response = await makeApiCall(routes.exercise.delete(deleteId), "DELETE");
    } else {
      response = await makeApiCall(routes.section.delete(deleteId), "DELETE");
      navigate(0);
    }
    if (response?.status && !isFromEditExerciseModal) {
      // Remove user from localStorage
      const updatedSections = sections.filter(
        (section) => section.id !== deleteId
      );
      setSectionsData(updatedSections);
    }
    setIsLoading(false);
    setIsModalOpen(false);
    setOpenDialog(false);
  };

  return (
    <>
      <LoadingBackdrop isLoading={isLoading} />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sectionList">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {sectionsData.map((section, index) => (
                <Draggable
                  key={section.id}
                  draggableId={section.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() =>
                        handleSectionClick(section.id, section, index)
                      }
                      sx={{
                        mb: 3,
                        borderRadius: "4px",
                        background:
                          "linear-gradient(270.34deg, #9388D3 0%, #74D0A9 100%)",
                        color: colors.gray50,
                        p: 2,
                        py: 2.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        transition: "outline-color 0.3s ease-in-out",
                        cursor: "pointer",
                        "&:hover": {
                          outline: `4px solid ${colors.teal400}`,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        <DragIndicatorOutlinedIcon
                          sx={{ mr: "3%", color: colors.gray50 }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                          }}
                        >
                          {isFromEditExerciseModal ? (
                            <Box>
                              <Typography
                                sx={{
                                  color: colors.gray50,
                                  fontSize: fonts.heading4,
                                }}
                              >
                                {formatTime(section.exerciseTimeSeconds)}{" "}
                                Exercise | {formatTime(section.restTimeSeconds)}{" "}
                                Rest
                              </Typography>
                            </Box>
                          ) : isFromSection ? (
                            <Box>
                              <Typography
                                sx={{
                                  color: colors.gray50,
                                  fontWeight: 600,
                                  fontSize: "20px",
                                }}
                              >
                                {section.title}
                              </Typography>
                              <Typography
                                sx={{ color: colors.gray50, fontSize: "14px" }}
                              >
                                {section.setTimings?.length} Exercises |{" "}
                                {formatTime(
                                  section.setTimings?.reduce(
                                    (total, timing) =>
                                      total + timing.exerciseTimeSeconds,
                                    0
                                  )
                                )}{" "}
                                |{" "}
                                {formatTime(
                                  section.setTimings?.reduce(
                                    (total, timing) =>
                                      total + timing.restTimeSeconds,
                                    0
                                  )
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <Box>
                              <Typography
                                sx={{
                                  color: colors.gray50,
                                  fontWeight: 600,
                                  fontSize: "20px",
                                }}
                              >
                                {section.title}
                              </Typography>
                              <Typography
                                sx={{ color: colors.gray50, fontSize: "14px" }}
                              >
                                {section.userExercises?.length} Exercises |{" "}
                                {formatTime(section.totalTimeSeconds)} |{" "}
                                {capitalizeFirstLetter(section.template)}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      {(isFromSection || isFromEditExerciseModal) && (
                        <IconButton
                          onClick={(e) =>
                            isFromEditExerciseModal
                              ? handleOpenTimingModal(section, e)
                              : handleOpenModal(section, e)
                          }
                          size="small"
                          sx={{
                            borderRadius: "50%",
                            padding: "10px",
                            "&:hover": {
                              background: colors.gray50,
                              "& img": {
                                filter: "brightness(0) invert(0)",
                              },
                            },
                          }}
                        >
                          <Box
                            component="img"
                            src={edit}
                            alt="edit"
                            sx={{ cursor: "pointer", color: "black" }}
                          />
                        </IconButton>
                      )}

                      <IconButton
                        size="small"
                        sx={{ color: colors.red100 }}
                        onClick={(e) => {
                          e.stopPropagation(); // Stop the click event from bubbling up
                          ShowDialog(isFromSection ? section.id : section.id); // Call the ShowDialog function with section.id
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill={colors.red900}
                          style={{
                            cursor: "pointer",
                            borderRadius: "50%",
                            padding: "10px",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              colors.red100)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "transparent")
                          }
                        >
                          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                        </svg>
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDialog}
        onConfirm={handleDelete}
        title="Delete"
        content={
          isFromEditExerciseModal
            ? "Are you sure you want to delete this set?"
            : isFromSection
            ? "Are you sure you want to delete this exercise? All its sets will be deleted along with it."
            : "Are you sure you want to delete this section? All its exercises will be deleted along with it."
        }
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
      {!isFromEditExerciseModal && (
        <EditExerciseModal
          open={isModalOpen}
          onClose={handleCloseModal}
          section={selectedSection}
          // onApiCall={goExerciseModal}
        />
      )}

      {isFromEditExerciseModal && (
        <SetTimingsModal
          open={isTimingModalOpen}
          onClose={handleCloseTimingModal}
          onSave={handleSaveTimings}
          isEdit={true}
          data={selectedSection}
        />
      )}
    </>
  );
};

export default DraggableSections;
