import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Drawer,
  IconButton,
  Slider,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { ChromePicker } from "react-color";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import { formatLabel } from "./CamelCaseFormatter";
export const handlePickerClick = (event) => {
  event.stopPropagation(); // Prevent parent onClick from toggling the picker
};
const ColorItem = ({ color, label, onChange, index, totalItems }) => {
  const [showPicker, setShowPicker] = useState(false);
  const itemRef = useRef(null);
  const [pickerPosition, setPickerPosition] = useState({
    top: "40px",
    left: "60px",
  });
  useEffect(() => {
    if (showPicker && itemRef.current) {
      const isNearBottom = totalItems - index <= 3; // Check if it's one of the last two items

      if (isNearBottom) {
        // Position the picker above the color circle for the last two items
        setPickerPosition({ top: "-150px", left: "60px" });
      } else {
        // Default position for other items
        setPickerPosition({ top: "40px", left: "60px" });
      }
    }
  }, [showPicker, index, totalItems]);

  return (
    <Box
      ref={itemRef}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid",
        borderColor: "lightgray",
        py: 1.5,
        cursor: "pointer",
      }}
      onClick={() => setShowPicker(!showPicker)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            border: "1px solid",
            borderColor: colors.gray900,
            backgroundColor: color,
            cursor: "pointer",
            mr: 3,
          }}
        />
        <Typography sx={{ color: colors.gray800 }}>
          {formatLabel(label)}
        </Typography>
      </Box>
      <IconButton>
        <ArrowForwardOutlinedIcon sx={{ color: colors.gray800 }} />
      </IconButton>
      {showPicker && (
        <Box
          sx={{ position: "absolute", ...pickerPosition, zIndex: 2 }}
          onClick={handlePickerClick}
        >
          <Box
            sx={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }}
            onClick={() => setShowPicker(false)}
          />
          <ChromePicker
            color={color}
            onChange={(color) => onChange(color.hex)}
          />
        </Box>
      )}
    </Box>
  );
};

const PresentModal = ({
  open,
  onClose,
  colorSettings,
  onSave,
  bgImageOverlayOpacityData,
}) => {
  const [themeColors, setThemeColors] = useState({});
  const [initialColors, setInitialColors] = useState({});
  const [bgImageOverlayOpacity, setBgImageOverlayOpacity] = useState(
    bgImageOverlayOpacityData
  );
  const [initialOpacity, setInitialOpacity] = useState(
    bgImageOverlayOpacityData
  );
  useEffect(() => {
    if (colorSettings) {
      const initial = {
        progressColor: colorSettings.progressColor,
        timerTextColor: colorSettings.timerTextColor,
        timerBgColor: colorSettings.timerBgColor,
        presentViewBgColor: colorSettings.presentViewBgColor,
      };
      setThemeColors(initial);
      setInitialColors(initial);
    }
  }, [colorSettings]);
  const handleColorChange = (key, newColor) => {
    setThemeColors((prevColors) => ({ ...prevColors, [key]: newColor }));
  };
  const handleOpacityChange = (event, newValue) => {
    setBgImageOverlayOpacity(newValue);
    setThemeColors((prevColors) => ({
      ...prevColors,
      bgImageOverlayOpacity: newValue,
    }));
  };

  const hasChanges =
    JSON.stringify(themeColors) !== JSON.stringify(initialColors) ||
    bgImageOverlayOpacity !== initialOpacity;

  const handleCancel = () => {
    setThemeColors(initialColors);
    setBgImageOverlayOpacity(initialOpacity);
    onClose();
  };

  const handleSave = () => {
    // Save all theme colors including opacity
    onSave({
      ...themeColors,
      bgImageOverlayOpacity,
    });
    onClose();
  };
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: "16px 16px 0 0", padding: 3, height: "750px" },
      }}
    >
      <Box sx={{ mx: "6.5%", mb: 3 }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: colors.gray800,
              fontSize: fonts.heading2,
              fontWeight: 600,
              mt: 3,
              mb: 2,
            }}
          >
            Customize Your Theme
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 0,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 3,
                textTransform: "none",
                background: colors.gray200,
                color: colors.gray800,
              }}
              endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
              sx={{
                textTransform: "none",
                background: colors.green100,
                color: colors.green900,
                px: 2.5,
              }}
              onClick={handleSave}
              disabled={!hasChanges}
            >
              Done
            </Button>
          </Box>
        </Box>
        <Box width={350} sx={{ mt: 4 }}>
          <Typography sx={{ color: colors.gray800, mb: 2 }}>
            Background Image Overlay Opacity
          </Typography>
          <Slider
            value={bgImageOverlayOpacity}
            onChange={handleOpacityChange}
            aria-labelledby="bg-image-overlay-opacity-slider"
            step={0.1}
            marks
            min={0}
            max={1}
            valueLabelDisplay="auto"
            sx={{ color: colors.teal400 }}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          {Object.entries(themeColors).map(([key, value], index, array) => (
            <ColorItem
              key={key}
              color={value}
              label={key}
              onChange={(newColor) => handleColorChange(key, newColor)}
              index={index}
              totalItems={array.length}
            />
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default PresentModal;
