import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const handleNavigateProfile = () => {
    navigate("/profile");
  };
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const handleNavigateHome = () => {
    navigate("/dashboard");
  };
  return (
    <AppBar
      position="static"
      sx={{ background: "white", color: colors.gray900, height: "80px" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: "3%",
          mt: 1,
        }}
      >
        <Box display={"flex"}>
          <IconButton color="inherit" onClick={handleNavigateHome}>
            <Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" />
          </IconButton>
          <Typography
            onClick={handleNavigateHome}
            fontSize={fonts.heading1}
            fontWeight={600}
            mt={0.2}
            ml={2}
            component="div"
            sx={{
              cursor: "pointer",
              background: "linear-gradient(to right, #74D0A9, #9388D3)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            Join Our Evolution
          </Typography>
        </Box>
        <IconButton
          onClick={handleNavigateProfile}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Removes hover background
            },
            padding: 0, // Optional: removes padding around the Avatar if desired
          }}
          disableRipple
          color="inherit"
        >
          <Avatar
            alt="User Avatar"
            sx={{
              mr: 2,
              border: "3px solid transparent",
              // Create gradient border
              backgroundImage:
                "linear-gradient(white, white), linear-gradient(148.43deg, #9747FF -2.38%, #00796B 40.71%)",
              backgroundOrigin: "border-box",
              backgroundClip: "content-box, border-box",
            }}
            src={
              storedUser?.image ||
              "https://d3vdd33llamc8d.cloudfront.net/assets/empty/placeholder.png"
            }
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
