import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import homeEmpty from "../../assets/homeEmpty.svg";
import colors from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import AddWorkoutModal from "../../components/utils/AddWorkoutModal";
import LoadingBackdrop from "../../components/utils/loader";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
const img = "https://d3vdd33llamc8d.cloudfront.net/assets/empty/home.png";
const WelcomeStudio = ({ username }) => {
  const navigate = useNavigate();
  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState(false);
  const { makeApiCall } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseTimingModal = () => {
    setIsWorkoutModalOpen(false);
  };
  const handleOpenTimingModal = () => {
    setIsWorkoutModalOpen(true);
  };
  const handleAddWorkout = async (workoutName) => {
    const payload = {
      title: workoutName,
    };
    if (workoutName) {
      setIsLoading(true);
    }
    const response = await makeApiCall(routes.workout.add, "POST", payload);
    setIsLoading(false);

    if (response?.status) {
      navigate(`/workout?id=${response.data.workout.id}`);
    }

    // Implement the logic to save the timings in your application state or backend
  };
  const storedUser = JSON.parse(localStorage.getItem("user"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 4,
        mx: "10%",
      }}
    >
      <LoadingBackdrop isLoading={isLoading} />

      <Box
        sx={{
          flex: {
            lg: 1.3,
            sm: 1,
          },
          pr: 0,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            background: "linear-gradient(to right, #74D0A9, #9388D3)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
            mb: "6%",
            textAlign: "left",
            fontSize: {
              lg: "40px",
              sm: "34px",
            },
          }}
        >
          Welcome to your studio {storedUser.username}
        </Typography>
        <Typography
          variant="body1"
          textAlign={"left"}
          sx={{ color: colors.gray600, mb: "7%" }}
        >
          Don't see any workouts yet? No worries. <br />
          Add new workouts and get started today.
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            background: "linear-gradient(to right, #74D0A9, #9388D3)",
            textTransform: "none",
            borderRadius: "4px",
            padding: "8px 20px",
            display: "flex",
            justifyContent: "left",
            boxShadow:
              "box-shadow: 0px 3px 1px -2px #00000033 ,0px 2px 2px 0px #00000024,0px 1px 5px 0px #0000001F",
          }}
          onClick={handleOpenTimingModal}
        >
          New Workout
        </Button>
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <img
          src={img}
          alt="Workout Illustration"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Box>
      <AddWorkoutModal
        open={isWorkoutModalOpen}
        onClose={handleCloseTimingModal}
        onSave={handleAddWorkout}
      />
    </Box>
  );
};

export default WelcomeStudio;
