const routes = {
  auth: {
    register: "/user/account/register",
    login: "/user/account/login",
    verify: "/user/account/register/verify",
    logout: "/user/account/logout",
    resend: "/user/account/register/send-code",
    sso: "/user/account/sso",
  },
  profile: {
    account: "/user/account",
    uploads: "/user/account/uploads",
  },
  password: {
    sendCode: "/user/account/password/send-code",
    verifyCode: "/user/account/password/verify-code",
    password: "/user/account/password",
  },
  workout: {
    add: "/workout",
    update: (id) => `/workout/${id}`,
    get: (id) => `/workout/${id}?asViewable=false`,
    delete: (id) => `/workout/${id}`,
    search: ({ pageNumber = 1, sortBy = "relevance", ...restParams } = {}) => {
      const baseUrl = `/workout?pageNumber=${pageNumber}&sortBy=${sortBy}`;

      const additionalParams = Object.keys(restParams)
        .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
        .join("&");

      return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
    },
  },
  section: {
    add: "/section",
    reorder: "/section/re-order",
    get: (id) => `/section/${id}`,
    update_work: (id) => `/section/work/${id}`,
    update_present: (id) => `/section/present/${id}`,
    delete: (id) => `/section/${id}`,
    work: (id) => `/section/work/${id}`,
    present: (id) => `/section/present/${id}`,
  },
  exercise: {
    library: ({ pageNumber = 1, sortBy = "relevance", ...restParams } = {}) => {
      const baseUrl = `/exercise?pageNumber=${pageNumber}&sortBy=${sortBy}`;

      const additionalParams = Object.keys(restParams)
        .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
        .join("&");

      return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
    },
    add: "/user/exercise",
    get: (id) => `/user/exercise/${id}`,
    delete: (id) => `/user/exercise/${id}`,
    update: (id) => `/user/exercise/${id}`,
    reorder: "/user/exercise/re-order",

    sets: {
      add: "/user/exercise/set",
      update: (id) => `/user/exercise/set/${id}`,
      reorder: "/user/exercise/set/re-order",
      delete: (id) => `/user/exercise/set/${id}`,
    },
  },
  ticket: {
    create: "/ticket/issue",
    send_message: "/ticket/issue/message",
    get: (id) => `/ticket/${id}`,
    search: ({ pageNumber = 1, sortBy = "relevance", ...restParams } = {}) => {
      const baseUrl = `/ticket?pageNumber=${pageNumber}&sortBy=${sortBy}`;

      const additionalParams = Object.keys(restParams)
        .map((key) => `${key}=${encodeURIComponent(restParams[key])}`)
        .join("&");

      return additionalParams ? `${baseUrl}&${additionalParams}` : baseUrl;
    },
    suggestion: {
      create: "/ticket/suggestion",
    },
  },
  newsletter: {
    add: "/newsletter",
  },
};

export default routes;
