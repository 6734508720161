import React, { useEffect, useRef, useState } from "react";
import { Box, Pagination, Typography, Button, Grid } from "@mui/material";
import FavouritesSection from "../../components/fav_section/FavouritesSection";
import AllWorkoutsSection from "../../components/workout/AllWorkoutsSection";
import colors from "../../styles/colors";
import { Add } from "@mui/icons-material";
import fonts from "../../styles/fontVars";
import AddWorkoutModal from "../../components/utils/AddWorkoutModal";
import { useNavigate } from "react-router-dom";
import HomePageSkeleton from "./HomePageSkeletonLoader";
import { title } from "framer-motion/client";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { formatDaysAgo } from "../../components/utils/DateFormatter";
import LoadingBackdrop from "../../components/utils/loader";
import WelcomeStudio from "./HomeEmptyPage";
import AllWorkoutSkeleton from "../../components/workout/AllWorkoutsSkeletonLoader";

function HomePage() {
  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [isAllWorkoutLoading, setIsAllWorkoutLoading] = useState(false);
  const { makeApiCall } = useApiRequest();
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [sortBy, setSortBy] = useState("relevance"); // Track the current page
  const [searchText, setSearchText] = useState(""); // State for search text
  const [onlyFavourites, setOnlyFavourites] = useState(true); // State for search text
  const [onlyFavData, setOnlyFavData] = useState([]); // State for search text
  const [showWelcomeStudio, setShowWelcomeStudio] = useState(false);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const fetchWorkouts = async (
    page = 1,
    sortBy = "relevance",
    searchText = ""
  ) => {
    const params = {
      pageNumber: page,
      sortBy: sortBy,
    };

    // Conditionally add searchText to params if it's not empty or null
    if (searchText) {
      params.searchText = searchText;
    }
    const response = await makeApiCall(
      routes.workout.search(params), // Pass the params object here
      "GET"
    );
    if (response.status && response?.data?.workouts) {
      const workouts = response.data.workouts;

      // Check if workouts array is empty
      if (workouts.length === 0) {
        setShowWelcomeStudio(true); // Set state to show WelcomeStudio
        return; // Exit the function
      } else {
        setShowWelcomeStudio(false); // Reset state if workouts are found
      }
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.pageNumber);
      const updatedCardData = response.data.workouts.map((workout) => {
        // Get all thumbnailCdnUrls from userExercises across all sections
        const exerciseImages = workout.sections
          .flatMap((section) => section.userExercises)
          .filter((userExercise) => userExercise?.exercise?.thumbnailCdnUrl)
          .map((userExercise) => userExercise.exercise.thumbnailCdnUrl);

        return {
          id: workout.id,
          title: workout.title,
          // Use exercise images if available, otherwise use placeholder
          images:
            exerciseImages.length > 0
              ? exerciseImages
              : [
                  "https://d3vdd33llamc8d.cloudfront.net/assets/empty/placeholder.png",
                ],
          isFavourite: workout.isFavourite,
          editedLabel: workout.updatedOn ? "Edited" : "Created",
          edited: workout.updatedOn
            ? formatDaysAgo(workout.updatedOn)
            : formatDaysAgo(workout.createdOn),
          totalTimeSeconds: workout.totalTimeSeconds,
          sections: workout.sections.map((section) => ({
            id: section.id,
            workoutId: section.workoutId,
            totalTimeSeconds: section.totalTimeSeconds,
            indexPosition: section.indexPosition,
            title: section.title,
            template: section.template,
            userExercises: section.userExercises,
            createdOn: section.createdOn,
            updatedOn: section.updatedOn,
            deletedOn: section.deletedOn,
          })),
        };
      });

      setCardData(updatedCardData);
    }
  };
  const fetchFav = async (onlyFavourites = true) => {
    const params = {
      sortBy: "lastActivity",
    };

    // Conditionally add searchText to params if it's not empty or null
    if (onlyFavourites) {
      params.onlyFavourites = onlyFavourites;
    }
    const response = await makeApiCall(
      routes.workout.search(params), // Pass the params object here
      "GET"
    );

    if (response.status && response?.data?.workouts) {
      const updatedCardData = response.data.workouts.map((workout) => ({
        id: workout.id,
        title: workout.title,
        images: [
          "https://d3vdd33llamc8d.cloudfront.net/assets/empty/placeholder.png", // Placeholder image
        ],
        isFavourite: workout.isFavourite,
        totalTimeSeconds: workout.totalTimeSeconds,
        editedLabel: workout.updatedOn ? "Edited" : "Created",
        edited: workout.updatedOn
          ? formatDaysAgo(workout.updatedOn)
          : formatDaysAgo(workout.createdOn),
        sections: workout.sections.map((section) => ({
          id: section.id,
          workoutId: section.workoutId,
          totalTimeSeconds: section.totalTimeSeconds,
          indexPosition: section.indexPosition,
          title: section.title,
          template: section.template,
          userExercises: section.userExercises,
          createdOn: section.createdOn,
          updatedOn: section.updatedOn,
          deletedOn: section.deletedOn,
        })),
      }));

      setOnlyFavData(updatedCardData);
    }
  };

  const hasLoadedOnce = useRef(false);
  useEffect(() => {
    let isMounted = true;

    const initializePage = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser && isMounted) {
        }

        if (isMounted) {
          // Run skeleton loader only on the first load
          if (!hasLoadedOnce.current) {
            setIsSkeletonLoading(true);
            hasLoadedOnce.current = true; // Mark initial load as completed

            // Fetch both on initial load
            await Promise.all([fetchWorkouts(currentPage, sortBy), fetchFav()]);

            setIsSkeletonLoading(false);
          } else {
            // Fetch only workouts on subsequent page changes
            setIsAllWorkoutLoading(true);
            await fetchWorkouts(currentPage, sortBy);
            setIsAllWorkoutLoading(false);
          }
        }
      } catch (error) {
        console.error("Error initializing page:", error);
      }
    };

    initializePage();

    return () => {
      isMounted = false;
    };
  }, [currentPage, sortBy]); // Triggers on page or sort change

  const handleOpenTimingModal = () => {
    setIsWorkoutModalOpen(true);
  };
  const handleCloseTimingModal = () => {
    setIsWorkoutModalOpen(false);
  };
  const handleAddWorkout = async (workoutName) => {
    const payload = {
      title: workoutName,
    };
    if (workoutName) {
      setIsLoading(true);
    }
    const response = await makeApiCall(routes.workout.add, "POST", payload);
    setIsLoading(false);

    if (response?.status) {
      navigate(`/workout?id=${response.data.workout.id}`);
    }

    // Implement the logic to save the timings in your application state or backend
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page when the user changes pages
  };
  const handleSearch = (text) => {
    setSearchText(text);
    fetchWorkouts(currentPage, sortBy, text);
  };
  // Callback function to handle filter changes
  const handleFilterChange = (selectedFilter) => {
    fetchWorkouts(currentPage, selectedFilter);
    // Add any other logic you want to handle when the filter changes
  };
  const handleDataUpdate = (updatedData, updatedCard) => {
    setCardData(updatedData);

    if (updatedCard.isFavourite) {
      // If the card was favorited, add it to the beginning of onlyFavData
      setOnlyFavData((prevFavData) => [
        updatedCard,
        ...prevFavData.filter((card) => card.id !== updatedCard.id),
      ]);
    } else {
      // If the card was unfavorited, remove it from onlyFavData
      setOnlyFavData((prevFavData) =>
        prevFavData.filter((card) => card.id !== updatedCard.id)
      );
    }
  };
  const handleDataUpdate2 = (updatedData, updatedCard) => {
    // Update the onlyFavData as before
    if (updatedCard.isFavourite) {
      // If the card was favorited, add it to the beginning of onlyFavData
      setOnlyFavData((prevFavData) => [
        updatedCard,
        ...prevFavData.filter((card) => card.id !== updatedCard.id),
      ]);
    } else {
      // If the card was unfavorited, remove it from onlyFavData
      setOnlyFavData((prevFavData) =>
        prevFavData.filter((card) => card.id !== updatedCard.id)
      );
    }

    // Update the cardData to reflect the unfavourited state
    setCardData((prevCardData) =>
      prevCardData.map((card) =>
        card.id === updatedCard.id ? { ...card, isFavourite: false } : card
      )
    );
  };

  const handleDelete = async (selectedWorkoutId) => {
    setIsLoading(true);
    const response = await makeApiCall(
      routes.workout.delete(selectedWorkoutId),
      "DELETE"
    );
    if (response?.status) {
      // Filter out the deleted workout from both cardData and onlyFavData
      const updatedCardData = cardData.filter(
        (card) => card.id !== selectedWorkoutId
      );
      const updatedOnlyFavData = onlyFavData.filter(
        (card) => card.id !== selectedWorkoutId
      );

      setCardData(updatedCardData);
      setOnlyFavData(updatedOnlyFavData);
      setIsLoading(false);
    }
  };

  // const refetchData = async () => {
  //   await fetchFav(currentPage, sortBy, onlyFavourites);
  // };
  return (
    <Box sx={{ width: "100%" }}>
      <LoadingBackdrop isLoading={isLoading} />
      {showWelcomeStudio ? (
        <WelcomeStudio /> // Render WelcomeStudio if no workouts
      ) : (
        <>
          {isSkeletonLoading ? (
            <HomePageSkeleton />
          ) : (
            <>
              <Box sx={{ marginTop: 4, width: "100%" }}>
                <Grid
                  display="flex"
                  mx={"6.3%"}
                  justifyContent={"space-between"}
                  sx={{ marginTop: 8 }}
                >
                  <Typography
                    fontSize={fonts.heading1}
                    fontWeight={600}
                    sx={{
                      background: "linear-gradient(to right, #74D0A9, #9388D3)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent", // Ensures the gradient is applied to the text
                      backgroundClip: "text",
                      textFillColor: "transparent",
                      textAlign: "left",
                    }}
                    gutterBottom
                  >
                    Welcome to your studio {storedUser?.username}
                  </Typography>
                  <Button
                    variant="contained"
                    fontSize={fonts.heading4}
                    size="small"
                    endIcon={<Add />}
                    sx={{
                      marginLeft: 2,
                      textTransform: "none",
                      background: colors.teal400,
                      fontWeight: 400,
                      height: "40px",
                      width: "150px",
                      px: 2,
                    }}
                    onClick={handleOpenTimingModal}
                  >
                    New Workout
                  </Button>
                </Grid>

                {/* Favourites Section */}

                <Box mt={5}>
                  {onlyFavData.length >= 1 && (
                    <>
                      <FavouritesSection
                        Data={onlyFavData}
                        onDataUpdate={handleDataUpdate2}
                        onDelete={handleDelete}
                      />{" "}
                    </>
                  )}
                </Box>

                <Box mt={5}>
                  {/* All Workouts Section */}
                  {isAllWorkoutLoading ? (
                    <AllWorkoutSkeleton />
                  ) : (
                    <AllWorkoutsSection
                      Data={cardData}
                      onFilterChange={handleFilterChange}
                      onSearch={handleSearch}
                      onDataUpdate={handleDataUpdate}
                      onDelete={handleDelete}
                    />
                  )}
                </Box>

                {/* Pagination */}
                {totalPages > 1 && (
                  <Pagination
                    count={totalPages} // Use dynamic totalPages from state
                    page={currentPage} // Control the current page
                    onChange={handlePageChange} // Handle page changes
                    color="primary"
                    sx={{
                      marginTop: 0,
                      justifyContent: "center",
                      display: "flex",
                    }}
                  />
                )}
                <AddWorkoutModal
                  open={isWorkoutModalOpen}
                  onClose={handleCloseTimingModal}
                  onSave={handleAddWorkout}
                />
                {/* Footer */}
              </Box>
            </>
          )}
        </>
      )}
      <Box sx={{ mt: 7 }}></Box>
    </Box>
  );
}

export default HomePage;
