import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import fonts from "../../styles/fontVars";
import colors from "../../styles/colors";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LoadingBackdrop from "./loader";

const SetTimingsModal = ({ open, onClose, onSave, isEdit, data }) => {
  const [exerciseTime, setExerciseTime] = useState("");
  const [restTime, setRestTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isEdit && data) {
      setExerciseTime(data.exerciseTimeSeconds || "");
      setRestTime(data.restTimeSeconds || "");
    } else {
      setExerciseTime("");
      setRestTime("");
    }
  }, [isEdit, data]);
  const handleSave = async () => {
    setIsLoading(true);

    await onSave(exerciseTime, restTime);
    setIsLoading(false);

    setRestTime("");
    setExerciseTime("");
    onClose();
  };

  const AcuteIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray600}
      style={{ marginRight: 10 }}
    >
      <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
    </svg>
  );

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <LoadingBackdrop isLoading={isLoading} />

      <DialogContent>
        <Typography
          fontSize={fonts.heading4}
          mt={1}
          color={colors.gray800}
          fontWeight={500}
          textAlign={"left"}
        >
          Set Timings
        </Typography>
        <TextField
          label="Exercise Time (seconds)"
          type="number"
          id="num"
          value={exerciseTime}
          onChange={(event) => {
            const value = event.target.value;

            // Allow empty value to let the user clear the field
            if (value === "") {
              setExerciseTime("");
            } else {
              // Prevent negative values
              setExerciseTime(Math.max(0, Number(value)));
            }
          }}
          sx={{
            mt: 3,
            width: {
              lg: "100%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
          helperText="This defines the time for each set"
          slotProps={{
            input: {
              // Adjust margin if needed
              startAdornment: <AcuteIcon />,
            },
          }}
        />

        <TextField
          label="Rest Time (seconds)"
          type="number"
          id="num"
          value={restTime}
          onChange={(event) => {
            const value = event.target.value;

            // Allow empty value to let the user clear the field
            if (value === "") {
              setRestTime("");
            } else {
              // Prevent negative values
              setRestTime(Math.max(0, Number(value)));
            }
          }}
          sx={{
            mt: 3,
            width: {
              lg: "100%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
          helperText="This defines the time in-between each set"
          slotProps={{
            input: {
              // Adjust margin if needed
              startAdornment: (
                <HistoryOutlinedIcon
                  fontSize="small"
                  sx={{ color: colors.gray600, mr: 1.6 }}
                />
              ),
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ mb: 1, mt: -3, mr: 2 }}>
        <Button onClick={onClose} sx={{ color: colors.gray500 }}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SetTimingsModal;
