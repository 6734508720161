import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  Card,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@mui/material";
import colors from "../../styles/colors";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./index.css";
import fonts from "../../styles/fontVars";
import DividerLine from "../utils/DividerLine";
import { useNavigate } from "react-router-dom";
import { PlayArrow, MoreVert, Info, Delete } from "@mui/icons-material";
import TruncateText from "../utils/TruncateText";
import { formatTime } from "../utils/DateFormatter";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ConfirmationDialog from "../utils/Dialog";
import LoadingBackdrop from "../utils/loader";

const img = "https://d3vdd33llamc8d.cloudfront.net/exercises/9/thumbnail.png";
const Arrow = ({ direction, onClick, disabled }) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: colors.gray200,
        borderRadius: "50%",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        "&:hover": {
          backgroundColor: "white",
        },
        mx: 0.5, // Add some margin between buttons
      }}
    >
      {direction === "next" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
    </IconButton>
  );
};

const FavouritesSection = ({ Data, onDataUpdate, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { makeApiCall } = useApiRequest();
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [cardData, setCardData] = useState(Data);
  const [isNavigationDisabled, setIsNavigationDisabled] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkoutId, setSelectedWorkoutId] = useState(null);

  const handleNavigateWorkout = (id) => {
    navigate(`/workout?id=${id}`); // Navigate to the workout page with the workout id
  };
  useEffect(() => {
    setCardData(Data); // Sync local state with the passed prop
  }, [Data]);
  const handlePlayButton = (id) => {
    if (id) {
      window.location.href = `https://main.dnxkce1wa4yhv.amplifyapp.com?id=${id}`;
    } else {
      window.location.href = "/404"; // Redirect to the 404 page
    }
  };
  const sliderRef = useRef(null);

  const getCurrentBreakpoint = () => {
    const width = window.innerWidth;
    if (width <= 480) return "mobile";
    if (width <= 900) return "tablet";
    if (width <= 1280) return "desktop";
    return "large";
  };

  // Function to get slidesToShow based on current breakpoint
  const getSlidesToShow = (breakpoint) => {
    switch (breakpoint) {
      case "mobile":
        return 1;
      case "tablet":
        return 2;
      case "desktop":
        return 3;
      default:
        return 4;
    }
  };

  // Update navigation disabled state
  const updateNavigationState = () => {
    const breakpoint = getCurrentBreakpoint();
    const slidesToShow = getSlidesToShow(breakpoint);
    setCurrentBreakpoint(breakpoint);
    setIsNavigationDisabled(cardData?.length <= slidesToShow);
  };

  // Add resize listener
  useEffect(() => {
    updateNavigationState();
    const handleResize = () => {
      updateNavigationState();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [cardData]);

  // Update when Data changes
  useEffect(() => {
    setCardData(Data);
    updateNavigationState();
  }, [Data]);

  const settings = {
    dots: false,
    infinite: false, // Changed to false since we're handling navigation visibility
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToNext = () => {
    if (!isNavigationDisabled) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrev = () => {
    if (!isNavigationDisabled) {
      sliderRef.current.slickPrev();
    }
  };

  // const settings = {
  //   dots: false,
  //   infinite: cardData?.length > 4,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   initialSlide: 0, // Start from the first slide
  //   responsive: [
  //     {
  //       breakpoint: 1280,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 900,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  // const goToNext = () => {
  //   sliderRef.current.slickNext();
  // };

  // const goToPrev = () => {
  //   sliderRef.current.slickPrev();
  // };

  const handleClick = (event, workout) => {
    setAnchorEl(event.currentTarget);
    setSelectedWorkout(workout); // Store the selected workout
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedWorkout(null); // Clear the selected workout when closing
  };

  const handlePlay = (id) => {
    if (id) {
      window.location.href = `https://main.dnxkce1wa4yhv.amplifyapp.com?id=${id}`;
    } else {
      window.location.href = "/404"; // Redirect to the 404 page
    }
  };

  const handleMoreInfo = (id) => {
    navigate(`/workout?id=${id}`); // Navigate to the workout page with the workout id
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const ShowCloseDialog = (id) => {
    setSelectedWorkoutId(id);
    setOpenDialog(true);
  };

  const handleDelete = (id) => {
    onDelete(selectedWorkoutId); // Call the shared delete handler
    setOpenDialog(false);
  };
  const handleIconClick = async (e, id, title) => {
    e.stopPropagation(); // Prevent card click event from triggering
    const updatedCardData = cardData.map((card) => {
      if (card.id === id) {
        return { ...card, isFavourite: !card.isFavourite };
      }
      return card;
    });

    setCardData(updatedCardData);

    const updatedCard = updatedCardData.find((card) => card.id === id);
    const newFavoriteStatus = updatedCard.isFavourite;

    // Toggle the favorite status
    const payload = {
      title: title,
      isFavourite: newFavoriteStatus,
    };
    const response = await makeApiCall(
      routes.workout.update(id),
      "PUT",
      payload
    );
    if (!response.status) {
      // If the API call fails, revert the change
      setCardData(cardData);
    } else {
      // If successful, update the parent component's data
      onDataUpdate(updatedCardData, { id, isFavourite: false });
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
        mx={"6.3%"}
      >
        <Typography variant="h6">Favourites</Typography>

        <Box>
          <Arrow
            direction="prev"
            onClick={goToPrev}
            disabled={isNavigationDisabled}
          />
          <Arrow
            direction="next"
            onClick={goToNext}
            disabled={isNavigationDisabled}
          />
        </Box>
      </Box>
      <Box mx={"6.3%"}>
        <DividerLine />
      </Box>

      {/* <Box
        sx={{
          width: { xs: "100%", md: "100%", lg: "120%" }, // Adjust width for responsiveness
        }}
      > */}
      <Box ml={"5%"} mr={"6%"}>
        <Slider ref={sliderRef} {...settings}>
          {cardData.map((workout, index) => (
            <Box key={workout.id || index} p={3}>
              <Card
                onClick={() => handleNavigateWorkout(workout.id)}
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: 2,
                  "&:hover .hover-image": {
                    transform: "scale(1.1)", // Scale image on hover
                    transition: "transform 0.3s ease-in-out", // Smooth transition
                  },
                  "&:hover .hover-icon": {
                    backgroundColor: colors.teal600, // Change button background color on hover
                    transition: "background-color 0.3s ease", // Smooth transition
                  },
                  "&:hover .hover-play-arrow": {
                    color: colors.teal50, // Change PlayArrow color on hover
                  },
                  "&:hover .hover-layout": {
                    opacity: 0.5,
                  },
                  "&:hover": {
                    boxShadow:
                      "4px 3px 20px 2px #00796B54, -4px -3px 20px 0px #9388D380", // Apply the box shadows on hover
                    transition: "box-shadow 0.3s ease", // Smooth transition for box-shadow
                  },
                  mr: "8%",
                  overflow: "hidden",
                }}
              >
                <CardMedia
                  component="img"
                  // height="100%"
                  height="200"
                  sx={{ objectFit: "cover" }}
                  image={
                    // Check for sections with userExercises containing a thumbnail
                    workout.sections?.some((section) =>
                      section.userExercises?.some(
                        (userExercise) => userExercise.exercise?.thumbnailCdnUrl
                      )
                    )
                      ? workout.sections.find((section) =>
                          section.userExercises.some(
                            (userExercise) =>
                              userExercise.exercise?.thumbnailCdnUrl
                          )
                        ).userExercises[0].exercise.thumbnailCdnUrl // Use the first matching thumbnail
                      : "https://d3vdd33llamc8d.cloudfront.net/assets/empty/placeholder.png" // Default placeholder image
                  }
                  alt="workout image"
                  className="hover-image"
                />
                <Box
                  className="hover-layout"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(to bottom, #898888 0%, #232323 62%, #000000 100%)",
                    opacity: 0.6, // You can adjust the opacity if needed
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    padding: "5px 10px",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    color: "#fff",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#e8eaed"
                  >
                    <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
                  </svg>
                  {workout?.totalTimeSeconds > 0 && (
                    <Typography ml={0.7} mt={0.2} variant="caption">
                      {formatTime(workout?.totalTimeSeconds)}
                    </Typography>
                  )}
                </Box>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling to parent
                    handlePlayButton(workout.id); // Call your play button handler
                  }}
                  className="hover-icon"
                  sx={{
                    position: "absolute",
                    bottom: 10,
                    right: 10,
                    backgroundColor: colors.teal50,
                    borderRadius: "50%",
                  }}
                >
                  <PlayArrow
                    className="hover-play-arrow"
                    sx={{
                      color: colors.teal900,
                      transition: "color 0.3s ease",
                    }}
                  />
                </IconButton>

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    left: 10,
                    color: "#fff",
                  }}
                >
                  <Typography fontSize={fonts.heading4} color={colors.gray50}>
                    <TruncateText text={workout.title} maxChars={15} />
                  </Typography>
                  <Typography
                    fontSize={fonts.info}
                    textAlign={"left"}
                    color={colors.teal200}
                  >
                    {`${workout?.sections?.reduce(
                      (total, section) =>
                        total + section?.userExercises?.length,
                      0
                    )} Exercises`}
                  </Typography>
                </Box>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the Card's onClick from being triggered
                    handleClick(e, workout);
                  }}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "#fff",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)" },
                  }}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e) => {
                    e.stopPropagation(); // Prevents the Card's onClick from being triggered
                    handleCloseMenu(); // Close the menu
                  }}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedWorkout) {
                        // Ensure there's a selected workout
                        handlePlay(selectedWorkout.id);
                      }
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <PlayArrow fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Play</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedWorkout) {
                        handleIconClick(e, selectedWorkout.id, workout.title);
                      }
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <FavoriteOutlinedIcon
                        fontSize="small"
                        // style={{ color: colors.teal700 }}
                      />
                    </ListItemIcon>
                    <Typography variant="inherit">Unfavourite</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedWorkout) {
                        // Ensure there's a selected workout
                        handleMoreInfo(selectedWorkout.id);
                      }
                      // handleMoreInfo();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <Info fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">More Info</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedWorkout) {
                        ShowCloseDialog(selectedWorkout.id);
                      }
                      handleCloseMenu();
                    }}
                    sx={{ color: "red" }}
                  >
                    <ListItemIcon>
                      <Delete fontSize="small" style={{ color: "red" }} />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete</Typography>
                  </MenuItem>
                </Menu>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleDelete}
        title="Delete"
        content="Are you sure you want to delete this workout? All its sections and exercises will be deleted along with it"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
      {/* </Box> */}
    </Box>
  );
};

export default FavouritesSection;
