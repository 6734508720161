import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import colors from "../../styles/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DividerLine from "../../components/utils/DividerLine";
import DraggableSections from "../../components/workout/DraggableSections";
import fonts from "../../styles/fontVars";
import ExerciseModal from "../../components/section/ExerciseDrawer";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import FileUploader from "../../components/utils/FileUploader";
import ColorsModal from "../../components/utils/ColorsModal";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import PresentTemplateSectionPage from "./PresentTemplateSection";
import ConfirmationDialog from "../../components/utils/Dialog";
import SectionPageSkeleton from "./Loader/SectionSkeletonLoader";
import TruncateText from "../../components/utils/TruncateText";
import EditableTitle from "../../components/utils/EditableTitle";
import LoadingBackdrop from "../../components/utils/loader";

const SectionPage = () => {
  const [sections, setSections] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [colorSettings, setColorSettings] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialSections, setInitialSections] = useState([]);
  const [hasOrderChanges, setHasOrderChanges] = useState(false);
  const [hasAdvancedChanges, setHasAdvancedChanges] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Extract the 'id' parameter

  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(true);
  const { makeApiCall } = useApiRequest();
  const [sectionData, setSectionData] = useState("");
  const navigate = useNavigate();

  const fetchData = async (noLoader) => {
    if (!noLoader) {
      setIsLoading(true);
    }

    const response = await makeApiCall(routes.section.get(id), "GET");
    const sectionData = response.data.section;
    const colorSettings = {
      progressColor: sectionData.progressColor,
      timerTextColor: sectionData.timerTextColor,
      timerBgColor: sectionData.timerBgColor,
      exerciseNameTextBgColor: sectionData.exerciseNameTextBgColor,
      exerciseNameTextColor: sectionData.exerciseNameTextColor,
      setCounterTextBgColor: sectionData.setCounterTextBgColor,
      setCounterTextColor: sectionData.setCounterTextColor,
      restViewBgColor: sectionData.restViewBgColor,
      restViewTextColor: sectionData.restViewTextColor,
      presentViewBgColor: sectionData.presentViewBgColor,
    };

    // Pass colorSettings to ColorsModal component
    setColorSettings(colorSettings); // Store it in state if needed
    // Set state variables based on response data
    setSectionData(sectionData);
    setSections(sectionData.userExercises);
    setInitialSections(sectionData.userExercises);
    setInitialData(sectionData);
    // Map fields to their respective states
    setEditingTitle(sectionData.title);
    setShowCircularProgressBar(sectionData.circularProgress);
    setPreparationTime(sectionData.preparationTimeSeconds || ""); // Handle null values
    setHeading(sectionData.restTitle);
    setSubHeading(sectionData.restBody);
    setTimerType(sectionData.timerType);
    setProgressPosition(sectionData.progressPosition);
    setUpNextPosition(sectionData.upNextPosition);
    setRestTime(sectionData.restTimeSeconds || ""); // Handle null values
    setShowSkipLastReset(sectionData.skipLastRest);
    setShowSkipFirstReset(sectionData.skipFirstRest);
    setShowUpNext(sectionData.showUpNext);
    setShowLogo(sectionData.showLogo);
    setLogoFile(sectionData.logoCdnUrl || null); // Set logo URL or null if not available

    if (!noLoader) {
      setIsLoading(false);
    }
    setIsSkeletonLoader(false);
  };
  useEffect(() => {
    if (id) {
      fetchData(true); // Call the async function if id exists
    }
  }, [id]); // Add 'id' as a dependency

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [isColorModalOpen, setIsColorModalOpen] = useState(false);

  const handleColorOpenModal = () => {
    setIsColorModalOpen(true);
  };

  const handleCloseColorModal = () => {
    setIsColorModalOpen(false);
  };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Function to handle drag and drop
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSections(items);
    setHasOrderChanges(true);
  };
  const [showCircularProgressBar, setShowCircularProgressBar] = useState(false);

  const handleCircularProgressBarToggle = (event) => {
    setShowCircularProgressBar(event.target.checked);
    setHasAdvancedChanges(true);
  };

  const [preparationTime, setPreparationTime] = useState("");

  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [timerType, setTimerType] = useState("");
  const [progressPosition, setProgressPosition] = useState("");
  const [upNextPosition, setUpNextPosition] = useState("");
  const [restTime, setRestTime] = useState("");
  const [skipLastRest, setShowSkipLastReset] = useState(false);
  const [skipFirstRest, setShowSkipFirstReset] = useState(false);
  const [showUpNext, setShowUpNext] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTitle, setEditingTitle] = useState(""); // separate state for editing

  const handleFileBase64 = (base64) => {
    setLogoFile(base64);
    setHasAdvancedChanges(true);
  };
  const handleShowLogoToggle = (event) => {
    setShowLogo(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleShowUpNextToggle = (event) => {
    setShowUpNext(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleShowSkipLastResetToggle = (event) => {
    setShowSkipLastReset(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleShowSkipFirstResetToggle = (event) => {
    setShowSkipFirstReset(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleTimerTypeChange = (event) => {
    setTimerType(event.target.value);
    setHasAdvancedChanges(true);
  };
  const handleProgressPositionChange = (event) => {
    setProgressPosition(event.target.value);
    setHasAdvancedChanges(true);
  };
  // const handlePreparationTimeChange = (event) => {
  //   setPreparationTime(event.target.value);
  //   setHasAdvancedChanges(true);
  // };
  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
    setHasAdvancedChanges(true);
  };
  const handleSubHeadingChange = (event) => {
    setSubHeading(event.target.value);
    setHasAdvancedChanges(true);
  };
  const handleRestTimeChange = (event) => {
    const value = event.target.value;

    // Allow empty value to let the user clear the field
    if (value === "") {
      setRestTime("");
    } else {
      // Prevent negative values
      setRestTime(Math.max(0, Number(value)));
    }
    setHasAdvancedChanges(true);
  };
  const handleUpNextPositionChange = (event) => {
    setUpNextPosition(event.target.value);
    setHasAdvancedChanges(true);
  };
  const AcuteIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray600}
      style={{ marginRight: 10 }}
    >
      <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
    </svg>
  );

  const [openDialog, setOpenDialog] = useState(false);
  const ShowDialog = () => {
    setOpenDialog(true);
  };
  const handleCancelDialog = () => {
    setOpenDialog(false);
  };
  const handleDelete = async () => {
    setIsLoading(true);

    const response = await makeApiCall(
      routes.section.delete(sectionData.id),
      "DELETE"
    );
    if (response?.status) {
      // Remove user from localStorage
      setIsLoading(false);

      // Optionally navigate to the homepage or login page
      navigate(`/workout?id=${sectionData.workout.id}`);
    }
    // Close the dialog
    setOpenDialog(false);
  };

  const handleSaveOrder = async () => {
    setIsLoading(true);
    try {
      const indexOrder = sections.map((section, index) => ({
        id: section.id,
        index: index,
      }));

      // First API payload for reordering
      const reorderPayload = {
        parentId: id,
        indexOrder: indexOrder,
      };

      // Call the first API to reorder if there are order changes
      if (hasOrderChanges) {
        const reorderResponse = await makeApiCall(
          routes.exercise.reorder, // Update this route to match your API
          "PUT",
          reorderPayload,
          "show"
        );

        if (reorderResponse.status) {
          setInitialSections(sections); // Update state only if reorder was successful
        }
      }

      // Second API payload for advanced changes
      if (hasAdvancedChanges) {
        const advancedPayload = {
          title: editingTitle,
          circularProgress: showCircularProgressBar,
          timerType: timerType,
          progressPosition: progressPosition,
          showLogo: showLogo,
          logo: logoFile,
          skipLastRest: skipLastRest,
          skipFirstRest: skipFirstRest,
          restTitle: heading,
          restBody: subHeading,
          restTimeSeconds: parseInt(restTime, 10),
          showUpNext: showUpNext,
          upNextPosition: upNextPosition,
          progressColor: colorSettings.progressColor,
          timerTextColor: colorSettings.timerTextColor,
          timerBgColor: colorSettings.timerBgColor,
          exerciseNameTextBgColor: colorSettings.exerciseNameTextBgColor,
          exerciseNameTextColor: colorSettings.exerciseNameTextColor,
          setCounterTextBgColor: colorSettings.setCounterTextBgColor,
          setCounterTextColor: colorSettings.setCounterTextColor,
          restViewBgColor: colorSettings.restViewBgColor,
          restViewTextColor: colorSettings.restViewTextColor,
        };

        // Call the second API for advanced changes
        const advancedResponse = await makeApiCall(
          routes.section.update_work(id), // Ensure this route matches your API
          "PUT",
          advancedPayload,
          "show"
        );
        if (advancedResponse.status) {
          setSectionData((prev) => ({
            ...prev,
            title: editingTitle,
          }));
        }
      }

      // Update state only if there were no errors and changes were made
      if (hasOrderChanges || hasAdvancedChanges) {
        setHasOrderChanges(false);
        setHasAdvancedChanges(false);
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error saving exercise order:", error);
    }
    setIsLoading(false);
  };

  const handleCancelOrder = () => {
    setIsEditing(false);
    setEditingTitle(sectionData?.title || "");
    setSections(initialSections);
    setHasOrderChanges(false);
    setHasAdvancedChanges(false);
    setShowCircularProgressBar(initialData.circularProgress);
    setPreparationTime(initialData.preparationTimeSeconds || "");
    setHeading(initialData.title);
    setSubHeading(initialData.restBody);
    setTimerType(initialData.timerType);
    setProgressPosition(initialData.progressPosition);
    setUpNextPosition(initialData.upNextPosition);
    setRestTime(initialData.restTimeSeconds || "");
    setShowSkipLastReset(initialData.skipLastRest);
    setShowSkipFirstReset(initialData.skipFirstRest);
    setShowUpNext(initialData.showUpNext);
    setShowLogo(initialData.showLogo);
    setLogoFile(initialData.logoCdnUrl || null);
  };
  if (isSkeletonLoader) {
    return <SectionPageSkeleton />;
  }
  if (sectionData?.template === "present") {
    return (
      <PresentTemplateSectionPage
        data={sectionData}
        colorSettings={colorSettings}
      />
    ); // Render the PresentTemplateSectionPage component if template is "present"
  }

  const handleSaveColors = (colors) => {
    setColorSettings(colors);
    setHasAdvancedChanges(true);
  };
  const handleStartEdit = () => {
    setIsEditing(true);
    setEditingTitle(sectionData?.title || ""); // initialize edit value
    setHasAdvancedChanges(true);
  };

  // await makeApiCall(routes.section.update_work(id), "PUT", payload);
  return (
    <Box sx={{ mt: 8, mx: "8%", mb: 12 }}>
      <LoadingBackdrop isLoading={isLoading} />
      {/* Header and Save/Cancel buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href="/dashboard"
          >
            Home
          </Link>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href={`/workout?id=${sectionData?.workout?.id}`}
          >
            <TruncateText text={sectionData?.workout?.title} maxChars={15} />
          </Link>
          <Typography color="text.secondary">{sectionData?.title}</Typography>
        </Breadcrumbs>
        {/* {(hasOrderChanges || hasAdvancedChanges) && ( */}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 0,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 3,
                textTransform: "none",
                background: colors.gray200,
                color: colors.gray800,
              }}
              onClick={handleCancelOrder}
              disabled={!(hasOrderChanges || hasAdvancedChanges)}
              endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
              sx={{
                textTransform: "none",
                background: colors.green100,
                color: colors.green900,
                px: 2.5,
              }}
              disabled={!(hasOrderChanges || hasAdvancedChanges)}
              onClick={handleSaveOrder}
            >
              Save
            </Button>
          </Box>
        </Box>
        {/* )} */}
      </Box>

      {/* Main Content */}
      <Grid container spacing={0}>
        <Grid item xs={12} mt={5} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Box sx={{ position: "relative", display: "flex" }}>
              <Typography
                sx={{
                  fontSize: fonts.heading1,
                  color: colors.gray800,
                  fontWeight: 600,
                }}
              >
                <EditableTitle
                  title={sectionData?.title || ""} // displayed when not editing
                  editValue={editingTitle} // separate value for editing
                  isEditing={isEditing}
                  onStartEdit={handleStartEdit}
                  onTitleChange={setEditingTitle}
                  // onKeyPress={handleKeyPress}
                />
                {/* {sectionData?.title} */}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2} sm={4}>
          <Box>
            <Typography
              fontSize={fonts.heading3}
              color={colors.gray800}
              fontWeight={600}
              textAlign={"right"}
              sx={{ mb: 2, mt: 3 }}
              onClick={ShowDialog}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={colors.red900}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  padding: "10px",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = colors.red100)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
              </svg>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, mt: -1, borderColor: "#9388D3", mb: 6 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab
            label="Exercises"
            sx={{
              color: activeTab === 0 ? colors.teal400 : colors.gray800,
              fontWeight: 500,
              borderBottom: activeTab === 0 ? "2px solid" : "none",
              //   borderColor: colors.teal400,
            }}
          />
          <Tab
            label="Advanced"
            sx={{
              color: activeTab === 1 ? colors.teal400 : colors.gray800,
              borderBottom: activeTab === 1 ? "2px solid" : "none",
              //   borderColor: colors.teal400,
              fontWeight: 500,
            }}
          />
        </Tabs>
      </Box>
      {/* <Box mt={-4}>
        <DividerLine />
      </Box> */}
      {/* New Section Button */}
      {activeTab === 0 && (
        <>
          <Box
            sx={{
              border: "1px dashed ",
              borderRadius: "4px",
              borderColor: colors.teal600,
              py: 3,
              mb: 6,
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={handleOpenModal}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.teal600,
              }}
            >
              <AddOutlinedIcon />
              <Typography sx={{ ml: 1 }}>New Exercise</Typography>
            </Box>
            <ExerciseModal
              open={isModalOpen}
              onClose={handleCloseModal}
              sectionId={id}
            />
          </Box>
          {sections && (
            <DraggableSections
              sections={sections}
              handleDragEnd={handleDragEnd}
              isFromSection={true}
              onModalClose={() => {
                fetchData(true);
              }}
            />
          )}
        </>
      )}
      {activeTab === 1 && (
        <>
          <Typography
            fontSize={fonts.body}
            color={colors.gray800}
            textAlign={"left"}
            textTransform={"uppercase"}
          >
            Template: {sectionData.template}
          </Typography>

          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Theme
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: -1,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              {Object.entries({
                progressColor: colorSettings.progressColor,
                timerTextColor: colorSettings.timerTextColor,
                timerBgColor: colorSettings.timerBgColor,
                exerciseNameTextBgColor: colorSettings.exerciseNameTextBgColor,
                exerciseNameTextColor: colorSettings.exerciseNameTextColor,
                setCounterTextBgColor: colorSettings.setCounterTextBgColor,
                setCounterTextColor: colorSettings.setCounterTextColor,
                restViewBgColor: colorSettings.restViewBgColor,
                restViewTextColor: colorSettings.restViewTextColor,
              }).map(([key, value]) => (
                <Box
                  key={key}
                  onClick={handleColorOpenModal}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    border: "1px solid",
                    borderColor: colors.gray900,
                    backgroundColor: value || "#FFFFFF",
                    cursor: "pointer",
                  }}
                />
              ))}
            </Box>
            <Box>
              {" "}
              <Button
                onClick={handleColorOpenModal}
                variant="contained"
                sx={{
                  textTransform: "none",
                  mt: 2,
                  px: 2,
                  fontWeight: 400,
                  fontSize: fonts.heading4,
                  background: colors.teal400,
                  color: "#fff",
                  "&:hover": {
                    background: colors.teal600,
                  },
                }}
                endIcon={<ColorLensOutlinedIcon />}
              >
                Customize
              </Button>
            </Box>
          </Box>
          <ColorsModal
            open={isColorModalOpen}
            onClose={handleCloseColorModal}
            colorSettings={colorSettings}
            onSave={handleSaveColors}
          />
          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Progress
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show circular progress bar</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showCircularProgressBar}
                  onChange={handleCircularProgressBarToggle}
                  color="primary"
                />
              }
              sx={{ ml: 10 }}
              //   label="Show circular progress bar"
            />
          </Box>
          <Box sx={{ display: "flex", mb: 4 }}>
            <Typography
              mt={2}
              fontSize={fonts.heading4}
              color={colors.gray800}
              mr={2}
            >
              Timer Type
            </Typography>
            <FormControl sx={{ minWidth: 200, ml: 24 }}>
              <InputLabel>Type</InputLabel>
              <Select
                sx={{
                  textAlign: "left",
                }}
                variant="outlined"
                label="Type"
                onChange={handleTimerTypeChange}
                value={timerType}
                defaultValue=""
              >
                <MenuItem value="down">Count Down</MenuItem>
                <MenuItem value="up">Count Up</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", mb: 4 }}>
            <Typography
              mt={2}
              fontSize={fonts.heading4}
              color={colors.gray800}
              mr={2}
            >
              Progress Position
            </Typography>

            <FormControl sx={{ minWidth: 200, ml: 17.8 }}>
              <InputLabel>Filter</InputLabel>
              <Select
                sx={{
                  textAlign: "left",
                }}
                variant="outlined"
                label="Type"
                defaultValue=""
                onChange={handleProgressPositionChange}
                value={progressPosition}
              >
                {showCircularProgressBar
                  ? [
                      <MenuItem key="leftCenter" value="leftCenter">
                        Left Center
                      </MenuItem>,
                      <MenuItem key="rightCenter" value="rightCenter">
                        Right Center
                      </MenuItem>,
                      <MenuItem key="rightTop" value="rightTop">
                        Right Top
                      </MenuItem>,
                      <MenuItem key="leftTop" value="leftTop">
                        Left Top
                      </MenuItem>,
                      <MenuItem key="leftBottom" value="leftBottom">
                        Left Bottom
                      </MenuItem>,
                      <MenuItem key="rightBottom" value="rightBottom">
                        Right Bottom
                      </MenuItem>,
                    ]
                  : [
                      <MenuItem key="top" value="top">
                        Top
                      </MenuItem>,
                      <MenuItem key="bottom" value="bottom">
                        Bottom
                      </MenuItem>,
                    ]}
              </Select>
            </FormControl>
          </Box>

          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Rest Time
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Skip Last Rest Time</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={skipLastRest}
                  onChange={handleShowSkipLastResetToggle}
                  color="primary"
                />
              }
              sx={{ ml: 16.7 }}
              //   label="Show circular progress bar"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Skip First Rest Time</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={skipFirstRest}
                  onChange={handleShowSkipFirstResetToggle}
                  color="primary"
                />
              }
              sx={{
                ml: 16.5,
              }}
              //   label="Show circular progress bar"
            />
          </Box>
          <TextField
            label="Heading"
            type="text"
            value={heading}
            onChange={handleHeadingChange}
            sx={{
              mt: 5,
              width: {
                lg: "50%",
                md: "60%",
              },
              display: "flex",
              justifyContent: "start",
            }}
          />
          <TextField
            label="Sub-Heading"
            type="text"
            value={subHeading}
            onChange={handleSubHeadingChange}
            sx={{
              mt: 5,
              width: {
                lg: "50%",
                md: "60%",
              },
              display: "flex",
              justifyContent: "start",
            }}
          />
          <TextField
            label="Rest Time (seconds)"
            type="number"
            id="num"
            value={restTime}
            slotProps={{
              input: {
                // Adjust margin if needed
                startAdornment: <AcuteIcon />,
              },
            }}
            onWheel={(e) => e.target.blur()}
            onChange={handleRestTimeChange}
            sx={{
              mt: 5,
              width: {
                lg: "50%",
                md: "60%",
              },
              display: "flex",
              justifyContent: "start",
            }}
            helperText="This defines the time in-between each exercise"
          />
          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Up Next
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show Up Next</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showUpNext}
                  onChange={handleShowUpNextToggle}
                  color="primary"
                />
              }
              sx={{ ml: 21.8 }}
              //   label="Show circular progress bar"
            />
          </Box>
          <Box sx={{ display: "flex", mb: 4 }}>
            <Typography
              mt={2}
              fontSize={fonts.heading4}
              color={colors.gray800}
              mr={2}
            >
              Up Next Position
            </Typography>
            <FormControl sx={{ minWidth: 200, ml: 18.7 }}>
              <InputLabel>Filter</InputLabel>
              <Select
                sx={{
                  textAlign: "left",
                }}
                value={upNextPosition}
                onChange={handleUpNextPositionChange}
                variant="outlined"
                label="Type"
                defaultValue=""
              >
                <MenuItem value="leftTop">Left Top</MenuItem>
                <MenuItem value="rightTop">Right Top</MenuItem>
                <MenuItem value="leftBottom">Left Bottom</MenuItem>
                <MenuItem value="rightBottom">Right Bottom</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Logo
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show Logo</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showLogo}
                  onChange={handleShowLogoToggle}
                  color="primary"
                />
              }
              sx={{ ml: 24.8 }}
              //   label="Show circular progress bar"
            />
          </Box>
          {showLogo && (
            <FileUploader onFileChange={handleFileBase64} value={logoFile} />
          )}
        </>
      )}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDialog}
        onConfirm={handleDelete}
        title="Delete"
        content="Are you sure you want to delete this section? All its exercises will be deleted along with it"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
    </Box>
  );
};

export default SectionPage;
