import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";

const SupportPageSkeleton = ({ activeTab }) => {
  return (
    <Box>
      {/* Suggestions Tab Content */}
      {activeTab === 0 && (
        <>
          {/* Accordion Items */}

          <Box sx={{ mb: 3 }}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Box>

          {/* Table Rows */}
          {[1, 2, 3, 4].map((item) => (
            <Box key={item} sx={{ mb: 2 }}>
              <Skeleton variant="rectangular" width="100%" height={52} />
            </Box>
          ))}

          {/* Pagination */}
          <Box
            sx={{ display: "flex", justifyContent: "center", mt: 14, mb: -5 }}
          >
            <Skeleton
              variant="rectangular"
              width={300}
              height={40}
              sx={{ borderRadius: 1 }}
            />
          </Box>
        </>
      )}

      {/* Issues Tab Content */}
      {activeTab === 1 && (
        <Box sx={{ width: "100%", mt: 2 }}>
          {/* Table Header */}
          <Box sx={{ mb: 3 }}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Box>

          {/* Table Rows */}
          {[1, 2, 3, 4].map((item) => (
            <Box key={item} sx={{ mb: 2 }}>
              <Skeleton variant="rectangular" width="100%" height={52} />
            </Box>
          ))}

          {/* Pagination */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
            <Skeleton
              variant="rectangular"
              width={300}
              height={40}
              sx={{ borderRadius: 1 }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SupportPageSkeleton;
