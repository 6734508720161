import "../App.css";
import React, { useEffect } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import AuthSignPage from "../pages/auth/AuthPage";
import HomePage from "../pages/home/HomePage";
import AccountSettingsPage from "../pages/account/AccountSettings";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import WorkoutPage from "../pages/workout/Workout";
import SectionPage from "../pages/section/Section";
import PresentTemplateSectionPage from "../pages/section/PresentTemplateSection";
import SupportSuggestionPage from "../pages/support-suggestion/mainPage";
import NewTicket from "../pages/support-suggestion/NewTicket";
import NewSuggestion from "../pages/support-suggestion/NewSuggestion";
import TicketDetails from "../pages/support-suggestion/TicketDetails";
import NotFoundPage from "../pages/error_page/404-page";
import WelcomeStudio from "../pages/home/HomeEmptyPage";
import ServerErrorPage from "../pages/error_page/5xx-page";

function Layout() {
  const location = useLocation();
  const storedUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const noNavbarRoutes = ["/", "/register", "/forgot-password", "/login"];
  const noFooterRoutes = ["/", "/register", "/forgot-password", "/login"];

  if (storedUser && noNavbarRoutes.includes(location.pathname)) {
    return <Navigate to="/dashboard" replace />;
  }
  const ProtectedRoute = ({ element }) => {
    return storedUser ? element : <Navigate to="/login" replace />;
  };
  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {!noNavbarRoutes.includes(location.pathname) && <Navbar />}

      <div className="App-content" style={{ flex: 1 }}>
        <Routes location={location} key={location.pathname}>
          {/* Public Routes */}
          <Route path="/register" element={<AuthSignPage />} />
          <Route path="/" element={<AuthSignPage />} />
          <Route path="/login" element={<AuthSignPage />} />
          <Route path="/forgot-password" element={<AuthSignPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/5xx" element={<ServerErrorPage />} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<HomePage />} />}
          />
          <Route
            path="/dashboard/empty"
            element={<ProtectedRoute element={<WelcomeStudio />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<AccountSettingsPage />} />}
          />
          <Route
            path="/workout"
            element={<ProtectedRoute element={<WorkoutPage />} />}
          />
          <Route
            path="/workout/section"
            element={<ProtectedRoute element={<SectionPage />} />}
          />
          <Route
            path="/support"
            element={<ProtectedRoute element={<SupportSuggestionPage />} />}
          />
          <Route
            path="/support/ticket/new"
            element={<ProtectedRoute element={<NewTicket />} />}
          />
          <Route
            path="/support/ticket"
            element={<ProtectedRoute element={<TicketDetails />} />}
          />
          <Route
            path="/support/suggestion/new"
            element={<ProtectedRoute element={<NewSuggestion />} />}
          />

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>

      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default Layout;

//old file
// import "../App.css";
// import React, { useEffect } from "react";
// import {
//   useLocation,
//   Routes,
//   Route,
//   Navigate,
//   useParams,
// } from "react-router-dom";
// import AuthSignPage from "../pages/auth/AuthPage";
// import HomePage from "../pages/home/HomePage";
// import AccountSettingsPage from "../pages/account/AccountSettings";
// import Navbar from "../components/navbar/Navbar";
// import Footer from "../components/footer/Footer";
// import WorkoutPage from "../pages/workout/Workout";
// import SectionPage from "../pages/section/Section";
// import PresentTemplateSectionPage from "../pages/section/PresentTemplateSection";
// import SupportSuggestionPage from "../pages/support-suggestion/mainPage";
// import NewTicket from "../pages/support-suggestion/NewTicket";
// import NewSuggestion from "../pages/support-suggestion/NewSuggestion";
// import TicketDetails from "../pages/support-suggestion/TicketDetails";
// import NotFoundPage from "../pages/error_page/404-page";
// import WelcomeStudio from "../pages/home/HomeEmptyPage";

// import ServerErrorPage from "../pages/error_page/5xx-page";
// function Layout() {
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);
//   const noNavbarRoutes = ["/", "/register", "/forgot-password", "/login"];
//   const noFooterRoutes = ["/", "/register", "/forgot-password", "/login"];
//   return (
//     <div className="App">
//       {" "}
//       {!noNavbarRoutes.includes(location.pathname) && <Navbar />}
//       <div className="App-content">
//         <Routes location={location} key={location.pathname}>
//           <Route path="/register" element={<AuthSignPage />} />
//           <Route path="/" element={<AuthSignPage />} />
//           <Route path="/login" element={<AuthSignPage />} />
//           <Route path="/dashboard" element={<HomePage />} />
//           <Route path="/dashboard/empty" element={<WelcomeStudio />} />
//           <Route path="/forgot-password" element={<AuthSignPage />} />
//           <Route path="/profile" element={<AccountSettingsPage />} />
//           <Route path="/workout" element={<WorkoutPage />} />
//           <Route path="/workout/section" element={<SectionPage />} />
//           <Route path="/support" element={<SupportSuggestionPage />} />
//           <Route path="/support/ticket/new" element={<NewTicket />} />
//           <Route path="/support/ticket" element={<TicketDetails />} />
//           <Route path="/support/suggestion/new" element={<NewSuggestion />} />
//           <Route path="*" element={<Navigate to="/404" replace />} />
//           <Route path="/404" element={<NotFoundPage />} />
//           <Route path="/5xx" element={<ServerErrorPage />} />
//         </Routes>
//       </div>
//       {!noFooterRoutes.includes(location.pathname) && <Footer />}
//     </div>
//   );
// }

// export default Layout;
