import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Drawer,
  Grid,
} from "@mui/material";

import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DividerLine from "./DividerLine";
import { Add } from "@mui/icons-material";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import DraggableSections from "../workout/DraggableSections";
import FileUploader from "./FileUploader";
import { useSearchParams } from "react-router-dom";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import LoadingBackdrop from "./loader";

const ReplyMessageModal = ({ open, onClose }) => {
  const [fileData, setFileData] = useState([]);
  const [description, setDescription] = useState(""); // Add description state
  const [isLoading, setIsLoading] = useState(false); // Add description state
  const handleFileChange = (filesBase64Array) => {
    // filesBase64Array will be an array of base64 strings
    setFileData(filesBase64Array);
  };
  const { makeApiCall } = useApiRequest();

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const handleCancel = () => {
    onClose();
    setDescription("");
    setFileData([]);
  };
  const handleSave = async () => {
    setIsLoading(true);
    const payload2 = {
      ticketId: id,
      messageText: description,
      messageMedia: fileData,
    };
    const response2 = await makeApiCall(
      routes.ticket.send_message,
      "POST",
      payload2
    );
    if (response2.status) {
      setDescription("");
      setIsLoading(false);

      onClose();
    }
  };
  return (
    <Drawer
      onClick={(event) => {
        event.stopPropagation();
      }}
      anchor="bottom"
      ModalProps={{
        keepMounted: true, // Ensures the modal stays mounted for better performance
      }}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: "16px 16px 0 0", padding: 3, height: "750px" },
      }}
    >
      <LoadingBackdrop isLoading={isLoading} />

      <Box sx={{ mx: "6.5%", mb: 0 }}>
        {/* Modal Heading */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: colors.gray800,
              fontSize: fonts.heading3,
              fontWeight: 600,
              mt: 3,
              mb: 2,
            }}
          >
            Send Message
          </Typography>
          {(description.trim() !== "" || fileData.length > 0) && ( // Updated condition
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mb: 0,
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mr: 3,
                  textTransform: "none",
                  background: colors.gray200,
                  color: colors.gray800,
                }}
                endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
                sx={{
                  textTransform: "none",
                  background: colors.green100,
                  color: colors.green900,
                  px: 2.5,
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            color: colors.gray800,
            fontSize: fonts.heading3,
            fontWeight: 600,
            mt: 3,
            mb: 2,
          }}
        >
          Details
        </Typography>
        <Box mt={-1}>
          <DividerLine />
        </Box>

        <TextField
          multiline
          rows={6}
          label="Description"
          type="text"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{
            mt: 5,
            width: "100%",
            display: "flex",
            justifyContent: "left",
            textAlign: "left",
            borderColor: colors.gray500,
          }}
        />
        <Box sx={{ position: "relative", display: "flex", mt: 5 }}>
          <Typography
            sx={{
              fontSize: fonts.heading2,
              color: colors.gray800,
              fontWeight: 600,
            }}
          >
            Screenshots & Images (optional)
          </Typography>
        </Box>
        <Box mt={-1}>
          <DividerLine />
        </Box>
        <FileUploader
          showPreviousUploads={false}
          onFileChange={handleFileChange}
          value={fileData}
        />
        <Box mt={-10}></Box>
      </Box>
    </Drawer>
  );
};
export default ReplyMessageModal;
