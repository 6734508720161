import React from "react";
import AuthParentComponent from "../../components/signup/AuthComponent";
import { Box } from "@mui/material";
import useWindowWidth from "../../components/windowSize/WindowWidth";
const AuthSignPage = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #004D40, #80CBC4 )",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          my: 8,

          width: isMobile ? "90%" : "80%",
        }}
      >
        <AuthParentComponent />
      </Box>
    </Box>
  );
};

export default AuthSignPage;
