import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";

import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import WestSharpIcon from "@mui/icons-material/WestSharp";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import Otp from "./Otp";
import NewPassword from "./NewPassword";
import useApiRequest from "../../api/api";
import { useSnackbar } from "../utils/SnackbarProvider";
import routes from "../../api/routes";
import LoadingBackdrop from "../utils/loader";

const ForgotPw = ({ handleBack, isFromRegister }) => {
  const [showOtp, setShowOtp] = useState(false); // State to toggle between ForgotPw and Otp components
  const [step, setStep] = useState(1); // State to manage steps (1 = ForgotPw, 2 = Otp, 3 = NewPassword)
  const [email, setEmail] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const { makeApiCall } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);

  const showSnackbar = useSnackbar(); // Use the global Snackbar
  // Handle Continue button click in Otp
  const handleContinueToNewPassword = (verificationToken) => {
    setVerificationToken(verificationToken); // Store the verification token in state
    setStep(3); // Move to the next step (NewPassword)
  };

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const handleSendCode = async (e) => {
    const payload = {
      email: email, // email coming from props
    };
    setIsLoading(true);
    const response = await makeApiCall(
      routes.password.sendCode,
      "POST",
      payload // Send the OTP as part of the request body
    );
    setIsLoading(false);

    if (response.status) {
      // You can add logic here to handle successful verification
      showSnackbar(response.message, "success");
      setStep(2); // Move to the Otp step
    } else {
      // Handle the case where OTP verification fails
      showSnackbar(response.message, "error");
      console.error("OTP verification failed:", response.message);
    }
  };
  return (
    <Box sx={{ width: isMobile ? "100%" : "100%" }}>
      <LoadingBackdrop isLoading={isLoading} />
      {step === 1 && (
        <>
          <Typography
            sx={{
              color: colors.gray600,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
              mt: 0,
              cursor: "pointer",
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontSize={fonts.body}
            gutterBottom
            onClick={handleBack}
          >
            <WestSharpIcon />{" "}
            <Typography sx={{ marginLeft: 1 }}>Back</Typography>{" "}
          </Typography>
          <Typography
            sx={{
              color: colors.gray900,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              textAlign: "left",
              mt: 5,
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontWeight="bold"
            fontSize={fonts.heading1}
            gutterBottom
          >
            Forgot Password?
          </Typography>
          <Typography
            sx={{
              ml: isMobile ? 1 : 0,
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              width: "280px",
              fontSize: fonts.heading2,
              fontWeight: 600,
              color: colors.gray900,
              mt: 7,
            }}
            // variant={isMobile ? "body2" : fonts.body}

            gutterBottom
          >
            Enter your Email
          </Typography>

          <TextField
            fullWidth
            label="Enter your Email"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ ml: isMobile ? 0.6 : 0, mt: 5 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />

          <Typography
            component="span"
            display={"flex"}
            justifyContent={"left"}
            sx={{
              fontSize: isMobile ? "11px" : fonts.info,
              ml: isMobile ? 2 : 0,
            }}
          >
            Enter the email linked to this account
          </Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={handleSendCode}
            sx={{
              mt: 9,
              py: 1,
              background: colors.teal400,
              height: isMobile ? "45px" : "60px",
              borderRadius: "4px",
              textTransform: "none",
              fontSize: isMobile ? 12 : fonts.heading4,
              letterSpacing: 1,
              fontWeight: 400,
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
              },
            }}
          >
            Continue
          </Button>
        </>
      )}
      {step === 2 && (
        <Otp
          handleBack={handleBack}
          handleContinue={handleContinueToNewPassword} // Continue from Otp to NewPassword
          email={email}
          fromForgotPW={true}
        />
      )}
      {step === 3 && (
        <NewPassword
          handleBack={handleBack}
          email={email}
          verificationToken={verificationToken}
        />
      )}{" "}
      {/* Render NewPassword component */}
    </Box>
  );
};

export default ForgotPw;
