import { React, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Breadcrumbs,
  Link,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import colors from "../../styles/colors";

import fonts from "../../styles/fontVars";
import DividerLine from "../../components/utils/DividerLine";
import { SubjectOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import LoadingBackdrop from "../../components/utils/loader";

const NewSuggestion = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { makeApiCall } = useApiRequest();

  const [timerType, setTimerType] = useState("account");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState(""); // Add description state
  const DomainIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray800}
    >
      <path d="M280-160v-441q0-33 24-56t57-23h439q33 0 56.5 23.5T880-600v320L680-80H360q-33 0-56.5-23.5T280-160ZM81-710q-6-33 13-59.5t52-32.5l434-77q33-6 59.5 13t32.5 52l10 54h-82l-7-40-433 77 40 226v279q-16-9-27.5-24T158-276L81-710Zm279 110v440h280v-160h160v-280H360Zm220 220Z" />
    </svg>
  );
  const handleNavigateSuggestions = async () => {
    setIsLoading(true);
    const payload = {
      domain: timerType,
      subject: subject,
      description: description,
    };
    const response = await makeApiCall(
      routes.ticket.suggestion.create,
      "POST",
      payload
    );
    if (response.status) {
      setIsLoading(false);

      navigate(`/support`);
    }
  };
  const handleTimerTypeChange = (event) => {
    setTimerType(event.target.value);
  };

  return (
    <Box sx={{ mt: 8, mx: "8%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href="/support"
          >
            Suggestions
          </Link>
          <Typography color="text.secondary">New Suggestion</Typography>
        </Breadcrumbs>
        <Box>
          <Button
            variant="contained"
            fontSize={fonts.heading4}
            size="medium"
            onClick={handleNavigateSuggestions}
            disabled={!timerType || !subject || !description}
            sx={{
              textTransform: "none",
              background: colors.teal400,
              fontWeight: 400,
              height: "40px",
              textAlign: "left",
              px: 2,
            }}
          >
            Submit Suggestion
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: "relative", display: "flex", mt: 5 }}>
        <Typography
          sx={{
            fontSize: fonts.heading2,
            color: colors.gray800,
            fontWeight: 600,
          }}
        >
          Details
        </Typography>
      </Box>
      <Box mt={-1}>
        <DividerLine />
      </Box>
      <Box display={"flex"}>
        <FormControl sx={{ width: "50%" }}>
          <InputLabel>Domain</InputLabel>
          <Select
            sx={{
              textAlign: "left",
            }}
            onChange={handleTimerTypeChange}
            value={timerType}
            label="Domain"
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <DomainIcon />
                  </InputAdornment>
                }
                label="Domain"
              />
            }
          >
            <MenuItem value="account">Account</MenuItem>
            <MenuItem value="workouts">Workouts</MenuItem>
            <MenuItem value="exercises">Exercises</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={5}>
        <TextField
          label="Subject"
          type="text"
          inputProps={{ maxLength: 80 }} // Limit to 100 characters
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          sx={{
            mt: 0,
            width: {
              lg: "70%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
          slotProps={{
            input: {
              // Adjust margin if needed
              startAdornment: (
                <SubjectOutlined sx={{ mr: 1, color: colors.gray800 }} />
              ),
            },
          }}
        />
      </Box>
      <TextField
        multiline
        rows={10}
        label="Description"
        variant="outlined"
        inputProps={{ maxLength: 250 }} // Limit to 100 characters
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{
          mb: 12,
          width: {
            sm: "100%", // 70% for small screens
            md: "100%", // 50% for medium screens and above
            lg: "70%", // 50% for medium screens and above
          },
          mt: 5,
          display: "flex",
          justifyContent: "left",
          textAlign: "left",
          borderColor: colors.gray500,
        }}
      />
    </Box>
  );
};

export default NewSuggestion;
