import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";

import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import apple from "../../assets/apple.svg";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import fonts from "../../styles/fontVars";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import Otp from "../forgotpw/Otp";
import ApiRequest from "../../api/api";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { useSnackbar } from "../utils/SnackbarProvider";
import { useGoogleLogin } from "@react-oauth/google";
import { useGoogleAuth } from "../utils/AuthServices";
import LoadingBackdrop from "../utils/loader";
const SignUpForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showOtp, setShowOtp] = useState(false);
  const showSnackbar = useSnackbar(); // Use the global Snackbar
  const { makeApiCall } = useApiRequest();
  const { handleGoogleLogin } = useGoogleAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleNavigateOtp = () => {
    setShowOtp(true); // Set the state to true to render the Otp component
  };
  const handleNavigateHome = () => {
    navigate("/dashboard");
  };
  const handleNavigateLogin = () => {
    navigate("/login");
  };
  const handleBack = () => {
    // Navigate back or perform other actions

    setShowOtp(false);
    // Example: navigate(-1); if using react-router
  };
  const handleContinue = (response) => {
    localStorage.setItem("user", JSON.stringify(response));

    navigate("/dashboard");
    // Example: navigate(-1); if using react-router
  };
  useEffect(() => {
    // Check if the state has 'verify' set to true
    if (location.state?.verify === true) {
      setShowOtp(true); // Show OTP if 'verify' is true in the state
    }
  }, [location.state]); // Runs when the location state changes
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    subscribeToNewsletter: false,
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.username) {
      showSnackbar("Username is required", "error");
      return;
    }
    if (!formData.email) {
      showSnackbar("Email is required", "error");
      return;
    }
    if (!formData.password) {
      showSnackbar("Password is required", "error");
      return;
    }
    if (formData.password.length < 8) {
      showSnackbar("Password must be at least 8 characters long", "error");
      return;
    }
    const formDataWithConfirmPassword = {
      ...formData,
      confirmPassword: formData.password,
    };
    setIsLoading(true);
    const response = await makeApiCall(
      routes.auth.register,
      "POST",
      formDataWithConfirmPassword
    );
    setIsLoading(false);

    setShowOtp(true); // Show OTP component after successful registration
  };

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  return (
    <Box sx={{ width: isMobile ? "100%" : "80%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      {!showOtp ? (
        <>
          <Typography
            sx={{
              color: colors.gray900,
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
            }}
            // variant={windowWidth <= 768 ? "h5" : "h4"}
            fontWeight="bold"
            mt={-4}
            fontSize={fonts.heading1}
            gutterBottom
            textAlign={"left"}
          >
            Create an Account
          </Typography>
          <Typography
            sx={{
              ml: isMobile ? 1 : 0,
              display: "flex",
              justifyContent: "left",
              mt: 4,
            }}
            variant={isMobile ? "body2" : fonts.body}
            gutterBottom
          >
            Already have an account?
            <Link
              sx={{ ml: 0.5, justifyContent: "left" }}
              href="/login"
              color={colors.teal700}
              underline={isMobile ? "none" : "always"}
            >
              {isMobile ? "Login Here" : "Log In"}
            </Link>
          </Typography>

          <TextField
            id="outlined-start-adornment"
            label="Username"
            name="username"
            variant="outlined"
            fullWidth
            value={formData.username}
            onChange={handleChange}
            margin="normal"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
            sx={{ mt: isMobile ? 1 : 5, ml: isMobile ? 0.6 : 0 }}
          />
          <TextField
            fullWidth
            label="Enter your Email"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ ml: isMobile ? 0.6 : 0 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
            autoComplete="email"
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            value={formData.password}
            onChange={handleChange}
            sx={{ ml: isMobile ? 0.6 : 0 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            autoComplete="current-password"
          />

          <FormControlLabel
            control={
              isMobile ? (
                <Box sx={{ width: 0, height: 0 }} />
              ) : (
                <Checkbox name="terms" />
              )
            }
            sx={{
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              fontSize: isMobile ? "18px" : "24px",
            }}
            label={
              <Typography
                component="span"
                sx={{
                  fontSize: isMobile ? "11px" : "16px",
                  ml: isMobile ? 2 : 0,
                }}
              >
                {isMobile
                  ? "By continuing you agree to the "
                  : "I agree to the "}
                <Link href="#" color="#00796B">
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
          <FormControlLabel
            control={
              isMobile ? (
                <Box sx={{ width: 0, height: 0 }} />
              ) : (
                <Checkbox
                  name="subscribeToNewsletter"
                  checked={formData.subscribeToNewsletter}
                  onChange={handleChange}
                />
              )
            }
            sx={{
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              fontSize: isMobile ? "18px" : "24px",
            }}
            label={
              <Typography
                component="span"
                sx={{
                  fontSize: isMobile ? "11px" : "16px",
                  ml: isMobile ? 2 : 0,
                }}
              >
                I agree to the receive occasional emails regarding news or
                updates
              </Typography>
            }
          />

          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 4,
              py: 1,
              background: "linear-gradient(to right, #74D0A9, #9388D3 )",
              height: isMobile ? "45px" : "60px",
              borderRadius: "4px",
              textTransform: "none",
              fontSize: isMobile ? 12 : fonts.heading4,
              letterSpacing: 1,
              fontWeight: 400,
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.4)", // Drop shadow effect
              },
            }}
            // onClick={handleNavigateOtp}
            onClick={handleSubmit}
          >
            {isMobile ? "Register" : "Create Account"}
          </Button>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",

              mt: isMobile ? 3 : 5,
              mb: 2,
              "&::before, &::after": {
                content: '""',
                flex: 1,
                borderBottom: "1px solid #BDBDBD",
              },
              "&::before": {
                marginRight: 2,
              },
              "&::after": {
                marginLeft: 2,
              },
            }}
          >
            <Typography variant="body2" color={colors.gray800}>
              or register with
            </Typography>
          </Box>

          <Box
            sx={{
              display: isMobile ? "inline" : "flex",
              justifyContent: "center",
              gap: 4,
              mt: 5,
            }}
          >
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "5px",
                width: isMobile ? "100%" : "100%",
                padding: 2,
                transition: "box-shadow 0.3s ease",
              }}
              onClick={handleGoogleLogin} // This triggers Google login
            >
              <img
                src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/google.png"
                alt="google"
              />
            </IconButton>
            {/* <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "5px",
                width: isMobile ? "100%" : "25%",
                padding: 2,
                mt: isMobile ? 3 : 0,
                transition: "box-shadow 0.3s ease",
              }}
              onClick={handleNavigateHome}
            >
              <img
                src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/facebook.png"
                alt="facebook"
              />
            </IconButton>
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: "5px",
                width: isMobile ? "100%" : "25%",
                mt: isMobile ? 3 : 0,
                padding: 2,
                mb: isMobile ? 2 : 0,

                transition: "box-shadow 0.3s ease",
              }}
              onClick={handleNavigateHome}
            >
              <img
                src="https://d3vdd33llamc8d.cloudfront.net/assets/icons/apple.png"
                alt="apple"
              />
            </IconButton> */}
          </Box>
        </>
      ) : (
        <Otp
          handleBack={handleBack}
          handleContinue={handleContinue}
          isFromRegister={true}
          email={formData.email || location?.state?.email}
        />
      )}
    </Box>
  );
};

export default SignUpForm;
