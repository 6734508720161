import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useSnackbar } from "../components/utils/SnackbarProvider";

const useApiRequest = () => {
  const navigate = useNavigate();
  const showSnackbar = useSnackbar(); // Use the global Snackbar
  const location = useLocation(); // Get the current location (route)
  const storedUser = JSON.parse(localStorage.getItem("user"));

  const makeApiCall = useCallback(
    async (endpoint, method, body = null, showToast = "hide", headers = {}) => {
      const refreshAccessToken = async () => {
        try {
          const refreshResponse = await fetch(
            `${process.env.REACT_APP_BASE_URL}/user/account/refresh`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${storedUser?.refreshToken}`,
              },
            }
          );

          if (!refreshResponse.ok) {
            throw new Error(`Error: ${refreshResponse.status}`);
          }

          const refreshData = await refreshResponse.json();
          return refreshData.newAccessToken;
        } catch (error) {
          console.error("Token refresh failed:", error);
          return null;
        }
      };

      // Prepare request options
      const options = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
          Authorization: storedUser?.token
            ? `Bearer ${storedUser?.token}`
            : undefined,
        },
        body: body ? JSON.stringify(body) : undefined,
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}${endpoint}`,
          options
        );
        if (response.status === 401) {
          const responseData = await response.json();
          if (responseData.refreshToken) {
            const newToken = await refreshAccessToken();
            if (newToken) {
              return await makeApiCall(endpoint, method, body, headers);
            }
          }
          if (location.pathname !== "/login" && location.pathname !== "/") {
            navigate("/");
            localStorage.removeItem("user");
          }

          return responseData; // Return the response data if needed
        }
        if (response.status === 404) {
          navigate("/404");
        }
        if (response.status === 500) {
          navigate("/5xx");
        }
        const responseData = await response.json();
        if (!response.ok) {
          if (responseData.message.toLower === "load failed") {
            showSnackbar(
              "Please check your internet connection or try again later",
              "error"
            );
          } else {
            showSnackbar(responseData.message, "error");
          }
        } else {
          if (showToast === "show") {
            showSnackbar(responseData.message, "success"); // Show success snackbar
          }
        }

        return responseData;
      } catch (error) {
        if (error.message.toLower === "load failed") {
          showSnackbar(
            "Please check your internet connection or try again later",
            "error"
          );
        } else {
          showSnackbar(error.message, "error");
        }
      }
    },
    [navigate]
  );

  return { makeApiCall };
};

export default useApiRequest;
