import React from "react";
import { Typography, TextField, Box } from "@mui/material";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import TruncateText from "./TruncateText";

const EditableTitle = ({
  title,
  isEditing,
  onStartEdit,
  onTitleChange,
  onKeyPress,
  editValue,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isEditing ? (
        <TextField
          value={editValue}
          inputProps={{ maxLength: 100 }} // Limit to 100 characters
          onChange={(e) => onTitleChange(e.target.value)}
          onKeyDown={onKeyPress}
          autoFocus
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              fontSize: fonts.heading1,
              fontWeight: 600,
              color: colors.gray800,
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: colors.gray300,
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: colors.teal600,
            },
          }}
        />
      ) : (
        <Typography
          onClick={onStartEdit}
          sx={{
            fontSize: fonts.heading1,
            color: colors.gray800,
            fontWeight: 600,
          }}
        >
          <TruncateText text={title} maxChars={15} />
        </Typography>
      )}
    </Box>
  );
};

export default EditableTitle;
