import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import fonts from "../../styles/fontVars";
import colors from "../../styles/colors";
import { useNavigate } from "react-router-dom";

const AddWorkoutModal = ({ open, onClose, onSave }) => {
  const navigate = useNavigate();
  const [workoutName, setWorkOutName] = useState("");

  const handleCreate = () => {
    onSave(workoutName);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <Typography
          fontSize={fonts.heading4}
          mt={1}
          color={colors.gray800}
          fontWeight={500}
          textAlign={"left"}
        >
          New Workout
        </Typography>
        <TextField
          label="Name"
          type="text"
          value={workoutName}
          autoFocus
          inputProps={{ maxLength: 100 }} // Limit to 100 characters
          onChange={(e) => setWorkOutName(e.target.value)}
          sx={{
            mt: 3,
            width: {
              lg: "100%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
        />
      </DialogContent>
      <DialogActions sx={{ mb: 1, mt: -2, mr: 2 }}>
        <Button onClick={onClose} sx={{ color: colors.gray500 }}>
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddWorkoutModal;
