import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  cancelButtonText,
  confirmButtonText,
  cancelButtonProps,
  confirmButtonProps,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      fullWidth
    >
      {title && (
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      )}
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {cancelButtonText && (
          <Button onClick={onClose} {...cancelButtonProps}>
            {cancelButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button onClick={onConfirm} {...confirmButtonProps}>
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
