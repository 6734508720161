// TicketDetailsSkeleton.js
import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Skeleton,
  Divider,
  IconButton,
} from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import DividerLine from "../../../components/utils/DividerLine";
const TicketDetailsSkeleton = () => {
  return (
    <Box sx={{ mt: 8, mx: "8%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Skeleton width="30%" height={30} />
      </Box>

      <Box sx={{ position: "relative", display: "flex", mt: 5 }}>
        <Skeleton variant="text" width="20%" height={40} />
      </Box>

      <Box mt={-1}>
        <DividerLine />
      </Box>

      <Paper elevation={0} sx={{ mt: 3, mb: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={2} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Submitted
            </Typography>
            <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          </Grid>
          <Grid item lg={2} sm={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Closed
            </Typography>
            <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          </Grid>
          <Grid item xs={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Closed By
            </Typography>
            <Skeleton width="80%" height={30} sx={{ mt: 2 }} />
          </Grid>
        </Grid>
      </Paper>

      <Typography textAlign={"left"} variant="subtitle2" color="text.secondary">
        Domain
      </Typography>
      <Skeleton variant="rectangular" height={30} width="70%" sx={{ mt: 1 }} />

      <Typography
        mt={5}
        textAlign={"left"}
        variant="subtitle2"
        color="text.secondary"
      >
        Subject
      </Typography>
      <Skeleton variant="rectangular" height={30} width="70%" sx={{ mt: 1 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 10,
        }}
      >
        <Skeleton variant="text" width="15%" height={40} />
        <Skeleton variant="rectangular" width={100} height={36} />
      </Box>

      <Box mt={-1}>
        <DividerLine />
      </Box>

      <Box sx={{ mb: 15 }}>
        {Array.from(new Array(3)).map((_, index) => (
          <Paper
            key={index}
            elevation={0}
            sx={{
              p: 2,
              mt: 5,
              mb: 3,
              border: "1px solid",
              borderColor: "#e0e0e0",
            }}
          >
            <Skeleton variant="text" width="50%" height={20} />
            <Skeleton variant="text" width="100%" height={20} sx={{ mt: 1 }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                mt: 4,
                flexWrap: "wrap",
              }}
            >
              {Array.from(new Array(2)).map((_, imgIndex) => (
                <Box
                  key={imgIndex}
                  sx={{
                    position: "relative",
                    width: 100,
                    height: 100,
                    borderRadius: "4px",
                    overflow: "hidden",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      opacity: 0.5,
                    }}
                  >
                    <OpenInNewOutlinedIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default TicketDetailsSkeleton;
