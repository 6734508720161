import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Breadcrumbs,
  Link,
  Alert,
  TextField,
  Grid,
} from "@mui/material";
import colors from "../../styles/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DividerLine from "../../components/utils/DividerLine";
import DraggableSections from "../../components/workout/DraggableSections";
import NewSectionModal from "../../components/workout/AddSectionModal";
import fonts from "../../styles/fontVars";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import LoadingBackdrop from "../../components/utils/loader";
import EditableTitle from "../../components/utils/EditableTitle";
import WorkoutSkeleton from "./WorkoutSkeleton";
import ConfirmationDialog from "../../components/utils/Dialog";
import TruncateText from "../../components/utils/TruncateText";
import { formatTotalTime } from "../../components/utils/DateFormatter";

const WorkoutPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Extract the 'id' parameter
  const [isLoading, setIsLoading] = useState(false);
  const [isOverLoading, setIsOverlayLoading] = useState(false);

  const handleNavigateView = () => {
    if (id) {
      window.location.href = `https://main.dnxkce1wa4yhv.amplifyapp.com?id=${id}`;
    } else {
      window.location.href = "/404"; // Redirect to the 404 page
    }
  };
  const [sections, setSections] = useState([]);
  const [initialSections, setInitialSections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workoutData, setWorkoutData] = useState("");
  const [pendingChanges, setPendingChanges] = useState({
    title: false,
    order: false,
  });
  const { makeApiCall } = useApiRequest();
  const fetchData = async () => {
    setIsLoading(true);

    const response = await makeApiCall(routes.workout.get(id), "GET");
    setWorkoutData(response.data.workout);
    setSections(response.data.workout.sections); // Set the sections array here
    setInitialSections(response.data.workout.sections);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData(); // Call the async function immediately
  }, [id]); // Add 'id' as a dependency
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const ShowDialog = () => {
    setOpenDialog(true);
  };
  const handleCancelDialog = () => {
    setOpenDialog(false);
  };
  const handleDelete = async () => {
    setIsLoading(true);

    const response = await makeApiCall(routes.workout.delete(id), "DELETE");
    if (response?.status) {
      // Remove user from localStorage
      setIsLoading(false);

      // Optionally navigate to the homepage or login page
      navigate("/dashboard");
    }
    // Close the dialog
    setOpenDialog(false);
  };
  // Function to handle drag and drop
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSections(items);
    setPendingChanges((prev) => ({ ...prev, order: true }));
  };
  const [isEditing, setIsEditing] = useState(false);
  const [editingTitle, setEditingTitle] = useState(""); // separate state for editing

  const handleStartEdit = () => {
    setIsEditing(true);
    setEditingTitle(workoutData?.title || ""); // initialize edit value
    setPendingChanges((prev) => ({ ...prev, title: true }));
  };

  const handleSaveAll = async () => {
    setIsOverlayLoading(true);
    try {
      // Save title if changed
      if (pendingChanges.title && editingTitle) {
        const titlePayload = {
          title: editingTitle,
          isFavourite: workoutData.isFavourite,
        };
        const titleResponse = await makeApiCall(
          routes.workout.update(id),
          "PUT",
          titlePayload
        );
        if (titleResponse.status) {
          setWorkoutData((prev) => ({
            ...prev,
            title: editingTitle,
          }));
        }
      }

      // Save order if changed
      if (pendingChanges.order) {
        const indexOrder = sections.map((section, index) => ({
          id: section.id,
          index: index,
        }));

        const orderPayload = {
          parentId: id,
          indexOrder: indexOrder,
        };

        const orderResponse = await makeApiCall(
          routes.section.reorder,
          "PUT",
          orderPayload
        );

        if (orderResponse.status) {
          setInitialSections(sections);
        }
      }

      // Reset all states after successful save
      setIsEditing(false);
      setPendingChanges({ title: false, order: false });
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    setIsOverlayLoading(false);
  };

  const handleCancelAll = () => {
    // Reset all changes
    if (pendingChanges.title) {
      setIsEditing(false);
      setEditingTitle(workoutData?.title || "");
    }
    if (pendingChanges.order) {
      setSections(initialSections);
    }
    setPendingChanges({ title: false, order: false });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSaveAll();
    } else if (event.key === "Escape") {
      handleCancelAll();
    }
  };
  if (isLoading) {
    return <WorkoutSkeleton />;
  }
  return (
    <Box sx={{ mt: 8, mx: "8%", mb: 17 }}>
      {/* Header and Save/Cancel buttons */}
      <LoadingBackdrop isLoading={isOverLoading} />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href="/dashboard"
          >
            Home
          </Link>
          <Typography color="text.secondary">
            <TruncateText text={workoutData?.title} maxChars={15} />
          </Typography>
        </Breadcrumbs>
        {(pendingChanges.title || pendingChanges.order) && (
          <>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mb: 0,
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    mr: 3,
                    textTransform: "none",
                    background: colors.gray200,
                    color: colors.gray800,
                  }}
                  onClick={handleCancelAll}
                  endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  endIcon={
                    <CheckOutlinedIcon sx={{ color: colors.green900 }} />
                  }
                  sx={{
                    textTransform: "none",
                    background: colors.green100,
                    color: colors.green900,
                    px: 2.5,
                  }}
                  onClick={handleSaveAll}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/* Main Content */}
      <Grid container spacing={0}>
        <Grid item xs={12} mt={5} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Box sx={{ position: "relative", display: "flex" }}>
              <EditableTitle
                title={workoutData?.title || ""} // displayed when not editing
                editValue={editingTitle} // separate value for editing
                isEditing={isEditing}
                onStartEdit={handleStartEdit}
                onTitleChange={setEditingTitle}
                onKeyPress={handleKeyPress}
              />
              <IconButton
                sx={{
                  backgroundColor: colors.teal50,
                  borderRadius: "50%",
                  height: "68px",
                  background:
                    "linear-gradient(270.34deg, #9388D3 0%, #74D0A9 100%)",
                  ml: 3,
                  mt: -2,
                  width: "68px",
                  transition: "all 0.3s ease", // Smooth transition for hover effect
                  "&:hover": {
                    background:
                      "linear-gradient(270.34deg, #7f72b9 0%, #5ba989 100%)", // Duller gradient colors
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Soft shadow on hover
                  },
                }}
                onClick={handleNavigateView}
              >
                <PlayArrowOutlinedIcon
                  sx={{
                    color: colors.gray50,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2} sm={4}>
          <Box>
            <Typography
              fontSize={fonts.heading3}
              color={colors.gray800}
              fontWeight={600}
              textAlign={"right"}
              sx={{ mb: 2, mt: 3 }}
              onClick={ShowDialog}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={colors.red900}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  padding: "10px",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = colors.red100)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
              </svg>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={-4} mb={6}>
        <DividerLine />
      </Box>

      <Alert
        severity="success"
        iconMapping={{
          success: (
            <InfoOutlinedIcon
              fontSize="inherit"
              sx={{
                color:
                  workoutData?.totalTimeSeconds > 0
                    ? colors.purple
                    : colors.gray600,
              }}
            />
          ),
        }}
        sx={{
          mb: 6,
          background: colors.gray50,
          color:
            workoutData?.totalTimeSeconds > 0 ? colors.purple : colors.gray600,
          border: "2px solid",
          borderColor:
            workoutData?.totalTimeSeconds > 0 ? colors.purple : colors.gray600,
          textAlign: "left",
        }}
      >
        {workoutData?.totalTimeSeconds > 0
          ? `Total workout time is ${formatTotalTime(
              workoutData?.totalTimeSeconds
            )}`
          : "Start adding sections and exercises to make your perfect workout"}
      </Alert>

      {/* New Section Button */}
      <Box
        sx={{
          border: "1px dashed ",
          borderRadius: "4px",
          borderColor: colors.teal600,
          py: 3,
          mb: 6,
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={handleOpenModal}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colors.teal600,
          }}
        >
          <AddOutlinedIcon />
          <Typography sx={{ ml: 1 }}>New Section</Typography>
        </Box>
        <NewSectionModal
          open={isModalOpen}
          onClose={handleCloseModal}
          workoutId={id}
        />
      </Box>
      {sections && (
        <DraggableSections sections={sections} handleDragEnd={handleDragEnd} />
      )}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDialog}
        onConfirm={handleDelete}
        title="Delete"
        content="Are you sure you want to delete this workout? All its sections and exercises will be deleted along with it"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
    </Box>
  );
};

export default WorkoutPage;
