// src/styles/colors.js

const colors = {
  gray800: "#424242",
  teal900: "#004D40",
  teal700: "#00796B",
  teal600: "#00897B",
  teal400: "#26A69A",
  teal200: "#80CBC4",
  teal100: "#B2DFDB",
  teal50: "#E0F2F1",
  primary: "#4CAF50",
  secondary: "#81C784",
  gray900: "#212121",
  gray500: "#9E9E9E",
  gray400: "#BDBDBD",
  gray600: "#757575",
  gray200: "#EEEEEE",
  gray50: "#FAFAFA",
  red100: "#FECDD2",
  red900: "#B71C1C",
  green900: "#1B5E20",
  green100: "#C8E6C9",
  green300: "#81C784",
  purple: "#9747FF",

  // Add more colors as needed
};

export default colors;
