import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

const HomePageSkeleton = () => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* Favourites Section skeleton */}
      <Box mt={5} mx="6.3%">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" height={20} sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          {[...Array(4)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="22%"
              height={200}
            />
          ))}
        </Box>
      </Box>

      {/* All Workouts Section skeleton */}
      <Box mt={5} mx="6.3%">
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="rectangular" height={20} sx={{ my: 2 }} />

        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" width="80%" height={56} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Skeleton variant="rectangular" width={200} height={56} />
          </Grid>
        </Grid>

        {/* Workout Cards skeleton */}
        <Grid container spacing={2}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <Skeleton variant="rectangular" height={300} sx={{ mb: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Skeleton variant="text" width={120} />
                  <Skeleton variant="text" width={80} />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{ mr: 1 }}
                  />
                  <Skeleton variant="circular" width={40} height={40} />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Skeleton variant="rectangular" width={300} height={40} />
      </Box>
    </Box>
  );
};

export default HomePageSkeleton;
