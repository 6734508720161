import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Tabs,
  Tab,
  InputAdornment,
  Accordion,
  AccordionSummary,
  Pagination,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";

import colors from "../../styles/colors";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import fonts from "../../styles/fontVars";
import { Add, Search } from "@mui/icons-material";
import BasicTable from "./table";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { formatDateToLongForm } from "../../components/utils/DateFormatter";
import SupportPageSkeleton from "./loaders/supportSkeleton";
import SuggestionsEmptyPage from "./empty_page/SuggestionsEmptyPage";
import TicketsEmptyPage from "./empty_page/TicketsEmptyPage";
const SupportSuggestionPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [type, setType] = useState("suggestion"); // Track type for data fetch
  const handleTabChange = (event, newValue) => {
    setIsLoading(true); // Hide skeleton when data is loaded
    setSearchText("");
    setType("relevance");
    setCurrentPage(1);
    setActiveTab(newValue);
    const newType = newValue === 1 ? "issue" : "suggestion";
    setType(newType); // Update type in state
    fetchData(1, "relevance", "", newType).finally(() => {
      setIsLoading(false); // Hide skeleton when data is loaded
    });
  };
  const [sortBy, setSortBy] = useState("relevance"); // Track the current page
  const [searchText, setSearchText] = useState(""); // State for search text
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [suggestionsData, setSuggestionsData] = useState([]); // Track the current page
  const [ticketsData, setTicketsData] = useState([]); // Track the current page
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const handleNavigateTicket = () => {
    navigate("/support/ticket/new");
  };
  const handleNavigateNewSuggest = () => {
    navigate("/support/suggestion/new");
  };
  const { makeApiCall } = useApiRequest();

  const fetchData = async (
    page = 1,
    sortBy = "relevance",
    searchText = "",
    type = "suggestion",
    showLoader = false
  ) => {
    if (showLoader) setIsLoading(true);
    const params = {
      pageNumber: page,
      sortBy: sortBy,
    };

    // Conditionally add searchText to params if it's not empty or null
    if (type) {
      params.type = type;
    }
    if (searchText) {
      params.searchText = searchText;
    }
    const response = await makeApiCall(
      routes.ticket.search(params), // Pass the params object here
      "GET"
    );
    if (response.status && response?.data?.tickets) {
      const tickets = response.data.tickets;

      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.pageNumber);
      setSuggestionsData(tickets);
      setTicketsData(tickets);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    if (tabParam === "issue") {
      setActiveTab(1);
      setType("issue");
      fetchData(1, "relevance", "", "issue", true);
    } else {
      setActiveTab(0);
      setType("suggestion");
      fetchData(1, "relevance", "", "suggestion", true);
    }
  }, []);

  const DomainIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray800}
    >
      <path d="M280-160v-441q0-33 24-56t57-23h439q33 0 56.5 23.5T880-600v320L680-80H360q-33 0-56.5-23.5T280-160ZM81-710q-6-33 13-59.5t52-32.5l434-77q33-6 59.5 13t32.5 52l10 54h-82l-7-40-433 77 40 226v279q-16-9-27.5-24T158-276L81-710Zm279 110v440h280v-160h160v-280H360Zm220 220Z" />
    </svg>
  );

  // Function to handle drag and drop

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleFilterChange = (selectedFilter) => {
    setSortBy(selectedFilter.target.value);
    fetchData(currentPage, selectedFilter.target.value, searchText, type);
    // Add any other logic you want to handle when the filter changes
  };
  const handlePageChange = (event, value) => {
    fetchData(value, sortBy, searchText, type);
    setCurrentPage(value); // Update the current page when the user changes pages
  };
  const handleSearch = () => {
    fetchData(currentPage, sortBy, searchText, type);
  };

  return (
    <>
      <Box sx={{ mt: 2, mx: "8%", mb: 12 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} mt={5} sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: fonts.heading1,
                    color: colors.gray800,
                    fontWeight: 600,
                  }}
                >
                  Suggestion & Support
                </Typography>
              </Box>
              <Box>
                {activeTab === 0 ? (
                  <Button
                    variant="contained"
                    fontSize={fonts.heading4}
                    endIcon={<Add />}
                    onClick={handleNavigateNewSuggest}
                    size="medium"
                    sx={{
                      textTransform: "none",
                      background: colors.teal400,
                      fontWeight: 400,
                      height: "40px",
                      textAlign: "left",
                      px: 2,
                    }}
                  >
                    New Suggestion
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fontSize={fonts.heading4}
                    size="medium"
                    endIcon={<Add />}
                    onClick={handleNavigateTicket}
                    sx={{
                      textTransform: "none",
                      background: colors.teal400,
                      fontWeight: 400,
                      height: "40px",
                      textAlign: "left",
                      px: 2,
                    }}
                  >
                    New Ticket
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ borderBottom: 1, mt: 1, borderColor: "#9388D3", mb: 6 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab
              label="Suggestions & FEEDBACK"
              sx={{
                color: activeTab === 0 ? colors.teal400 : colors.gray800,
                fontWeight: 500,
                borderBottom: activeTab === 0 ? "2px solid" : "none",
                //   borderColor: colors.teal400,
              }}
            />
            <Tab
              label="ISSUE TICKETS"
              sx={{
                color: activeTab === 1 ? colors.teal400 : colors.gray800,
                borderBottom: activeTab === 1 ? "2px solid" : "none",
                //   borderColor: colors.teal400,
                fontWeight: 500,
              }}
            />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <>
            {isLoading ? (
              <SupportPageSkeleton activeTab={activeTab} />
            ) : (
              <>
                {!suggestionsData || suggestionsData.length === 0 ? (
                  <SuggestionsEmptyPage />
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 0,
                      }}
                    >
                      <TextField
                        label="Search"
                        value={searchText} // Bind the input value to local state
                        onChange={(e) => setSearchText(e.target.value)} // Update local state on input change
                        sx={{
                          display: "flex",
                          width: {
                            lg: "35%",
                            sm: "60%",
                          },
                          mb: 7,
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleSearch}>
                                  <Search sx={{ cursor: "pointer" }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                    </Box>
                    {suggestionsData?.map((accordion) => (
                      <Accordion
                        key={accordion.id}
                        disableGutters
                        elevation={0}
                        expanded={expanded === accordion.id}
                        onChange={handleChange(accordion.id)}
                        sx={{
                          border: "none",
                          "&:before": {
                            display: "none",
                          },
                          mb: 2,
                          borderBottom:
                            expanded === accordion.id ? "none" : "1px solid",
                          borderColor: colors.gray400,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ArrowDropDownOutlinedIcon />}
                          sx={{
                            "& .MuiAccordionSummary-content": {
                              alignItems: "center",
                              justifyContent: "space-between",
                              ml: 5,
                            },
                          }}
                        >
                          <Typography>{accordion.subject}</Typography>
                          <Typography mr={4}>
                            {formatDateToLongForm(accordion.createdOn)}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails sx={{ mt: 1, mb: 5 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                              flexDirection: "column",
                              gap: 4,
                            }}
                          >
                            <Box>
                              <Typography
                                textAlign={"left"}
                                color={colors.gray600}
                                fontSize={fonts.info}
                                mb={2}
                              >
                                Domain
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <DomainIcon />
                                <Typography color={colors.gray800} ml={2}>
                                  {accordion.domain}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                textAlign={"left"}
                                color={colors.gray600}
                                fontSize={fonts.info}
                                mb={2}
                              >
                                Message
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  gap: 1,
                                }}
                              >
                                <MessageOutlinedIcon
                                  sx={{ color: colors.gray800 }}
                                />
                                <Typography
                                  textAlign={"left"}
                                  color={colors.gray800}
                                  ml={2}
                                >
                                  {accordion.description}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                )}
                {/* )} */}
              </>
            )}
            {totalPages > 1 && (
              <Box mt={14}>
                <Pagination
                  count={totalPages} // Use dynamic totalPages from state
                  page={currentPage} // Control the current page
                  onChange={handlePageChange} // Handle page changes
                  color="primary"
                  sx={{ mb: -5, justifyContent: "center", display: "flex" }}
                />
              </Box>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {isLoading ? (
              <SupportPageSkeleton activeTab={activeTab} />
            ) : (
              <>
                {!ticketsData || ticketsData.length === 0 ? (
                  <TicketsEmptyPage />
                ) : (
                  <>
                    {ticketsData?.length && (
                      <>
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <TextField
                            label="Search"
                            value={searchText} // Bind the input value to local state
                            onChange={(e) => setSearchText(e.target.value)} // Update local state on input change
                            sx={{
                              display: "flex",
                              width: {
                                lg: "35%",
                                sm: "60%",
                              },
                              mb: 7,
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSearch();
                              }
                            }}
                            slotProps={{
                              input: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleSearch}>
                                      <Search sx={{ cursor: "pointer" }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                          <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel>Filter</InputLabel>
                            <Select
                              sx={{
                                textAlign: "left",
                              }}
                              variant="outlined"
                              label="Filter"
                              defaultValue="relevance"
                              onChange={handleFilterChange} // Handle change event
                            >
                              <MenuItem value="relevance">Relevance</MenuItem>
                              <MenuItem value="lastActivity">
                                Last Activity
                              </MenuItem>
                              <MenuItem value="status">Status</MenuItem>
                              <MenuItem value="domain">Domain</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <BasicTable data={ticketsData} />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {totalPages > 1 && (
              <Box mt={8}>
                <Pagination
                  count={totalPages} // Use dynamic totalPages from state
                  page={currentPage} // Control the current page
                  onChange={handlePageChange}
                  color="primary"
                  sx={{ mb: -5, justifyContent: "center", display: "flex" }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default SupportSuggestionPage;
