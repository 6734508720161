import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
} from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Search } from "@mui/icons-material";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import LoadingBackdrop from "../utils/loader";
import { useNavigate } from "react-router-dom";
const ExerciseModal = ({ open, onClose, sectionId }) => {
  const [totalPages, setTotalPages] = useState(1);
  const { makeApiCall } = useApiRequest();
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [sortBy, setSortBy] = useState("relevance"); // Track the current page
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [accordionsData, setAccordionsData] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fetchWorkouts = async (
    page = 1,
    sortBy = "relevance",
    searchText = ""
  ) => {
    const params = {
      pageNumber: page,
      sortBy: sortBy,
    };

    // Conditionally add searchText to params if it's not empty or null
    if (searchText) {
      params.searchText = searchText;
    }
    const response = await makeApiCall(
      routes.exercise.library(params), // Pass the params object here
      "GET"
    );
    if (response.status && response?.data?.exercises) {
      const exercises = response.data.exercises;
      setAccordionsData(exercises);
      // Check if workouts array is empty
      // if (exercises.length === 0) {
      //   setShowWelcomeStudio(true); // Set state to show WelcomeStudio
      //   return; // Exit the function
      // } else {
      //   setShowWelcomeStudio(false); // Reset state if workouts are found
      // }
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.pageNumber);
      const updatedCardData = response.data.exercises.map((exercise) => ({
        id: exercise.id,
        title: exercise.title,
        // images: [
        //   "https://d3vdd33llamc8d.cloudfront.net/assets/empty/placeholder.png", // Placeholder image
        // ],
        // isFavourite: exercise.isFavourite,
        // editedLabel: exercise.updatedOn ? "Edited" : "Created",
        // edited: exercise.updatedOn
        //   ? formatDaysAgo(exercise.updatedOn)
        //   : formatDaysAgo(exercise.createdOn),
      }));

      // setAccordionsData(updatedCardData);
    }
  };
  useEffect(() => {
    let isMounted = true;

    const initializePage = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem("user"));

        if (isMounted) {
          await Promise.all([fetchWorkouts(currentPage, sortBy)]);
        }
      } catch (error) {
        console.error("Error initializing page:", error);
      }
    };

    initializePage();

    return () => {
      isMounted = false;
    };
  }, [currentPage, sortBy]);

  const handleSearch = () => {
    fetchWorkouts(currentPage, sortBy, searchText); // Reset to page 1 when searching
    // setCurrentPage(1);
  };

  const handleFilterChange = (event) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    // fetchWorkouts(1, newSortBy, searchText); // Reset to page 1 when filter changes
    // setCurrentPage(1);
  };
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleCheckboxChange = (checkboxId) => (event) => {
    if (selectedCheckboxes.includes(checkboxId)) {
      // If already selected, remove it from the array
      const updatedCheckboxes = selectedCheckboxes.filter(
        (id) => id !== checkboxId
      );
      setSelectedCheckboxes(updatedCheckboxes);
    } else {
      // If not selected, add it to the array
      const updatedCheckboxes = [...selectedCheckboxes, checkboxId];
      setSelectedCheckboxes(updatedCheckboxes);
    }
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page when the user changes pages
  };
  const handleAdd = async () => {
    const payload = {
      sectionId: sectionId,
      exerciseIds: selectedCheckboxes,
    };
    setIsLoading(true);
    const response = await makeApiCall(routes.exercise.add, "POST", payload);
    if (response?.status) {
      onClose();
      navigate(0);
    }
    setIsLoading(false);
  };

  return (
    <>
      <LoadingBackdrop isLoading={isLoading} />

      <Drawer
        onClick={(event) => {
          event.stopPropagation();
        }}
        anchor="bottom"
        ModalProps={{
          keepMounted: true, // Ensures the modal stays mounted for better performance
        }}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { borderRadius: "16px 16px 0 0", padding: 3, height: "750px" },
        }}
      >
        <Box sx={{ mx: "6.5%", mb: 3 }}>
          {/* Modal Heading */}
          <Box
            mt={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: colors.gray800,
                fontSize: fonts.heading2,
                fontWeight: 600,
                mt: 3,
                mb: 2,
              }}
            >
              Exercise Library
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mb: 0,
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mr: 3,
                  textTransform: "none",
                  background: colors.gray200,
                  color: colors.gray800,
                }}
                endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleAdd}
                endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
                sx={{
                  textTransform: "none",
                  background: colors.green100,
                  color: colors.green900,
                  px: 2.5,
                }}
                disabled={!selectedCheckboxes.length > 0}
              >
                Add
              </Button>
            </Box>
          </Box>

          <Grid container spacing={2} mt={4} mb={8} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                label="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{
                  display: "flex",
                  width: "80%",
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search
                          sx={{ cursor: "pointer" }}
                          onClick={handleSearch}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} textAlign="right">
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Filter</InputLabel>
                <Select
                  sx={{
                    textAlign: "left",
                  }}
                  variant="outlined"
                  label="Filter"
                  defaultValue="relevance"
                  value={sortBy}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="relevance">Relevance</MenuItem>
                  <MenuItem value="dateCreated">Date Added</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* First Template Option */}
          {accordionsData.map((accordion) => (
            <Accordion
              key={accordion.id}
              disableGutters
              elevation={0}
              expanded={expanded === accordion.id}
              onChange={handleChange(accordion.id)}
              sx={{
                border: "none",
                "&:before": {
                  display: "none",
                },
                borderBottom: expanded === accordion.id ? "none" : "1px solid",
                borderColor: colors.gray400,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownOutlinedIcon sx={{ colors: "#1C1B1F" }} />
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCheckboxes.includes(accordion.id)} // Check if this accordion is selected
                      onChange={handleCheckboxChange(accordion.id)} // Handle selection/unselection
                      sx={{ mr: 6 }}
                    />
                  }
                  label={accordion.title}
                  sx={{ color: "#1C1B1F" }}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", alignItems: "start", mx: "5%" }}>
                  <video
                    src={accordion.videoCdnUrl} // The video URL
                    controls
                    controlsList="nodownload noremoteplayback"
                    poster={accordion.thumbnailCdnUrl} // Placeholder thumbnail URL
                    style={{
                      width: "30%",
                      height: "100%",
                      objectFit: "cover",
                      marginRight: 16,
                    }}
                  >
                    Your browser does not support the video tag.
                  </video>
                  <Box ml={3}>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 14, fontWeight: 500 }}
                    >
                      {/* {accordion.template} */}
                    </Typography>
                    <Typography sx={{ fontSize: 12, mt: 2 }}>
                      {accordion.description}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          {totalPages > 1 && (
            <Pagination
              count={totalPages} // Use dynamic totalPages from state
              page={currentPage} // Control the current page
              onChange={handlePageChange} // Handle page changes
              color="primary"
              sx={{
                marginTop: 5,
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
          {/* Create Section Button */}
        </Box>
      </Drawer>
    </>
  );
};
export default ExerciseModal;
