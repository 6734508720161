import { Jodit } from "jodit-react";

const isMobile = window.innerWidth < 768;
const config = {
  // placeholder: "Start typing....",
  // theme: "dark", // Add this line for dark mode
  disablePlugins: ["speech-recognize"],
  removeButtons: [
    "undo",
    "redo",
    "superscript",
    "subscript",
    "search",
    "source",
    "find",
    "fullsize",
    "preview",
    "print",
    "about",
    // "image",
    // "file",
    "video",
    "lineBreak",
    "table",
  ],
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: Jodit.INSERT_CLEAR_HTML,
  processPasteHTML: false,
  direction: "ltr",
  style: {
    height: isMobile ? "245px" : "400px",
    //background: '#000000',
    textAlign: "left",
  },
  tabIndex: 1,
  defaultTimeout: 0,
  addNewLine: false,
  toolbarAdaptive: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
};
export default config;
