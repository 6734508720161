import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import colors from "../../styles/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import DividerLine from "../../components/utils/DividerLine";
import fonts from "../../styles/fontVars";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";

import FileUploader from "../../components/utils/FileUploader";
import config from "../../components/RichTextEditor/config";
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
import PresentModal from "../../components/utils/PresentColorsModal";
import ConfirmationDialog from "../../components/utils/Dialog";
import routes from "../../api/routes";
import useApiRequest from "../../api/api";
import { useNavigate } from "react-router-dom";
import SectionPageSkeleton from "./Loader/SectionSkeletonLoader";
import EditableTitle from "../../components/utils/EditableTitle";
import LoadingBackdrop from "../../components/utils/loader";

const PresentTemplateSectionPage = ({ data, colorSettings }) => {
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(data);
  const [colorsData, setColorsData] = useState(colorSettings);
  const [logoFile, setLogoFile] = useState(null);
  const [bgLogoFile, setbgLogoFile] = useState(null);
  const [bgImageOverlayOpacity, setBgImageOverlayOpacity] = useState("");
  const [hasAdvancedChanges, setHasAdvancedChanges] = useState(false);
  const [editingTitle, setEditingTitle] = useState(""); // separate state for editing
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(true);

  const [isColorModalOpen, setIsColorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { makeApiCall } = useApiRequest();
  const handleColorOpenModal = () => {
    setIsColorModalOpen(true);
  };

  const handleCloseColorModal = () => {
    setIsColorModalOpen(false);
  };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const AcuteIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray600}
      style={{ marginRight: 10 }}
    >
      <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
    </svg>
  );
  // Function to handle drag and drop
  const [isEditing, setIsEditing] = useState(false);

  const [showCircularProgressBar, setShowCircularProgressBar] = useState(false);

  const handleCircularProgressBarToggle = (event) => {
    setShowCircularProgressBar(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const [timerType, setTimerType] = useState("");
  const [contentPosition, setContentPosition] = useState("");
  const [progressPosition, setProgressPosition] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [showContent, setShowConent] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [showImage, setShowImage] = useState(true);

  const handleShowContentToggle = (event) => {
    setShowConent(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleShowLogoToggle = (event) => {
    setShowLogo(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleShowImageToggle = (event) => {
    setShowImage(event.target.checked);
    setHasAdvancedChanges(true);
  };
  const handleTimerTypeChange = (event) => {
    setTimerType(event.target.value);
    setHasAdvancedChanges(true);
  };
  const handleContentPositionChange = (event) => {
    setContentPosition(event.target.value);
    setHasAdvancedChanges(true);
  };

  const handleTotalTimeChange = (event) => {
    const value = event.target.value;

    // Allow empty value to let the user clear the field
    if (value === "") {
      setTotalTime("");
    } else {
      // Prevent negative values
      setTotalTime(Math.max(0, Number(value)));
    }

    setHasAdvancedChanges(true);
  };

  const handleProgressPositionChange = (event) => {
    setProgressPosition(event.target.value);
    setHasAdvancedChanges(true);
  };
  const handleLogoFileBase64 = (base64) => {
    setLogoFile(base64);
    setHasAdvancedChanges(true);
  };
  const handleBgLogoFileBase64 = (base64) => {
    setbgLogoFile(base64);
    setHasAdvancedChanges(true);
  };
  const handleEditorChange = (content) => {
    // Only set hasAdvancedChanges to true if content is different from initial
    if (content !== editorText) {
      setHasAdvancedChanges(true);
    } else {
      setHasAdvancedChanges(false);
    }
    setEditorText(content);

    // setHasAdvancedChanges(true); // Set advanced changes to true here
  };
  const [editorText, setEditorText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = async (noLoader) => {
    setEditingTitle(initialData.title);
    setShowCircularProgressBar(initialData.circularProgress);
    setTotalTime(initialData.contentTimeSeconds || "");
    setEditorText(initialData.contentText);
    setTimerType(initialData.timerType);
    setProgressPosition(initialData.progressPosition);
    setShowConent(initialData.showContent);
    setContentPosition(initialData.contentPosition);
    setShowLogo(initialData.showLogo);
    setShowImage(initialData.showBgImage);
    setLogoFile(initialData.logoCdnUrl || null);
    setbgLogoFile(initialData.bgImageCdnUrl || null);
    setBgImageOverlayOpacity(initialData.bgImageOverlayOpacity);
    if (!noLoader) {
      setIsSkeletonLoader(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Call the async function if id exists
  }, []); // Add 'id' as a dependency

  const ShowDialog = () => {
    setOpenDialog(true);
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);

    const response = await makeApiCall(
      routes.section.delete(data.id),
      "DELETE"
    );
    if (response?.status) {
      // Remove user from localStorage
      setIsLoading(false);

      // Optionally navigate to the homepage or login page
      navigate(`/workout?id=${data.workout.id}`);
    }
    // Close the dialog
    setOpenDialog(false);
  };

  const handleCancel = () => {
    setHasAdvancedChanges(false);
    setIsEditing(false);
    fetchData();
  };

  const handleSaveColors = (colors) => {
    setHasAdvancedChanges(true);
    setColorsData(colors);
  };
  const handleSave = async () => {
    setIsLoading(true);
    try {
      // Second API payload for advanced changes
      if (hasAdvancedChanges) {
        const payload = {
          title: editingTitle,
          circularProgress: showCircularProgressBar,
          timerType: timerType,
          progressPosition: progressPosition,
          contentTimeSeconds: parseInt(totalTime, 10),
          showLogo: showLogo,
          logo: logoFile,
          showBgImage: showImage,
          BgImage: bgLogoFile,
          showContent: showContent,
          contentText: editorText,
          contentPosition: contentPosition,
          progressColor: colorsData.progressColor,
          timerTextColor: colorsData.timerTextColor,
          timerBgColor: colorsData.timerBgColor,
          presentViewBgColor: colorsData.presentViewBgColor,
          bgImageOverlayOpacity:
            colorsData.bgImageOverlayOpacity || bgImageOverlayOpacity,
        };

        // Call the second API for advanced changes
        const response = await makeApiCall(
          routes.section.update_present(initialData.id), // Ensure this route matches your API
          "PUT",
          payload,
          "show"
        );
        if (response.status) {
          setInitialData((prev) => ({
            ...prev,
            title: editingTitle,
          }));
        }
      }

      // Update state only if there were no errors and changes were made
      if (hasAdvancedChanges) {
        setIsEditing(false);

        setHasAdvancedChanges(false);
      }
    } catch (error) {
      console.error("Error saving exercise order:", error);
    }
    setIsLoading(false);
  };

  const handleStartEdit = () => {
    setIsEditing(true);
    setEditingTitle(initialData?.title || ""); // initialize edit value
    setHasAdvancedChanges(true);
  };
  if (isSkeletonLoader) {
    return <SectionPageSkeleton />; // Render the PresentTemplateSectionPage component if template is "present"
  }
  return (
    <Box sx={{ mt: 8, mx: "8%", mb: 12 }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href="/dashboard"
          >
            Home
          </Link>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href={`/workout?id=${data.workout.id}`}
          >
            {initialData?.workout.title}
          </Link>
          <Typography color="text.secondary">{initialData?.title}</Typography>
        </Breadcrumbs>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 0,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                mr: 3,
                textTransform: "none",
                background: colors.gray200,
                color: colors.gray800,
              }}
              endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
              disabled={!hasAdvancedChanges}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
              sx={{
                textTransform: "none",
                background: colors.green100,
                color: colors.green900,
                px: 2.5,
              }}
              disabled={!hasAdvancedChanges}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={0}>
        <Grid item xs={12} mt={5} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Box sx={{ position: "relative", display: "flex" }}>
              <EditableTitle
                title={initialData?.title || ""} // displayed when not editing
                editValue={editingTitle} // separate value for editing
                isEditing={isEditing}
                onStartEdit={handleStartEdit}
                onTitleChange={setEditingTitle}
                // onKeyPress={handleKeyPress}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2} sm={4}>
          <Box>
            <Typography
              fontSize={fonts.heading3}
              color={colors.gray800}
              fontWeight={600}
              textAlign={"right"}
              sx={{ mb: 2, mt: 3 }}
              onClick={ShowDialog}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={colors.red900}
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  padding: "10px",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = colors.red100)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "transparent")
                }
              >
                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
              </svg>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, mt: -1, borderColor: "#9388D3", mb: 6 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab
            label="CONTENT"
            sx={{
              color: activeTab === 0 ? colors.teal400 : colors.gray800,
              fontWeight: 500,
              borderBottom: activeTab === 0 ? "2px solid" : "none",
              //   borderColor: colors.teal400,
            }}
          />
          <Tab
            label="ADVANCED"
            sx={{
              color: activeTab === 1 ? colors.teal400 : colors.gray800,
              borderBottom: activeTab === 1 ? "2px solid" : "none",
              //   borderColor: colors.teal400,
              fontWeight: 500,
            }}
          />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <>
          <Box sx={{ width: "100%", height: "auto" }}>
            <RichTextEditor
              setValue={handleEditorChange}
              initialValue={editorText}
              config={config}
            />
          </Box>
        </>
      )}
      {activeTab === 1 && (
        <>
          <Typography
            fontSize={fonts.body}
            color={colors.gray800}
            textAlign={"left"}
            textTransform={"uppercase"}
          >
            Template: {data.template}
          </Typography>
          <Typography
            fontSize={fonts.heading2}
            mt={5}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
            slotProps={{
              input: {
                // Adjust margin if needed
                startAdornment: <AcuteIcon />,
              },
            }}
          >
            Time
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <TextField
            label="Presentation Time (seconds)"
            type="number"
            id="num"
            value={totalTime}
            onChange={handleTotalTimeChange}
            sx={{
              mt: 0,
              width: {
                lg: "50%",
                md: "60%",
              },
              display: "flex",
              justifyContent: "start",
            }}
            helperText="This defines the time the section will show for"
          />
          <Typography
            fontSize={fonts.heading2}
            mt={5}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Theme
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: -1,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              {Object.entries({
                progressColor: colorsData.progressColor,
                timerTextColor: colorsData.timerTextColor,
                timerBgColor: colorsData.timerBgColor,
                presentViewBgColor: colorsData.presentViewBgColor,
              }).map(([key, value]) => (
                <Box
                  key={key}
                  onClick={handleColorOpenModal}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    border: "1px solid",
                    borderColor: colors.gray900,
                    backgroundColor: value,
                    cursor: "pointer",
                  }}
                />
              ))}
            </Box>

            <Box>
              {" "}
              <Button
                onClick={handleColorOpenModal}
                variant="contained"
                sx={{
                  textTransform: "none",
                  mt: 2,
                  px: 2,
                  fontWeight: 400,
                  fontSize: fonts.heading4,
                  background: colors.teal400,
                  color: "#fff",
                  "&:hover": {
                    background: colors.teal600,
                  },
                }}
                endIcon={<ColorLensOutlinedIcon />}
              >
                Customize
              </Button>
            </Box>
          </Box>
          <PresentModal
            open={isColorModalOpen}
            onClose={handleCloseColorModal}
            colorSettings={colorsData}
            onSave={handleSaveColors}
            bgImageOverlayOpacityData={bgImageOverlayOpacity}
          />
          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Progress
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show circular progress bar</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showCircularProgressBar}
                  onChange={handleCircularProgressBarToggle}
                  color="primary"
                />
              }
              sx={{ ml: 10 }}
              //   label="Show circular progress bar"
            />
          </Box>
          <Box sx={{ display: "flex", mb: 4 }}>
            <Typography
              mt={2}
              fontSize={fonts.heading4}
              color={colors.gray800}
              mr={2}
            >
              Timer Type
            </Typography>
            <FormControl sx={{ minWidth: 200, ml: 24 }}>
              <InputLabel>Type</InputLabel>
              <Select
                sx={{
                  textAlign: "left",
                }}
                variant="outlined"
                onChange={handleTimerTypeChange}
                value={timerType}
                label="Type"
              >
                <MenuItem value="down">Count Down</MenuItem>
                <MenuItem value="up">Count Up</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", mb: 4 }}>
            <Typography
              mt={2}
              fontSize={fonts.heading4}
              color={colors.gray800}
              mr={2}
            >
              Progress Position
            </Typography>
            <FormControl sx={{ minWidth: 200, ml: 17.8 }}>
              <InputLabel>Filter</InputLabel>
              <Select
                sx={{
                  textAlign: "left",
                }}
                variant="outlined"
                onChange={handleProgressPositionChange}
                value={progressPosition}
                label="Type"
                defaultValue=""
              >
                {showCircularProgressBar
                  ? [
                      <MenuItem key="left" value="left">
                        Left
                      </MenuItem>,
                      <MenuItem key="right" value="right">
                        Right
                      </MenuItem>,
                    ]
                  : [
                      <MenuItem key="top" value="top">
                        Top
                      </MenuItem>,
                      <MenuItem key="bottom" value="bottom">
                        Bottom
                      </MenuItem>,
                    ]}
              </Select>
            </FormControl>
          </Box>

          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Content
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show Content</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showContent}
                  onChange={handleShowContentToggle}
                  color="primary"
                />
              }
              sx={{ ml: 21.8 }}
              //   label="Show circular progress bar"
            />
          </Box>
          {showContent && (
            <Box sx={{ display: "flex" }}>
              <Typography
                mt={2}
                fontSize={fonts.heading4}
                color={colors.gray800}
                mr={2}
              >
                Content Position
              </Typography>
              <FormControl sx={{ minWidth: 200, ml: 18.7 }}>
                <InputLabel>Filter</InputLabel>
                <Select
                  sx={{
                    textAlign: "left",
                  }}
                  variant="outlined"
                  onChange={handleContentPositionChange}
                  value={contentPosition}
                  label="Type"
                  defaultValue=""
                >
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}

          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Logo
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show Logo</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showLogo}
                  onChange={handleShowLogoToggle}
                  color="primary"
                />
              }
              sx={{ ml: 24.8 }}
              //   label="Show circular progress bar"
            />
          </Box>
          {showLogo && (
            <FileUploader
              onFileChange={handleLogoFileBase64}
              value={logoFile}
            />
          )}
          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Background
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mt: 3,
              mb: 4,
            }}
          >
            <Typography>Show Image</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showImage}
                  onChange={handleShowImageToggle}
                  color="primary"
                />
              }
              sx={{ ml: 24.8 }}
              //   label="Show circular progress bar"
            />
          </Box>
          {showImage && (
            <FileUploader
              onFileChange={handleBgLogoFileBase64}
              value={bgLogoFile}
            />
          )}
        </>
      )}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDialog}
        onConfirm={handleDelete}
        title="Delete"
        content="Are you sure you want to delete this section? All its exercises will be deleted along with it"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
    </Box>
  );
};

export default PresentTemplateSectionPage;
