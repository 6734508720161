import React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/system";

const PreviousUploadsContainer = styled(Box)({
  position: "relative",
  width: "100%",
  marginTop: "16px",
});

const PreviousUploads = styled(Box)({
  display: "flex",
  overflowX: "auto",
  scrollBehavior: "smooth",
  WebkitOverflowScrolling: "touch",
  padding: "4px",
  gap: "20px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
});

export default function PreviousUploadsSkeleton() {
  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Skeleton width="60%" height={24} sx={{ mb: 2 }} />
      <PreviousUploadsContainer>
        <PreviousUploads>
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={100}
              height={100}
              sx={{ borderRadius: "8px", minWidth: "100px" }}
            />
          ))}
        </PreviousUploads>
      </PreviousUploadsContainer>
    </Box>
  );
}
