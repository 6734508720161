import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import colors from "../../styles/colors";
import { formatDateToLongForm } from "../../components/utils/DateFormatter";
import { useNavigate } from "react-router-dom";

export default function BasicTable({ data }) {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleRowClick = (id) => {
    navigate(`/support/ticket?id=${id}`);
  };
  return (
    <TableContainer elevation={0} component={Paper}>
      <Table
        sx={{
          width: "100%",
          "& .MuiTableCell-root": {
            borderColor: colors.gray400,
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ pr: 8 }}>ID</TableCell>
            <TableCell align="left">Domain</TableCell>
            <TableCell align="left">Subject</TableCell>
            <TableCell align="left">Submitted</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => handleRowClick(row.id)}
              sx={{
                cursor: "pointer",
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": {
                  backgroundColor: colors.gray50, // Light background color on hover
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell sx={{ py: 3 }} align="left">
                {row.domain}
              </TableCell>
              <TableCell sx={{ py: 3 }} align="left">
                {row.subject}
              </TableCell>
              <TableCell sx={{ py: 3 }} align="left">
                {formatDateToLongForm(row.createdOn)}
              </TableCell>
              <TableCell
                sx={{
                  py: 3,
                  color:
                    row.status === "open" ? colors.green900 : colors.red900,
                  textTransform: "uppercase",
                }}
                align="left"
              >
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
