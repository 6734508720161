import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  Breadcrumbs,
  Link,
  Alert,
  Paper,
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import colors from "../../styles/colors";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import fonts from "../../styles/fontVars";
import google from "../../assets/google.svg";
import facebook from "../../assets/facebook.svg";
import apple from "../../assets/apple.svg";
import key from "../../assets/key.svg";
import edit from "../../assets/edit.svg";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import useApiRequest from "../../api/api";
import { useSnackbar } from "../../components/utils/SnackbarProvider";
import routes from "../../api/routes";
import ConfirmationDialog from "../../components/utils/Dialog";
import { useNavigate } from "react-router-dom";
import { useGoogleAuth } from "../../components/utils/AuthServices";
import LoadingBackdrop from "../../components/utils/loader";
import { useGoogleLogin } from "@react-oauth/google";
const AccountSettingsPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { makeApiCall } = useApiRequest();
  const showSnackbar = useSnackbar();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [showPassword3, setShowPassword3] = React.useState(false);
  // const [storedUser, setStoreduser] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = useState(
    "Are you sure you want to delete your account? This action is irreversible"
  ); // Default message
  const [openCloseAccountDialog, setOpenCloseAccountDialog] =
    React.useState(false);
  const handleCancelDialog = () => {
    setOpenDialog(false);
  };
  const handleCancelCloseAccountDialog = () => {
    setOpenCloseAccountDialog(false);
    setDialogMessage(
      "Are you sure you want to delete your account? This action is irreversible"
    );
  };
  const ShowCloseAccountDialog = () => {
    setOpenCloseAccountDialog(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleClickShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };
  const ShowDialog = () => {
    setOpenDialog(true);
  };
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    // setStoreduser(storedUser);
    setIsConnected(!!storedUser.googleUserId);
  }, []);
  const storedUser = JSON.parse(localStorage.getItem("user"));

  const handleGoogleConnect = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const accessToken = response.access_token;
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!userInfoResponse.ok) {
          throw new Error("Failed to fetch user info");
        }

        const userInfo = await userInfoResponse.json();
        const storedUser = JSON.parse(localStorage.getItem("user") || "{}");

        const payload = {
          username: storedUser.username,
          image: storedUser.image,
          subscribeToNewsletter: storedUser.subscribeToNewsletter,
          facebookUserId: storedUser.facebookUserId,
          appleUserId: storedUser.appleUserId,
          googleUserId: userInfo.sub,
        };

        const responseApi = await makeApiCall(
          routes.profile.account,
          "PUT",
          payload
        );

        if (responseApi.status) {
          storedUser.googleUserId = userInfo.sub;
          localStorage.setItem("user", JSON.stringify(storedUser));
          setIsConnected(true);
        }
      } catch (error) {
        console.error("Failed to connect Google account:", error);
      }
    },
    onError: (error) => {
      console.error("Google connection failed:", error);
    },
  });

  const handleLogout = async () => {
    setIsLoading(true);

    const response = await makeApiCall(routes.auth.logout, "DELETE");
    if (response?.status) {
      // Remove user from localStorage
      setIsLoading(false);

      // Optionally navigate to the homepage or login page
      localStorage.removeItem("user");

      navigate("/");
    } else {
    }

    // Close the dialog
    setOpenDialog(false);
  };
  const initialFormState = {
    username: storedUser?.username || "",
    email: storedUser?.email || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    subscribeToNewsletter: storedUser?.newsletterMemberId != null,
  };

  // Current form state
  const [formState, setFormState] = React.useState(initialFormState);
  const [hasChanges, setHasChanges] = React.useState(false);
  // Track if form has changes
  const [hasProfileChanges, setHasProfileChanges] = React.useState(false);
  const [hasPasswordChanges, setHasPasswordChanges] = React.useState(false);
  const updateStoredUser = (newData) => {
    const updatedUser = {
      ...storedUser,
      ...newData,
    };
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };

  // Handle form field changes
  const handleFieldChange = (field) => (event) => {
    const newValue =
      field === "subscribeToNewsletter"
        ? event.target.checked
        : event.target.value;
    setFormState((prev) => ({
      ...prev,
      [field]: newValue,
    }));

    // Check for profile changes (username or email)
    if (field === "username" || field === "subscribeToNewsletter") {
      const hasProfileUpdate =
        field === "username"
          ? newValue !== initialFormState.username
          : field === "subscribeToNewsletter"
          ? newValue !== initialFormState.subscribeToNewsletter
          : formState.username !== initialFormState.username ||
            formState.subscribeToNewsletter !==
              initialFormState.subscribeToNewsletter;
      setHasProfileChanges(hasProfileUpdate);
    }

    // Check for password changes
    if (
      field === "currentPassword" ||
      field === "newPassword" ||
      field === "confirmPassword"
    ) {
      const hasPasswordUpdate =
        newValue !== "" ||
        formState.currentPassword !== "" ||
        formState.newPassword !== "" ||
        formState.confirmPassword !== "";
      setHasPasswordChanges(hasPasswordUpdate);
    }

    // Update overall changes flag
    const updatedState = {
      ...formState,
      [field]: newValue,
    };

    const hasAnyChange = Object.keys(initialFormState).some(
      (key) => initialFormState[key] !== updatedState[key]
    );

    setHasChanges(hasAnyChange);
  };

  // Reset form to initial state
  const handleCancel = () => {
    setFormState(initialFormState);
    setSelectedImage(null);
    setHasChanges(false);
    setHasProfileChanges(false);
    setHasPasswordChanges(false);
  };
  const [selectedImage, setSelectedImage] = React.useState(null);
  const fileInputRef = React.useRef(null);

  // Function to compress and convert image to base64
  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to base64 with reduced quality
          const base64String = canvas.toDataURL("image/jpeg", 0.7);
          resolve(base64String);
        };
      };
    });
  };

  // Handle image selection
  const handleImageSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedBase64 = await compressImage(file);
        setSelectedImage(compressedBase64);
        setHasProfileChanges(true);
        setHasChanges(true);
      } catch (error) {
        console.error("Error processing image:", error);
        showSnackbar("Error processing image", "error");
      }
    }
  };

  // Trigger file input click
  const handleEditImageClick = () => {
    fileInputRef.current.click();
  };
  // Handle form submission
  const handleSave = async () => {
    const apiCalls = [];
    const apiResults = [];
    // Prepare profile update if needed
    if (hasProfileChanges) {
      const profilePayload = {
        username: formState.username,
        image: selectedImage || storedUser.image, // Include image if selected
        subscribeToNewsletter: formState.subscribeToNewsletter,
        facebookUserId: storedUser.facebookUserId,
        appleUserId: storedUser.appleUserId,
        googleUserId: storedUser.googleUserId,
      };
      apiCalls.push(
        makeApiCall(routes.profile.account, "PUT", profilePayload).then(
          (result) => {
            apiResults.push({ type: "profile", result });
            return result;
          }
        )
      );
    }

    // Prepare password update if needed
    if (hasPasswordChanges) {
      // Validate password fields
      if (formState.newPassword !== formState.confirmPassword) {
        showSnackbar("New password and confirm password don't match", "error");
        return;
      }

      const passwordPayload = {
        email: formState.email,
        oldPassword: formState.currentPassword || "",
        password: formState.newPassword,
        confirmPassword: formState.confirmPassword,
      };
      apiCalls.push(
        makeApiCall(routes.password.password, "PUT", passwordPayload, "show")
      );

      updateStoredUser({
        isPasswordSet: true,
      });
    }

    // Make API calls if there are any
    if (apiCalls.length > 0) {
      setIsLoading(true);

      const results = await Promise.all(apiCalls);
      // Check if all API calls were successful
      const allSuccessful = results.every((result) => result?.status);

      if (allSuccessful) {
        const profileResult = apiResults.find((r) => r.type === "profile");
        if (profileResult && profileResult.result.status) {
          updateStoredUser({
            username: formState.username,
            image: selectedImage || storedUser.image,
            newsletterMemberId:
              profileResult.result.data.user.newsletterMemberId,
          });
        }

        // Reset form state after successful save
        setFormState({
          ...formState,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setHasChanges(false);
        setSelectedImage(null);
        setHasProfileChanges(false);
        setHasPasswordChanges(false);
      }
      setIsLoading(false);
    }
  };
  const handleCloseAccount = async () => {
    setIsLoading(true);

    const response = await makeApiCall(routes.profile.account, "DELETE");
    if (response.status) {
      localStorage.removeItem("user");
      setIsLoading(false);

      navigate("/");
    }
  };

  const handleCancelSSO = async () => {
    setIsLoading(true);

    try {
      const payload = {
        ...storedUser,
        googleUserId: null,
      };
      const responseApi = await makeApiCall(
        routes.profile.account,
        "PUT",
        payload
      );
      if (responseApi.status) {
        storedUser.googleUserId = null;
        localStorage.setItem("user", JSON.stringify(storedUser));
        setIsConnected(false);
      } else {
        setDialogMessage(responseApi.message);
        ShowCloseAccountDialog(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to disconnect Google account:", error);
    }
  };
  return (
    <Box sx={{ mt: 8, mx: "8%" }}>
      <LoadingBackdrop isLoading={isLoading} />

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            href="/dashboard"
          >
            Home
          </Link>
          <Typography color="text.secondary">Account</Typography>
        </Breadcrumbs>
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={ShowDialog}
            sx={{
              textTransform: "none",
              background: colors.red900,
              color: colors.red100,
              mt: 6,
              ml: 15,
            }}
            endIcon={<LogoutOutlinedIcon sx={{ color: "#D9D9D9" }} />}
          >
            Logout
          </Button>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 0,
              mt: 5,
            }}
          >
            {hasChanges && (
              <>
                <Button
                  variant="contained"
                  sx={{
                    mr: 3,
                    textTransform: "none",
                    background: colors.gray200,
                    color: colors.gray800,
                  }}
                  onClick={handleCancel}
                  endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSave}
                  endIcon={
                    <CheckOutlinedIcon sx={{ color: colors.green900 }} />
                  }
                  sx={{
                    textTransform: "none",
                    background: colors.green100,
                    color: colors.green900,
                    px: 2.5,
                  }}
                >
                  Save
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12} mt={-8} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <Avatar
                sx={{
                  width: 100,
                  height: 100,
                  mr: 2,
                  border: "4.2px solid transparent",
                  // Create gradient border
                  backgroundImage:
                    "linear-gradient(white, white), linear-gradient(148.43deg, #9747FF -2.38%, #00796B 40.71%)",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                }}
                src={
                  selectedImage ||
                  storedUser?.image ||
                  "https://d3vdd33llamc8d.cloudfront.net/assets/empty/placeholder.png"
                }
                alt="User Avatar"
              />
              <IconButton
                color="primary"
                sx={{
                  position: "absolute",
                  right: 7,
                  bottom: 1,
                  backgroundColor: colors.teal700,
                  "&:hover": { backgroundColor: "primary.main" },
                  color: colors.teal50,
                  borderRadius: "50%",
                }}
                onClick={handleEditImageClick}
              >
                <Box
                  component="img"
                  src={edit}
                  alt="edit"
                  sx={{ cursor: "pointer" }}
                />
              </IconButton>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageSelect}
                accept="image/*"
                style={{ display: "none" }}
              />
            </Box>{" "}
          </Box>

          <TextField
            label="Email"
            variant="outlined"
            value={formState.email}
            onChange={handleFieldChange("email")}
            sx={{
              mb: 2,
              mt: 6,
              width: "80%",
              display: "flex",
              justifyContent: "start",
            }}
            inputProps={{
              readOnly: true, // Make the field read-only
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            label="Username"
            variant="outlined"
            value={formState.username}
            onChange={handleFieldChange("username")}
            sx={{
              mt: 4,
              width: "80%",
              display: "flex",
              justifyContent: "start",
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlinedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="subscribeToNewsletter"
                checked={formState.subscribeToNewsletter}
                onChange={handleFieldChange("subscribeToNewsletter")}
              />
            }
            sx={{
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              fontSize: "24px",
              mt: 2,
              mb: 4,
            }}
            label={
              <Typography
                component="span"
                sx={{
                  fontSize: "16px",
                  ml: 0,
                }}
              >
                I agree to the receive occasional emails regarding news or
                updates
              </Typography>
            }
          />

          <Typography
            fontSize={fonts.heading3}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
            mt={6}
          >
            Change Password
          </Typography>
          {storedUser?.isPasswordSet && (
            <TextField
              label="Current Password"
              type={showPassword3 ? "text" : "password"}
              margin="normal"
              value={formState.currentPassword}
              onChange={handleFieldChange("currentPassword")}
              sx={{
                mt: 4,
                width: "80%",
                display: "flex",
                justifyContent: "start",
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword3}
                      >
                        {showPassword3 ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}

          <TextField
            sx={{
              mt: 3,
              width: "80%",
              display: "flex",
              justifyContent: "start",
            }}
            label="New Password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            value={formState.newPassword}
            onChange={handleFieldChange("newPassword")}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            sx={{
              mt: 3,
              width: "80%",
              display: "flex",
              justifyContent: "start",
            }}
            label="Confirm Password"
            type={showPassword2 ? "text" : "password"}
            value={formState.confirmPassword}
            onChange={handleFieldChange("confirmPassword")}
            margin="normal"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                    >
                      {showPassword2 ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        <Grid item xs={12} mt={2} md={6}>
          <Box
            sx={{
              ml: {
                lg: "25%", // For large screens and above
                md: "0%", // For medium screens
              },
            }}
          >
            <Typography
              fontSize={fonts.heading3}
              color={colors.gray800}
              fontWeight={600}
              textAlign={"left"}
              sx={{ mb: 2, mt: 4 }}
            >
              Connected Accounts
            </Typography>
            {/* <Paper
              elevation={0}
              sx={{
                p: 3,
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "solid 1px",
                borderColor: "#D9D9D9",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={facebook} alt="facebook" />
                <Typography ml={3} fontSize={fonts.heading4}>
                  Facebook
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {storedUser && storedUser.facebookUserId ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{
                        mr: 3,
                        textTransform: "none",
                        fontSize: fonts.info,
                        fontWeight: 400,
                        px: 2,
                        letterSpacing: "0.75px",
                      }}
                      startIcon={<img src={key} alt="key" />}
                    >
                      Connected
                    </Button>
                    <CloseOutlinedIcon
                      sx={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        padding: "5px",
                        "&:hover": {
                          backgroundColor: "#EEEEEE",
                        },
                      }}
                    />
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      color: colors.teal600,
                      textTransform: "none",
                      px: 2,
                      fontWeight: 400,
                    }}
                    size="small"
                  >
                    Connect Now
                  </Button>
                )}
              </Box>
            </Paper> */}
            {/* <Paper
              elevation={0}
              sx={{
                p: 3,
                mt: 5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "solid 1px",
                borderColor: "#D9D9D9",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={apple} alt="Apple" />
                <Typography ml={3} fontSize={fonts.heading4}>
                  Apple
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {storedUser && storedUser.appleUserId ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{
                        mr: 3,
                        textTransform: "none",
                        fontSize: fonts.info,
                        fontWeight: 400,
                        px: 2,
                        letterSpacing: "0.75px",
                      }}
                      startIcon={<img src={key} alt="key" />}
                    >
                      Connected
                    </Button>
                    <CloseOutlinedIcon
                      sx={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        padding: "5px",
                        "&:hover": {
                          backgroundColor: "#EEEEEE",
                        },
                      }}
                    />
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      color: colors.teal600,
                      textTransform: "none",
                      px: 2,
                      fontWeight: 400,
                    }}
                    size="small"
                  >
                    Connect Now
                  </Button>
                )}
              </Box>
            </Paper> */}
            <Paper
              elevation={0}
              sx={{
                p: 3,
                mt: 5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "solid 1px",
                borderColor: "#D9D9D9",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={google} alt="google" />
                <Typography ml={3} fontSize={fonts.heading4}>
                  Google
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isConnected ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{
                        mr: 3,
                        textTransform: "none",
                        fontSize: fonts.info,
                        fontWeight: 400,
                        px: 2,
                        letterSpacing: "0.75px",
                      }}
                      startIcon={<img src={key} alt="key" />}
                    >
                      Connected
                    </Button>
                    <CloseOutlinedIcon
                      sx={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        padding: "5px",
                        "&:hover": {
                          backgroundColor: "#EEEEEE",
                        },
                      }}
                      onClick={handleCancelSSO}
                    />
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      color: colors.teal600,
                      textTransform: "none",
                      px: 2,
                      fontWeight: 400,
                    }}
                    onClick={handleGoogleConnect}
                    size="small"
                  >
                    Connect Now
                  </Button>
                )}
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} mt={5} md={12}>
          <Typography
            fontSize={fonts.heading3}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Close Account
          </Typography>
          <Alert
            variant="outlined"
            severity="error"
            sx={{
              mt: 2,
              background: colors.red100,
              color: colors.red900,
              border: "2px solid", // Set the border width to 2px
              borderColor: colors.red900, // Optional: customize border color if needed
              textAlign: "left",
            }}
          >
            Are you sure you want to close your account? This action is
            irreversible. All of the data associated with this account will be
            deleted from the platform.
          </Alert>
          <Button
            variant="contained"
            sx={{
              display: "flex",
              justifyContent: "left",
              mt: 4,
              mb: 10,
              textTransform: "none",
              fontSize: fonts.heading4,
              background: colors.red900,
              color: colors.red100,
            }}
            onClick={ShowCloseAccountDialog}
            endIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
              </svg>
            }
          >
            Close My Account
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDialog}
        onConfirm={handleLogout}
        title="Logout"
        content="Are you sure you want to logout of your account?"
        cancelButtonText="Cancel"
        confirmButtonText="Logout"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
      <ConfirmationDialog
        open={openCloseAccountDialog}
        onClose={handleCancelCloseAccountDialog}
        onConfirm={handleCloseAccount}
        title="Delete"
        content={dialogMessage}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        cancelButtonProps={{ sx: { color: colors.gray500 } }}
        confirmButtonProps={{ sx: { color: colors.red900 } }}
      />
    </Box>
  );
};

export default AccountSettingsPage;
