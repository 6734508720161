// src/AuthServices.js

import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

import routes from "../../api/routes";
import useApiRequest from "../../api/api";

export const useGoogleAuth = () => {
  const navigate = useNavigate();
  const { makeApiCall } = useApiRequest();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const accessToken = response.access_token;

        // Fetch user info from Google API using fetch
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!userInfoResponse.ok) {
          throw new Error("Failed to fetch user info");
        }

        const userInfo = await userInfoResponse.json();
        const payload = {
          googleUserId: userInfo.sub,
          username: userInfo.name,
          email: userInfo.email,
          image: userInfo.picture,
        };
        const responseApi = await makeApiCall(routes.auth.sso, "POST", payload);
        if (responseApi.status) {
          localStorage.setItem("user", JSON.stringify(responseApi.data.user));
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
    },
  });
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const handleGoogleConnect = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const accessToken = response.access_token;

        // Fetch user info from Google API using fetch
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!userInfoResponse.ok) {
          throw new Error("Failed to fetch user info");
        }

        const userInfo = await userInfoResponse.json();
        const payload = {
          username: storedUser.username,
          image: storedUser.image, // Include image if selected
          subscribeToNewsletter: storedUser.subscribeToNewsletter,
          facebookUserId: storedUser.facebookUserId,
          appleUserId: storedUser.appleUserId,
          googleUserId: userInfo.sub,
        };
        const responseApi = await makeApiCall(
          routes.profile.account,
          "PUT",
          payload
        );
        if (responseApi.status) {
          const storedUser = JSON.parse(localStorage.getItem("user")) || {};
          storedUser.googleUserId = userInfo.sub; // Update googleUserId
          localStorage.setItem("user", JSON.stringify(storedUser));
        }
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
    },
  });

  return { handleGoogleLogin, handleGoogleConnect };
};
