import React from "react";
import { Box, Skeleton, Grid, Tab, Tabs } from "@mui/material";

const SectionPageSkeleton = () => {
  return (
    <Box sx={{ mt: 8, mx: "8%", mb: 12 }}>
      {/* Breadcrumbs Skeleton */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Skeleton width={60} />
          <Skeleton width={20} />
          <Skeleton width={80} />
          <Skeleton width={20} />
          <Skeleton width={100} />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Skeleton width={100} height={40} />
          <Skeleton width={100} height={40} />
        </Box>
      </Box>

      {/* Title Section */}
      <Grid container spacing={0}>
        <Grid item xs={12} mt={5} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <Skeleton width={300} height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} mt={2} sm={4}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Skeleton width={40} height={40} variant="circular" />
          </Box>
        </Grid>
      </Grid>

      {/* Tabs Skeleton */}
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 6 }}>
        <Tabs value={0}>
          <Tab label={<Skeleton width={80} />} />
          <Tab label={<Skeleton width={80} />} />
        </Tabs>
      </Box>

      {/* New Exercise Button Skeleton */}
      <Skeleton
        variant="rectangular"
        height={80}
        sx={{ borderRadius: 1, mb: 6 }}
      />

      {/* Exercise Cards Skeleton */}
      {[1, 2, 3].map((index) => (
        <Box
          key={index}
          sx={{
            mb: 3,
            p: 3,
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton width={200} height={32} />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Skeleton width={40} height={40} variant="circular" />
              <Skeleton width={40} height={40} variant="circular" />
              <Skeleton width={40} height={40} variant="circular" />
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Skeleton height={24} width="80%" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Skeleton width={100} height={24} />
                <Skeleton width={100} height={24} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}

      {/* Advanced Tab Content Skeleton */}
      <Box sx={{ display: "none" }}>
        {" "}
        {/* This will be shown when advanced tab is active */}
        <Skeleton width="100%" height={60} sx={{ mb: 4 }} />
        {/* Theme Section */}
        <Skeleton width={200} height={32} sx={{ mb: 2 }} />
        <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
          {[1, 2, 3, 4, 5].map((index) => (
            <Skeleton key={index} variant="circular" width={40} height={40} />
          ))}
        </Box>
        {/* Form Fields */}
        {[1, 2, 3, 4].map((index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Skeleton width={200} height={32} sx={{ mb: 1 }} />
            <Skeleton width="50%" height={56} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SectionPageSkeleton;
