import { React } from "react";
import { Grid, Box, Card, CardContent } from "@mui/material";
import { Skeleton } from "@mui/material";

const AllWorkoutSkeleton = () => {
  // Array to represent number of skeleton cards to show
  const skeletonItems = Array(6).fill(null);

  return (
    <>
      {/* Header Skeleton */}
      <Box mx="6.3%">
        <Skeleton
          variant="text"
          width="150px"
          height={40}
          sx={{ marginTop: 4 }}
        />
        <Skeleton variant="text" width="100%" height={2} sx={{ marginY: 2 }} />
      </Box>

      {/* Search and Filter Skeleton */}
      <Box mx="6.3%">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Skeleton variant="rectangular" width="80%" height={56} />
          </Grid>
          <Grid item xs={12} sm={6} textAlign="right">
            <Skeleton
              variant="rectangular"
              width={200}
              height={56}
              sx={{ ml: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Workout Cards Skeleton */}
      <Box ml="6.2%" mr="3.3%">
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {skeletonItems.map((_, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <Card
                variant="none"
                sx={{
                  marginRight: "10%",
                  marginBottom: "10%",
                  borderRadius: "8px",
                }}
              >
                {/* Image Skeleton */}
                <Skeleton variant="rectangular" width="100%" height={300} />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardContent sx={{ width: "100%" }}>
                    {/* Title Skeleton */}
                    <Skeleton variant="text" width="70%" height={24} />
                    {/* Date Skeleton */}
                    <Skeleton
                      variant="text"
                      width="40%"
                      height={20}
                      sx={{ mt: 1 }}
                    />
                  </CardContent>
                  <Box sx={{ display: "flex", pr: 2 }}>
                    {/* Action Icons Skeleton */}
                    <Skeleton
                      variant="circular"
                      width={40}
                      height={40}
                      sx={{ mr: 1 }}
                    />
                    <Skeleton variant="circular" width={40} height={40} />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AllWorkoutSkeleton;
