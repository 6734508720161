import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Drawer,
  Grid,
} from "@mui/material";

import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DividerLine from "./DividerLine";
import { Add } from "@mui/icons-material";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import DraggableSections from "../workout/DraggableSections";
import SetTimingsModal from "./TimingsModal";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import LoadingBackdrop from "./loader";
import { useNavigate } from "react-router-dom";
import ExerciseModalSkeleton from "./loaders/EditExerciseModalSkeleton";
import { useSnackbar } from "./SnackbarProvider";

const EditExerciseModal = ({ open, onClose, section }) => {
  const showSnackbar = useSnackbar(); // Use the global Snackbar

  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [isTimingModalOpen, setIsTimingModalOpen] = useState(false);
  const { makeApiCall } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [titleChanged, setTitleChanged] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [initialSections, setInitialSections] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({
    title: false,
    description: false,
    preparationTime: false,
    order: false,
  });
  const [preparationTime, setPreparationTime] = useState("");
  const AcuteIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray600}
      style={{ marginRight: 10 }}
    >
      <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
    </svg>
  );
  const handleOpenTimingModal = (section, e) => {
    e?.stopPropagation();
    // setSelectedSection(section);
    setIsTimingModalOpen(true);
  };
  const fetchData = async () => {
    if (section?.id) {
      setIsLoading(true);
      const response = await makeApiCall(routes.exercise.get(section.id));
      setSections(response.data.userExercise);
      setInitialSections(response.data.userExercise);
      setIsLoading(false);

      setPendingChanges({
        title: false,
        description: false,
        preparationTime: false,
        order: false,
      });
      // You can map or set the data here if the response is needed to update sections
    }
  };

  useEffect(() => {
    if (section) {
      setNewTitle(section.title || ""); // Update newTitle when section changes
      setNewDescription(section.description || ""); // Update newDescription when section changes
      setPreparationTime(section.preparationTimeSeconds || ""); // Update newDescription when section changes
      setSections([section]);
      fetchData(); // Fetch data when section changes
    }
  }, [section]);

  const handleCloseTimingModal = () => {
    setIsTimingModalOpen(false);
    // setSelectedSection(null);
  };

  const handleSaveTimings = async (exerciseTime, restTime) => {
    const payload = {
      userExerciseId: section.id,
      exerciseTimeSeconds: exerciseTime,
      restTimeSeconds: restTime,
    };
    setIsLoading(true);
    const response = await makeApiCall(
      routes.exercise.sets.add,
      "POST",
      payload
    );
    setIsLoading(false);

    if (response.status) {
      handleCloseTimingModal();
      handleApiCall();
    }
  };
  const handleApiCall = () => {
    fetchData();
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // Assume `sections.setTimings` is the array you want to reorder
    const items = Array.from(sections.setTimings);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the `setTimings` within `sections`
    setSections((prevSections) => ({
      ...prevSections,
      setTimings: items,
    }));

    setPendingChanges((prev) => ({
      ...prev,
      order: true,
    }));
  };
  const handleClose = () => {
    // Reset changes when closing
    setNewTitle(section?.title || "");
    setNewDescription(section?.description || "");
    setPreparationTime(section?.preparationTimeSeconds || "");
    setTitleChanged(false);
    setDescriptionChanged(false);
    setPendingChanges({
      title: false,
      description: false,
      order: false,
    });
    onClose(); // Call the passed onClose function
  };

  const handleSaveChanges = async () => {
    if (!preparationTime || preparationTime <= 0) {
      showSnackbar("Preparation time cannot be empty or zero.", "error");
      return;
    }
    setIsLoading(true);
    try {
      // Save title and description if changed
      if (
        pendingChanges.title ||
        pendingChanges.description ||
        pendingChanges.preparationTime
      ) {
        const updatePayload = {
          title: newTitle,
          description: newDescription,
          preparationTimeSeconds: preparationTime,
        };

        const updateResponse = await makeApiCall(
          routes.exercise.update(section.id),
          "PUT",
          updatePayload
        );

        if (updateResponse.status) {
        }
      }

      // Save order if changed
      if (pendingChanges.order) {
        const orderPayload = {
          parentId: section.id,
          indexOrder: sections.setTimings.map((item, index) => ({
            id: item.id,
            index: index,
          })),
        };

        const orderResponse = await makeApiCall(
          routes.exercise.sets.reorder,
          "PUT",
          orderPayload
        );
      }

      // Reset all pending changes
      setPendingChanges({
        title: false,
        description: false,
        order: false,
      });

      onClose();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    setIsLoading(false);
  };

  // Cancel all changes

  return (
    <>
      <LoadingBackdrop isLoading={isLoading} />

      <Drawer
        onClick={(event) => {
          event.stopPropagation();
        }}
        anchor="bottom"
        ModalProps={{
          keepMounted: true, // Ensures the modal stays mounted for better performance
        }}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { borderRadius: "16px 16px 0 0", padding: 3, height: "750px" },
        }}
      >
        {/* {isLoading ? (
          <ExerciseModalSkeleton />
        ) : ( */}
        <Box sx={{ mx: "6.5%", mb: 3 }}>
          {/* Modal Heading */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: colors.gray800,
                fontSize: fonts.heading3,
                fontWeight: 600,
                mt: 3,
                mb: 2,
              }}
            >
              Customize Your Exercise
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mb: 0,
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mr: 3,
                  textTransform: "none",
                  background: colors.gray200,
                  color: colors.gray800,
                }}
                endIcon={<CloseOutlinedIcon sx={{ color: colors.gray800 }} />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveChanges}
                endIcon={<CheckOutlinedIcon sx={{ color: colors.green900 }} />}
                sx={{
                  textTransform: "none",
                  background: colors.green100,
                  color: colors.green900,
                  px: 2.5,
                }}
                disabled={
                  !(
                    pendingChanges.title ||
                    pendingChanges.description ||
                    pendingChanges.preparationTime ||
                    pendingChanges.order
                  )
                } // Disable if no changes
              >
                Save
              </Button>
            </Box>
          </Box>
          <Typography
            sx={{
              color: colors.gray800,
              fontSize: fonts.heading3,
              fontWeight: 600,
              mt: 3,
              mb: 2,
            }}
          >
            Details
          </Typography>
          <Box mt={-1}>
            <DividerLine />
          </Box>
          <TextField
            placeholder="Display Label"
            type="text"
            value={newTitle}
            onChange={(e) => {
              setNewTitle(e.target.value);
              setPendingChanges((prev) => ({ ...prev, title: true }));
            }}
            sx={{
              mt: 4,
              width: {
                lg: "50%",
                md: "60%",
              },
              display: "flex",
              justifyContent: "start",
            }}
          />
          <TextField
            multiline
            rows={6}
            value={newDescription}
            placeholder="Description"
            onChange={(e) => {
              setNewDescription(e.target.value);
              setPendingChanges((prev) => ({ ...prev, description: true }));
            }}
            type="text"
            variant="outlined"
            sx={{
              mt: 5,
              width: {
                sm: "100%", // 70% for small screens
                md: "100%", // 50% for medium screens and above
                lg: "50%", // 50% for medium screens and above
              },
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              borderColor: colors.gray500,
            }}
          />
          <Typography
            fontSize={fonts.heading2}
            mt={7}
            color={colors.gray800}
            fontWeight={600}
            textAlign={"left"}
          >
            Preperation Time
          </Typography>
          <Box mt={-2}>
            <DividerLine />
          </Box>
          <TextField
            label="Preparation Time (seconds)"
            type="number"
            id="num"
            required
            value={preparationTime}
            onChange={(event) => {
              const value = event.target.value;

              // Allow empty value to let the user clear the field
              if (value === "") {
                setPreparationTime("");
              } else {
                // Prevent negative values
                setPreparationTime(Math.max(0, Number(value)));
              }
              setPendingChanges((prev) => ({
                ...prev,
                preparationTime: true,
              }));
            }}
            onWheel={(e) => e.target.blur()}
            slotProps={{
              input: {
                // Adjust margin if needed
                startAdornment: <AcuteIcon />,
              },
            }}
            sx={{
              mt: 0,
              width: {
                lg: "50%",
                md: "60%",
              },
              display: "flex",
              justifyContent: "start",
            }}
            helperText="This defines the time for the preview of a new exercise or set"
          />
          <Typography
            sx={{
              color: colors.gray800,
              fontSize: fonts.heading3,
              fontWeight: 600,
              mt: 4,
            }}
          >
            Advanced
          </Typography>
          <Box mt={-1}>
            <DividerLine />
          </Box>
          <Grid display="flex" mt={-1} justifyContent={"space-between"}>
            <Typography
              fontSize={fonts.body}
              sx={{
                textAlign: "left",
              }}
              gutterBottom
            >
              <IconButton>
                <FitnessCenterOutlinedIcon sx={{ mr: 1 }} />
              </IconButton>
              Total Number of Sets: {sections?.setTimings?.length}
            </Typography>
            <Button
              variant="contained"
              fontSize={fonts.heading4}
              size="medium"
              endIcon={<Add />}
              sx={{
                textTransform: "none",
                background: colors.teal400,
                fontWeight: 400,
                height: "40px",
                textAlign: "left",
                px: 2,
              }}
              onClick={handleOpenTimingModal}
            >
              New Set
            </Button>
          </Grid>
          <Box mt={4}>
            <DraggableSections
              sections={sections}
              handleDragEnd={handleDragEnd}
              isFromEditExerciseModal={true}
              isFromSection={false}
              onApiCall={() => {
                handleApiCall();
              }}
            />
          </Box>
          <SetTimingsModal
            open={isTimingModalOpen}
            onClose={handleCloseTimingModal}
            onSave={handleSaveTimings}
          />
        </Box>
        {/* )} */}
      </Drawer>
    </>
  );
};
export default EditExerciseModal;
