import React from "react";
import Slider from "react-slick";
import { Box, Button, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import useWindowHeight from "../windowSize/WindowHeight";
import colors from "../../styles/colors";
import useWindowWidth from "../windowSize/WindowWidth";
import EastSharpIcon from "@mui/icons-material/EastSharp";
const highlightText = (caption) => {
  const regex = /\[([^\]]+)\]/g; // Matches text inside []
  const parts = caption.split(regex); // Splits the caption into text and highlighted parts

  return parts.map((part, index) =>
    index % 2 === 1 ? (
      <span style={{ color: colors.purple }}>{part}</span>
    ) : (
      part
    )
  );
};

const CarouselComponent = () => {
  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();
  const images = [
    {
      src: "https://d3vdd33llamc8d.cloudfront.net/assets/auth/1.png",
      caption: "Free Yourself to Focus on What [Truly Matters]",
    },
    {
      src: "https://d3vdd33llamc8d.cloudfront.net/assets/auth/2.png",
      caption: "Make it, just the way [you like it]",
    },
    {
      src: "https://d3vdd33llamc8d.cloudfront.net/assets/auth/3.png",
      caption: "Present on [any device], anywhere",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };
  return (
    <Box
      sx={{
        height: windowHeight < 861 ? "99.6%" : "99.6%",
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              height: `750px`,
              overflow: "hidden",
            }}
          >
            <img
              src={image.src}
              alt={`slide-${index}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <Typography
              variant={windowWidth > 1300 ? "h4" : "h5"}
              sx={{
                position: "absolute",
                bottom: "130px",
                left: "10%",
                color: "white",
                width: windowWidth > 1300 ? "400px" : "300px",
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              {highlightText(image.caption)}
            </Typography>
            <Button
              variant="contained"
              href="https://trainerjoe.ai"
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px",
                backgroundColor: "black",
                opacity: 0.16,
                color: colors.gray50,
                textTransform: "none",
                py: "4px",
                pl: "10px",
                borderRadius: "12px",
                letterSpacing: 0.75,
                // lineHeight: 2,
                fontWeight: 400,
                "&:hover": {
                  opacity: 0.3,
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.4)", // Drop shadow effect
                },
                transition: "box-shadow 0.3s ease",

                "& .MuiButton-endIcon": {
                  // Style for the end icon
                  ml: 3,
                },
              }}
              endIcon={
                <EastSharpIcon
                  style={{
                    transform: "scale(1.2)",
                    stroke: "white",
                    strokeWidth: 0.8,
                  }}
                />
              }
            >
              More Info
            </Button>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default CarouselComponent;
