// export default TicketDetails;
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  MenuItem,
  Select,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { SubjectOutlined } from "@mui/icons-material";
import chatImages from "../../assets/messageChatImage.svg";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import colors from "../../styles/colors";
import fonts from "../../styles/fontVars";
import DividerLine from "../../components/utils/DividerLine";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ReplyMessageModal from "../../components/utils/ReplyMessageModal";
import useApiRequest from "../../api/api";
import routes from "../../api/routes";
import {
  formatDateToLongForm,
  formatDateWithTime,
} from "../../components/utils/DateFormatter";
import TicketDetailsSkeleton from "./loaders/TicketDetailsSkeleton";
const TicketDetails = () => {
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [ticketDetails, setTicketDetails] = useState({}); // Store the ticket data
  const [messages, setMessages] = useState([]); // Store the ticket data
  const { makeApiCall } = useApiRequest();

  const fetchTicketDetails = async () => {
    try {
      const response = await makeApiCall(routes.ticket.get(id), "GET");
      if (response?.data?.ticket) {
        setTicketDetails(response.data.ticket);
        setStatus(response.data.ticket.status); // Set the initial status from API
        setMessages(response.data.ticket.messages);
      }
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch ticket details:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTicketDetails();
    }
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    fetchTicketDetails();
    setIsModalOpen(false);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const DomainIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.gray800}
    >
      <path d="M280-160v-441q0-33 24-56t57-23h439q33 0 56.5 23.5T880-600v320L680-80H360q-33 0-56.5-23.5T280-160ZM81-710q-6-33 13-59.5t52-32.5l434-77q33-6 59.5 13t32.5 52l10 54h-82l-7-40-433 77 40 226v279q-16-9-27.5-24T158-276L81-710Zm279 110v440h280v-160h160v-280H360Zm220 220Z" />
    </svg>
  );
  const PromptSuggestionIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill={colors.teal50}
    >
      <path d="m600-200-56-57 143-143H300q-75 0-127.5-52.5T120-580q0-75 52.5-127.5T300-760h20v80h-20q-42 0-71 29t-29 71q0 42 29 71t71 29h387L544-624l56-56 240 240-240 240Z" />
    </svg>
  );
  const storedUser = JSON.parse(localStorage.getItem("user"));
  if (loading) {
    <TicketDetailsSkeleton />;
  }
  return loading ? (
    <TicketDetailsSkeleton />
  ) : (
    <Box sx={{ mt: 8, mx: "8%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0 }}>
        <Breadcrumbs>
          <Link
            color={colors.gray400}
            sx={{ textDecoration: "none" }}
            component={RouterLink}
            to="/support?tab=issue"
          >
            Support Tickets
          </Link>
          <Typography color="text.secondary">Details</Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ position: "relative", display: "flex", mt: 5 }}>
        <Typography
          sx={{
            fontSize: fonts.heading2,
            color: colors.gray800,
            fontWeight: 600,
          }}
        >
          Details
        </Typography>
      </Box>
      <Box mt={-1}>
        <DividerLine />
      </Box>

      <Paper elevation={0} sx={{ mt: 3, mb: 4 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={2} sm={3}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Submitted
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <EventNoteOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {formatDateToLongForm(ticketDetails?.createdOn) || "N/A"}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Closed
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <EventNoteOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {formatDateToLongForm(ticketDetails?.closedOn) || "---"}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              textAlign={"left"}
              variant="subtitle2"
              color="text.secondary"
            >
              Closed By
            </Typography>
            <Typography mt={2} textAlign={"left"} display={"flex"}>
              <AccountCircleOutlinedIcon sx={{ color: colors.gray800 }} />
              <Typography display={"inline-block"} ml={2}>
                {ticketDetails?.closedByUser?.username || "---"}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Typography textAlign={"left"} variant="subtitle2" color="text.secondary">
        Domain
      </Typography>
      <Box mt={1}>
        <Typography
          //   value={subject}
          //   onChange={(e) => setSubject(e.target.value)}
          sx={{
            mt: 0,
            width: {
              lg: "70%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
        >
          <DomainIcon sx={{ color: colors.gray800 }} />{" "}
          <Typography display={"inline-block"} ml={3}>
            {ticketDetails?.domain || "N/A"}
          </Typography>
        </Typography>
      </Box>

      <Typography
        mt={5}
        textAlign={"left"}
        variant="subtitle2"
        color="text.secondary"
      >
        Subject
      </Typography>
      <Box mt={1}>
        <Typography
          //   value={subject}
          //   onChange={(e) => setSubject(e.target.value)}
          sx={{
            mt: 0,
            width: {
              lg: "70%",
              md: "60%",
            },
            display: "flex",
            justifyContent: "start",
          }}
        >
          <SubjectOutlined sx={{ color: colors.gray800 }} />{" "}
          <Typography display={"inline-block"} ml={3}>
            {ticketDetails?.subject || "N/A"}
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          mt: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: fonts.heading2,
            color: colors.gray800,
            fontWeight: 600,
          }}
        >
          Messages
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.teal400,
            "&:hover": { backgroundColor: colors.teal500 },
            textTransform: "none",
            fontWeight: 400,
            px: 4,
          }}
          endIcon={<PromptSuggestionIcon />}
          onClick={handleOpenModal}
        >
          Reply
        </Button>
      </Box>
      <Box mt={-1}>
        <DividerLine />
      </Box>
      <Box sx={{ mb: 15 }}>
        {messages?.map((message, index) => {
          const isCurrentUser =
            message.sentByUser.id === parseInt(storedUser.id, 10);

          return (
            <Paper
              key={message.id}
              elevation={0}
              sx={{
                p: 2,
                mt: 5,
                mb: 3,
                border: "1px solid",
                borderColor: isCurrentUser ? colors.teal400 : colors.gray200,
                textAlign: isCurrentUser ? "right" : "left",
                // backgroundColor: isCurrentUser ? colors.teal50 : "white",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  mb: 1,
                  color: colors.gray500,
                }}
              >
                {isCurrentUser ? "You" : message.sentByUser.username} at{" "}
                {formatDateWithTime(message.createdOn)}
              </Typography>
              <Typography
                sx={{
                  color: colors.gray800,
                  textAlign: isCurrentUser ? "right" : "left",
                }}
              >
                {message.messageText || "No description available."}
              </Typography>

              {message.uploadCdnUrls && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                    mt: 4,
                    flexWrap: "wrap",
                  }}
                >
                  {message.uploadCdnUrls.map((imgSrc, i) => (
                    <Box
                      key={i}
                      sx={{
                        position: "relative",
                        width: 100,
                        height: 100,
                        borderRadius: "4px",
                        border: "1px solid",
                        borderColor: colors.gray400,
                        overflow: "hidden",
                        "&:hover img": {
                          filter: "brightness(70%)",
                        },
                        "&:hover .iconOverlay": {
                          opacity: 1,
                        },
                      }}
                    >
                      <img
                        src={imgSrc}
                        alt={`Attachment ${i + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transition: "0.3s ease",
                        }}
                      />
                      <a
                        href={imgSrc}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconButton
                          className="iconOverlay"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                          }}
                          onClick={() => window.open(imgSrc, "_blank")}
                        >
                          <OpenInNewOutlinedIcon />
                        </IconButton>
                      </a>
                    </Box>
                  ))}
                </Box>
              )}
            </Paper>
          );
        })}
      </Box>

      <ReplyMessageModal open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default TicketDetails;
